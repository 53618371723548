import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  userToken: null,
};

const userAddInfoSlice = createSlice({
  name: "useraddinfo",
  initialState,
  reducers: {
    userAddInfoStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    userAddInfoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },

    userAddInfoFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  userAddInfoStart,
  userAddInfoSuccess,
  userAddInfoFailure,
  clearError,
  clearMessage,
} = userAddInfoSlice.actions;

export default userAddInfoSlice.reducer;
