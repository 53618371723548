import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  uploaded: null,
};

const PostUserStory = createSlice({
  name: "postuserstory",
  initialState,
  reducers: {
    postUserStoryStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    postUserStorySuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      
      state.message = action.payload.message;
      state.token = action.payload.token;
    },

    PostUserStoryFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    setUnderUploadedProgress(state, action) {
      state.uploaded = action.payload;
      console.log(action.payload, "<<<<<<<payload");
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  postUserStoryStart,
  postUserStorySuccess,
  PostUserStoryFailure,
  setUnderUploadedProgress,
  clearError,
  clearMessage,
} = PostUserStory.actions;

export default PostUserStory.reducer;
