import React, { useEffect } from "react";
import InvestorSidebar from "../../Componenets/Investor/Sidebar/InvestorSidebar";
import InvestorHeader from "../../Componenets/Investor/Header/InvestorHeader";
import UserCallRequestPreview from "../../Componenets/Investor/InvestorToUserRequests/UserCallRequestPreview";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Fade from 'react-reveal/Fade';

function UserToInvestorCallRequest() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <InvestorSidebar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <InvestorHeader />
          <Fade down delay={1000}>
          <UserCallRequestPreview />
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default UserToInvestorCallRequest;
