import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  verifyStart,
  verifySuccess,
  verifyFailure,
} from "../Reducers/verifyReducer";
import axios from "axios";

export const verify = createAsyncThunk("verify", async (token, thunkAPI) => {
  try {
    thunkAPI.dispatch(verifyStart());

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}verify`,
      {
        headers,
      }
    );

    thunkAPI.dispatch(verifySuccess(response.data));
  } catch (error) {
    thunkAPI.dispatch(verifyFailure(error.response.data.message));
  }
});