import React from "react";
import CenterImage from "../../assets/404center.png";
import NotFoundHeader from "./NotFoundHeader";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="container-fluid notFoundMian">
      <div className="row">
        <NotFoundHeader />
        <div className="col-sm-12">
          <div className="centerImage">
            <img src={CenterImage} />
            <Link to="/">
              <button className="goBackBtn">Back to Home</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
