import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const scheduleCallsSlice = createSlice({
  name: "ScheduleCalls",
  initialState,
  reducers: {
    scheduleCallStart(state) {
      state.loading = true;
      state.error = null;
    },

    scheduleCallSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    scheduleCallFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  scheduleCallStart,
  scheduleCallSuccess,
  scheduleCallFailure,
  clearError,
  clearMessage,
} = scheduleCallsSlice.actions;

export default scheduleCallsSlice.reducer;
