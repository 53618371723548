import React, { Fragment, useEffect, useState } from "react";
import Users from "../../../assets/user.png";
import Round from "../../../assets/round.png";
import { Link, useNavigate } from "react-router-dom";
import PDF1 from "../../../assets/pdf1.png";
import PDF2 from "../../../assets/pdf2.png";
import moment from "moment/moment";
import calender from "../../../assets/calendar.png";
import maps from "../../../assets/maps.png";
import { Form, DatePicker, Button, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { InvestorDatess } from "../../../Redux/Actions/InvestorDates";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/InvestorCallingReducer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import VideoPlayer from "../Profile/VideoPlayer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { InvestorReschedule } from "../../../Redux/Actions/InvestorRescheduleCalls";
import {
  clearError as clearErrors,
  clearMessage as clearMessages,
} from "../../../Redux/Reducers/InvestorReschedule";
import { InvestorCalling } from "../../../Redux/Actions/InvestorCalling";

function LeftSec({ id, data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabledDates, setDisabledDates] = useState([]);
  const { message, error, loading } = useSelector(
    (state) => state.InvestorCalling
  );

  // const videoId = data.userCallDetails?.userStoryData?.Video;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const handleNavigate = () => {
    navigate(-1);
  };

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      date1: moment(fieldsValue.date1.toString()).utc().format(), // Use 'date1' instead of 'date-time-picker'
      date2: moment(fieldsValue.date2.toString()).utc().format(),
      date3: moment(fieldsValue.date3.toString()).utc().format(),
    };

    dispatch(InvestorCalling({ id, data: values }));
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate(-1);
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);

  if (!data) {
    return <div>Data is not available.</div>;
  }
  console.log(data, "single data");
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const disabledDate = (current) => {
    // Disable dates that are before the current date or more than three days in the future
    return (
      current &&
      (current < dayjs() || current > dayjs().add(3, "day").endOf("day"))
    );
  };

  return (
    <Fragment>
      <div className="row leftsidedetail investerLeftsidedetail newds">
        <div className="col-sm-12 profdetails axbhbxhsbx">
          {/* <Link to="/investor/edit-profile" className="linkEdit">Edit Profile</Link> */}

          <div className="profDetailsTitle usp">
            <h2>
              {data?.firstName} {data?.lastName}
            </h2>
            {/* <h3>
              <img src={Round} alt="as" /> Round{" "}
              {data?.userBusinessInfoData?.Round}
            </h3> */}

            <p>
              Business Interested:
              {data?.investorRelationToBusinessInfo?.investorBusinessInfoData?.map(
                (item, i) => (
                  <span>
                    {item?.investorInvestorGroupName?.groupName}
                    {i !==
                      data?.investorRelationToBusinessInfo
                        ?.investorBusinessInfoData.length -
                        1 && ","}
                    {i ===
                      data?.investorRelationToBusinessInfo
                        ?.investorBusinessInfoData.length -
                        1 && "."}
                  </span>
                )
              )}
            </p>
          </div>

          <div className="row investorWeb">
            <div className="col-sm-12">
              <p>Bio : {data?.investorRelationToBusinessInfo?.bio}</p>
            </div>

            <div className="col-sm-12 urrrllss">
              <p className="urls">
                Website Links:{" "}
                <a
                  href={data?.investorRelationToBusinessInfo?.websiteLink}
                  target="_blank"
                  className="linkss"
                >
                  Click To Visit
                </a>
              </p>
            </div>
          </div>

          <div className="row inner invDETAIlestor">
            <div className="col-sm-6">
              <h2>No of Companies Invested In</h2>
              <h3>
                {data?.investorRelationToBusinessInfo?.noCompaniesInvestedIn}
              </h3>
            </div>

            <div className="col-sm-6 ">
              <img src={maps} alt="" />
              <p>
                {data?.invesotrinfo?.investorCity?.name +
                  "," +
                  data?.invesotrinfo?.investorCity?.StateData?.CountryDetail
                    ?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Form onFinish={onFinish}> */}
      <div className="row bottombox bottombox1">
        <div className="row">
          <div className="col-sm-12">{/* <p>{}</p> */}</div>
        </div>

        <div className="col-sm-8 textSection">
          {/* <p>{data?.userCallDetails?.userStoryData?.description}</p> */}

          {/* <div className="row leftSecDateSelect">
            <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <img src={calender} alt="" />
                  <p> {moment(data?.createdAt).format("YYYY-MM-DD HH:mm a")}</p>
                </div>
              </div>

              <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <Form.Item name="date1" {...config}>
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                </div>
              </div>
              <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <Form.Item name="date2" {...config}>
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                </div>
              </div>
              <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <Form.Item name="date3" {...config}>
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                </div>
              </div>

              <div className="col-sm-12 greyDate myheights">
                <div className="greyBox">
                  <Form.Item
                    name="status"
                    initialValue="accepted"
                    rules={[{ required: true }]}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item
                    name="CallId"
                    initialValue={data?._id}
                    rules={[{ required: true }]}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                </div>
              </div> 
          </div>
              */}
        </div>
      </div>

      {/* <div className="col-sm-12 rightvideo mob">
        <VideoPlayer />
      </div> */}

      <div className="row btnSection calReqLeft">
        {/* <div className="col-sm-6 btnLefty">
            <Button type="primary" htmlType="submit">
              {loading ? (
                <Spin indicator={antIcon} style={{ color: "#ffff" }} />
              ) : (
                "Schedule"
              )}
            </Button>
          </div> */}
        <div className="col-sm-12 btnRights">
          <Button
            type="primary"
            htmlType="submit"
            className="btnBack"
            onClick={handleNavigate}
          >
            Back
          </Button>
        </div>
      </div>
      {/* </Form> */}
    </Fragment>
  );
}

export default LeftSec;
