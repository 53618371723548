import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  investorAddInfoStart,
  investorAddInfoSuccess,
  investorAddInfoFailure,
} from "../Reducers/InvestorAddInfoReducer";
import axios from "axios";

export const InvestorAddInfo = createAsyncThunk(
  "investoraddinfo",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(investorAddInfoStart());
      // const userToken = thunkAPI.getState().userAuth.userToken;
      const investorstoken = thunkAPI.getState().investorauth.investortoken;

      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/investor/addInvestorInfo`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(investorAddInfoSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(investorAddInfoFailure(error.response.data.message));
    }
  }
);
