import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  shareMeetingStart,
  shareMeetingSuccess,
  shareMeetingFailure,
} from "../Reducers/AdminShareMeeting";
import axios from "axios";

export const AdminShareMeeting = createAsyncThunk(
  "sharemeeting",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(shareMeetingStart());
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/shareMeetingLink`,
        data,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(shareMeetingSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(shareMeetingFailure(error.response.data.message));
    }
  }
);
