import React from 'react'
import AdminSidebar from '../../Componenets/Admin/Sidebar/AdminSidebar'
import AdminHeader from '../../Componenets/Admin/Header/AdminHeader'
import AdminInvestorEdit from '../../Componenets/Admin/Investors/AdminInvestorEdit'

function AdminEditInvestor()
{
    return(
        <div className='container-fluid userdashboard'>
            <div className='row'>
                <div className='col-sm-3 sidebar'>
                    <AdminSidebar/>
                </div>

                <div className='col-sm-9 rightloginform bgdarks'>
                    <AdminHeader/>
                    <AdminInvestorEdit />
                </div>
            </div>
        </div>
    )
}

export default AdminEditInvestor