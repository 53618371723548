import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  InvestorSignupStart,
  InvestorSignupSuccess,
  InvestorSignupFailure,
} from "../Reducers/InvestorSignupReducer";
import axios from "axios";

export const InvestorSignups = createAsyncThunk(
  "investorsignup",
  async (data, thunkAPI) => {
    try {

       const investorstoken= thunkAPI.getState().investorauth.investortoken

      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

       console.log(investorstoken , "states")
      thunkAPI.dispatch(InvestorSignupStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/investor/registerInvestor`,
        data, { headers: header,  }
      );

      thunkAPI.dispatch(InvestorSignupSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(InvestorSignupFailure(error.response.data.message));
    }
  }
);
