import React, { Fragment, useEffect } from "react";
import { Button, Form, Input, Upload, Select, Steps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PostUserStory } from "../../../Redux/Actions/PostUserStory";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/PostUserStoryReducer";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
import { verify } from "../../../Redux/Actions/verify";
import Cookies from "js-cookie";

function RecordForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading, uploaded } = useSelector(
    (state) => state.postUserStory
  );
  const verifyData = useSelector((state) => state.verify.data);

  // const { data } = useSelector((state) => state.verify);

  console.log(uploaded, "uploaded");
  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const { Option } = Select;

  const onFinish = async(values) => {
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("tag", values.tag);

      formData.append("description", values.description);
      if (values.video && values.video.length > 0) {
        // Append the first file in the fileList (assuming single file upload)
        formData.append("video", values.video[0].originFileObj);
      }

   await   dispatch(PostUserStory(formData));
      const token = Cookies.get("token");
      dispatch(verify(token));
    } catch (error) {
      toast.error(error, "chal gaya jhaatu");
      console.error("Error message:", error.message);
      console.error("Error name:", error.name);
      console.error("Error stack trace:", error.stack);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/user/pitchdeck");
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
   
    if (verifyData.user && verifyData.user.userStoryDetail) {
      navigate("/user/pitchdeck");
    }
  }, [verifyData]);

  return (
    <Fragment>
      <div className="row usersignup storyUSer">
        <div className="col-sm-12 recordform">
          <Steps
            size="small"
            current={0}
            items={[
              { title: "Story" },
              { title: "PitchDeck" },
              { title: "Business Detail" },
            ]}
          />

          <h2>Story/Recorded Video </h2>
          <p>Welcome back! please enter your detail</p>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-sm-12 uploadbtn">
                <Form.Item
                  name="video"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="logo"
                    beforeUpload={() => false}
                    action="/upload.do"
                    listType="picture"
                    accept="video/*"
                  >
                    <Button icon={<UploadOutlined />}>Upload Video</Button>
                  </Upload>
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="title"
                  rules={[
                    { required: true, message: "Please input your Title!" },
                  ]}
                >
                  <Input placeholder="Title" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Description!",
                    },
                  ]}
                >
                  <Input placeholder="Description" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="recordbtn"
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Next"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {uploaded && (
        <div className="progress mt-2">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={uploaded}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${uploaded}%` }}
          >
            {`${uploaded}%`}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default RecordForm;
