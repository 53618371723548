import React from 'react'
import SideBar from '../../Componenets/User/Sidebar/SideBar'
import ProfileView from '../../Componenets/User/Profile/ProfileView'
import Header from '../../Componenets/User/Header/Header'
import Fade from 'react-reveal/Fade';

function Profile() {
  return (
   
    <div className='container-fluid userdashboard'>
        <div className='row'>
            <div className='col-sm-3 sidebar'>
                <SideBar/>
            </div>
            
            <div className='col-sm-9 rightloginform bgdarks'>
            <Header/>
            <Fade down delay={500}>
            <ProfileView/>
            </Fade>
            </div>
         
        </div>
    </div>
    
  )
}

export default Profile

