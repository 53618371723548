import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  error: null,
  data: [],
};

const GetInvestorSlice = createSlice({
  name: "getinvestor",
  initialState,
  reducers: {
    getInvestorStart(state) {
      state.loading = true;
      state.error = null;
    },

    getInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    getInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getSingleInvestorStart(state) {
      state.loading = true;
      state.error = null;
    },

    getSingleInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    getSingleInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    changeStatusInvestorStart(state) {
      state.loading = true;
      state.error = null;
    },

    changeStatusInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
    },

    changeStatusInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteInvestorStart(state) {
      state.loading = true;
      state.error = null;
    },

    deleteInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.data.message;
    },

    deleteInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getInvestorStart,
  getInvestorSuccess,
  getInvestorFailure,
  clearError,
  getSingleInvestorStart,
  getSingleInvestorSuccess,
  getSingleInvestorFailure,
  deleteInvestorStart,
  deleteInvestorSuccess,
  deleteInvestorFailure,
  changeStatusInvestorStart,
  changeStatusInvestorSuccess,
  changeStatusInvestorFailure,
  clearMessage,
} = GetInvestorSlice.actions;

export default GetInvestorSlice.reducer;
