import { Fragment, useEffect } from "react";
import { Button, Checkbox, Form, Input, Image, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Linkdin from "../../../assets/LinkedIn.png";
import Envlop from "../../../assets/env.png";
import Password from "../../../assets/pass.png";
import { useDispatch, useSelector } from "react-redux";
import { InvestorLogin } from "../../../Redux/Actions/InvestorAuth";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/InvestorAuthReducer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOutlined } from "@ant-design/icons";
import LinkedinInvestor from "./LinkedinInvestor";
import Cookies from "js-cookie";

function LoginForm() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message, error, loading } = useSelector(
    (state) => state.investorauth
  );
  const { data } = useSelector((state) => state.verify);

  const onFinish = (values) => {
    dispatch(InvestorLogin(values));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      if (data.investor && !data.investor?.InvestorBusinessInfoDetail) {
        navigate("/investor/business-info");
      } else if (data.investor && data.investor?.InvestorBusinessInfoDetail) {
        navigate("/investor/my-profile");
      }
    }
  }, [data]);

  return (
    <Fragment>
      <div className="row storyUSer">
        <div className="col-sm-12 loginform">
          <h2>Investor Login</h2>
          <p>Welcome back! please enter your detail</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input
                placeholder="Email"
                prefix={<Image src={Envlop} preview={false} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<Image src={Password} preview={false} />}
              />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              className="checkrem"
            >
              <Checkbox>Remember me</Checkbox>
              <Link to="/investor/forget-password">Forget Password</Link>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {loading ? (
                  <Spin indicator={antIcon} style={{ color: "#ffff" }} />
                ) : (
                  "Login"
                )}
              </Button>
              <br />

              <LinkedinInvestor />

              <p className="ques">
                Don't have an account? <Link to="/investor/signup">Signup</Link>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default LoginForm;
