import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Packagelist } from "../../../Data/Data";
import { useDispatch, useSelector } from "react-redux";
import { GetUserPackage } from "../../../Redux/Actions/Package";
import { buyPackage } from "../../../Redux/Actions/BuyPackage";
import Nodata from "../../../assets/nodata.jpg";

function Packages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading } = useSelector((state) => state.packages);

  const handleRedirect = (id) => {
    dispatch(buyPackage(id));

    navigate(`/user/selectpackage/${id}`);
  };

  useEffect(() => {
    dispatch(GetUserPackage());
  }, [dispatch]);

  return (
    <div className="col-sm-12 packages userpackageeess">
      <div className="row abcd">
        {data?.length > 0 ? (
          data?.map((data) => {
            return (
              <div className="col-sm-4 packagessss" key={data.key}>
                <p className="sale">{data.discount}% OFF</p>
                <h2 className="title">{data?.PackageName}</h2>
                <div className="card">
                  <p>{data.description}</p>
                  <h4 className="days">{data.noOfDays} Days</h4>
                  <h5>
                    <br /> <span>{data.reachLimit}</span> Investor
                  </h5>
                  <h6 className="cost">${data.price}</h6>
                  <button
                    to={`/user/selectpackage/${data?.packageTypeDetails?.name}`}
                    onClick={() => handleRedirect(data._id)}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="nodatas subs">
            <img src={Nodata} alt="asd" /> <h2>Sorry No Data Found</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default Packages;
