import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  investortoken: null,
};

const InvestorUthSlice = createSlice({
  name: "investorLogin",
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    loginSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Login successfully!";
      state.investortoken = action.payload.data.token;
      Cookies.set("token", action.payload.data.token);
      console.log("token checking", action);
    },

    logOutSuccess(state) {
      state.data = null;
      state.investortoken = null;
      state.message = null;
      localStorage.removeItem("persist:root");
      state.loading = false;
      Cookies.remove("token");
    },

    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    InvestorlinkedinLogin(state, action) {
      state.investortoken = action?.payload;
      console.log(action, "<<<<<>>>>>>>>");
      Cookies.set("token", action.payload);
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  clearError,
  InvestorlinkedinLogin,
  clearMessage,
  logOutSuccess,
} = InvestorUthSlice.actions;

export default InvestorUthSlice.reducer;
