import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import edit from "../../../assets/Pen Squared.png";
import { Button, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Deleteuser, Getuser, changeStatus } from "../../../Redux/Actions/User";
import { toast } from "react-toastify";
import { clearError, clearMessage } from "../../../Redux/Reducers/UserReducer";
import DeleteModal from "../../Modals/DeleteModal";
import Pagination from "../../Pagination";

function AdminUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, message, error, loading } = useSelector((state) => state.user);
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);

  

  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const handleRedirect = (id) => {
    navigate(`/admin/edit-user/${id}`);
  };

  const handleDelete = async (id) => {
    console.log("Delete", id);

    await dispatch(Deleteuser({ id }));
    // dispatch(Getuser());
    await dispatch(Getuser({ page, size }));
  };
  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };
  const onChange = async (UserId, checked) => {
    const data = {
      status: checked,
      UserId,
    };
    dispatch(changeStatus({ data }));
    await dispatch(Getuser({ page, size }));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    dispatch(Getuser({ page, size }));
  }, [dispatch, page, size]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="mobresponnTable schdcalss">
          <table className="adminSubsPackageTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow">
                <th className="userAdmNAme">Name</th>
                <th className="userAdmEmail">Email</th>
                <th className="userAdmInv">Business Name</th>
                <th className="userAdmInt">Interest Group</th>
                <th className="userAdmCurr">Current Package</th>
                <th className="userAdmFlag">Flag</th>
                <th className="userAdmStatus">Status</th>
                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow" key={i}>
                  <td className="userAdmNAme">
                    {value?.firstName + " " + value?.lastName}
                  </td>
                  <td className="userAdmEmail">{value?.email}</td>
                  <td className="userAdmInv">
                    {value?.userBusinessInfoData?.businessName}
                  </td>
                  <td className="invAdmInt ">
                    <div className="heightss">
                      {value?.userBusinessInfoData?.userInvestorGroups?.map(
                        (elem, i) => (
                          <>{elem?.userInvestorGroupName?.groupName}<br /> </>
                        )
                      )}
                    </div>
                  </td>
                  <td className="userAdmCurr">Not Found</td>
                  <td className="userAdmFlag">Flag</td>
                  <td className="userAdmStatus">
                    <Switch
                      defaultChecked={value?.isActive}
                      onChange={() => onChange(value?._id, !value?.isActive)}
                    />
                  </td>
                  <td className="actionIcons">
                    <img
                      src={edit}
                      className="actionIconsAdm"
                      onClick={() => handleRedirect(value?._id)}
                    />

                    <img
                      src={trash}
                      className="actionIconsAdm"
                      // onClick={() => handleDelete(value?._id)}
                      onClick={() => handleModal(value._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
      <DeleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleDelete={handleDelete}
        id={id}
      />
    </div>
  );
}

export default AdminUsers;
