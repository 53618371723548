import React, { Fragment, useEffect } from "react";
import Users from "../../../assets/user.png";
import Round from "../../../assets/round.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import PDF1 from "../../../assets/pdf1.png";
import PDF2 from "../../../assets/pdf2.png";
import { Form, Input, Button, Spin } from "antd";
import calender from "../../../assets/calendar.png";
import maps from "../../../assets/maps.png";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserReschedulePost } from "../../../Redux/Actions/UserReschedulePost";
import { clearError, clearMessage,} from "../../../Redux/Reducers/UserRescheduleCard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOutlined } from "@ant-design/icons";

function LeftSecD({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, error, loading } = useSelector(
    (state) => state.userreschedulerard
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { id } = useParams();

  console.log(id, "poppp");
  const onFinish = (values) => {
    // Handle form submission, e.g., send data to a server
    values.CallId = id;
    dispatch(UserReschedulePost({id ,data:values}));
    console.log("Received values:", values);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/user/schedule-calls");
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);

  if (!data) {
    return <div>Data is not available.</div>;
  }

  return (
    <Fragment>
      <div className="row leftsidedetail investerLeftsidedetail">
        <div className="col-sm-4 usImg">
          <img src={data?.userCallDetails?.profile_pic} />
          <div className="bgGreen">
            <p>IT INDUSTRY</p>
          </div>
        </div>

        <div className="col-sm-8 profdetails">
          {/* <Link to="/investor/edit-profile" className="linkEdit">Edit Profile</Link> */}
          <button>Pending</button>
          <div className="profDetailsTitle">
            <h2>
              {data?.userCallDetails?.firstName}{" "}
              {data?.userCallDetails?.lastName}
            </h2>
            <h3>
              <img src={Round} alt="as" /> Round{" "}
              {data.userCallDetails?.userBusinessInfoData?.Round}
            </h3>

            <p>
              Business Name:
              <span>
                {data?.userCallDetails?.userBusinessInfoData?.businessName}
              </span>
            </p>
          </div>

          <div className="row investorWeb">
            <div className="col-sm-12">
              <p className="urls">
                <Link
                  to={data?.userCallDetails?.userBusinessInfoData?.websiteLink}
                >
                  {data?.userCallDetails?.userBusinessInfoData?.websiteLink}
                </Link>
              </p>
            </div>
            <div className="col-sm-12">
              <p>
                No. of Associated Partners:{" "}
                {
                  data?.userCallDetails?.userBusinessInfoData?.partnersInfo
                    .length
                }
              </p>
            </div>
          </div>

          <div className="row inner">
            <div className="col-sm-6">
              <h2>Investment</h2>
              <h3>
                $ {data.userCallDetails?.userBusinessInfoData?.InvestmentRaised}
              </h3>
            </div>

            <div className="col-sm-6 ">
              <img src={maps} alt="" />
              <p>
                {data?.userCallDetails?.userInfo?.userCity?.name},{" "}
                {
                  data?.userCallDetails?.userInfo?.userCity?.StateData
                    ?.CountryDetail?.name
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row bottombox bottombox1">
        <div className="col-sm-4 deckpdf">
          <h3>Pitch Deck</h3>
          <Link to="">
            {" "}
            <img src={PDF1} alt="asd" />
          </Link>
          <Link to="">
            {" "}
            <img src={PDF2} alt="asd" />
          </Link>
        </div>

        <div className="col-sm-8 textSection">
          <p>
            Select 3 Available days and time slot, The Admin will confirm one of
            them as per the mutual Availability between User and Investor. Note:
            The slot can be selected after 8 days only
          </p>

          <div className="row LeftSecDDateSelect">
            <div className="col-sm-12 greyDate">
              <div className="greyBox">
                <img src={calender} alt="" />
                <p>Nov 30 - 04:30 PM</p>
              </div>
            </div>
            <div className="col-sm-6 greyDate">
              <div className="greyBox">
                <DatePicker placeholder="Select Date" />
              </div>
            </div>
            <div className="col-sm-6 greyDate">
              <div className="greyBox">
                <DatePicker placeholder="Select Date" />
              </div>
            </div>
            <div className="col-sm-6 greyDate">
              <div className="greyBox">
                <DatePicker placeholder="Select Date" />
              </div>
            </div>
            <div className="col-sm-6 greyDate">
              <div className="greyBox">
                <DatePicker placeholder="Select Date" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row btnSection">
        <div className="col-sm-6 btnLefty">
          <button>Schedule </button>
        </div>
        <div className="col-sm-6 btnRights">
          <button className="backBtn">Back </button>
        </div>
      </div>

      <div className="row formsdecline">
        <h2>Reason for Reschedule</h2>
        <Form name="myForm" onFinish={onFinish}>
          <Form.Item
            name="Rescheduled"
            rules={[
              {
                required: true,
                message: "Please input your reason for declining!",
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit">
              {loading ? (
                <Spin indicator={antIcon} style={{ color: "#ffff" }} />
              ) : (
                "Submit Reason"
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
}

export default LeftSecD;
