import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  userCallsStart,
  userCallsSuccess,
  userCallsFailure,
} from "../Reducers/UserCallsReducer";
import axios from "axios";

export const UserCalls = createAsyncThunk("usercalls", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(userCallsStart());

    const userToken = thunkAPI.getState().userAuth.userToken;
    console.log(userToken, "token");
    const header = {
      Authorization: `Bearer ${userToken}`,
    };
    console.log(userToken, "<====userToken");
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}user/datesListing`,
      {
        headers: header,
      }
    );

    thunkAPI.dispatch(userCallsSuccess(response.data));
  } catch (error) {
    thunkAPI.dispatch(userCallsFailure(error.response.data.message));
  }
});
