import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import edit from "../../../assets/Pen Squared.png";
import calendars from "../../../assets/calendar.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleCallsListings } from "../../../Redux/Actions/ScheduleCalls";
import Pagination from "../../Pagination";
import moment from "moment";

function AdminScheduledCallsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.scheduleCalListing);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);

  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const handleRedirect = (id) => {
    navigate("/admin/user-call-request", {
      state: id,
    });
  };

  const handleEdit = () => {
    console.log("Edit");
  };



  console.log(data);
  useEffect(() => {
    dispatch(ScheduleCallsListings({ page, size }));
  }, [dispatch, page, size]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="mobresponnTable schdcalss">
          <table className="adminSubsPackageTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow">
                <th className="admSchName">Investor Name</th>
                <th className="admSchEmail">User Name</th>
                <th className="admSchInv">Investment Stage</th>
                <th className="admSchInt">Interest Group</th>
                <th className="admSchDate">Meeting Link</th>
                <th className="admSchDate">Scheduled On</th>
                <th className="admSchDate">Scheduled Time</th>

                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow" key={i}>
                  <td className="admSchName">
                    {value?.sceduleDatesDetails?.callIdDetails
                      ?.investorCallDetails?.firstName +
                      " " +
                      value?.sceduleDatesDetails?.callIdDetails
                        ?.investorCallDetails?.lastName}
                  </td>
                  <td className="admSchEmail">
                    {" "}
                    {value?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                      ?.firstName +
                      " " +
                      value?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                        ?.lastName}
                  </td>
                  <td className="admSchInv">
                    Round{" "}
                    {
                      value?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                        ?.userBusinessInfoData?.Round
                    }
                  </td>
                  <td className="admSchInt">
                    <div className="heightss">
                      {" "}
                      {value?.sceduleDatesDetails?.callIdDetails?.userCallDetails?.userBusinessInfoData?.userInvestorGroups?.map(
                        (e) => (
                          <>{e.userInvestorGroupName?.groupName}<br /></>
                        )
                      )}
                    </div>
                  </td>
                  <td className="admSchDate">
                    <a href={value?.meetingLink} target="_blank">
                      <p>Meeting Link</p>
                    </a>
                  </td>
                  <td className="admSchDate">
                    <div className="callDate">
                      <img src={calendars} />
                      <p>
                        {moment(value?.SceduledDate).format("YYYY-MM-DD A")}
                      </p>
                    </div>
                  </td>
                  <td className="admSchDate">
                    <div className="callDate">
                      <img src={calendars} />
                      <p>{moment(value?.SceduledDate).format("h:mm A")}</p>
                    </div>
                  </td>

                  <td className="actionIcons">
                    <img
                      src={edit}
                      className="actionIconsAdm"
                      // onClick={handleEdit()}
                      onClick={() =>
                        handleRedirect(value?.sceduleDatesDetails?.CallId)
                      }
                    />

                    {/* <img
                      src={trash}
                      className="actionIconsAdm"
                      onClick={handleDelete}
                    /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
    </div>
  );
}

export default AdminScheduledCallsList;
