import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PostPackage } from "../../../Redux/Actions/Package";
import Loader from "../../Loader";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/PackageReducer";

// const { TextArea } = Input;

function AdminAddSubscriptionPackage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading } = useSelector((state) => state.packages);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(PostPackage(values));
    // navigate("/admin/subscription-packages");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const validateWordCount = (_, value) => {
    // Change the minimum and maximum word count limits as needed
    const minWordCountLimit = 3;
    const maxWordCountLimit = Infinity;

    // Remove leading and trailing whitespaces
    const trimmedValue = value.trim();

    // Count the words
    const wordCount = trimmedValue.split(/\s+/).length;

    if (wordCount < minWordCountLimit) {
      return Promise.reject(
        `Word count is below the minimum. Minimum required: ${minWordCountLimit}`
      );
    } else if (wordCount > maxWordCountLimit) {
      return Promise.reject(
        `Word count exceeds the maximum allowed. Maximum allowed: ${
          maxWordCountLimit === Infinity ? "Unlimited" : maxWordCountLimit
        }`
      );
    } else {
      return Promise.resolve();
    }
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/admin/subscription-packages");
      dispatch(clearMessage());
    }
  }, [error, message]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminAddSubscriptionPackage ">
        <div className="adminAddSubscriptionPackageData">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="PackageName"
              rules={[
                { required: true, message: "Please enter package name!" },
              ]}
            >
              <Input placeholder="Package Name" />
            </Form.Item>

            <Form.Item
              name="description"
              rules={[
                { required: true, message: "Please enter description!" },
                { validator: validateWordCount },
              ]}
            >
              <Input.TextArea placeholder="Description" rows={5} />
            </Form.Item>

            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please enter price!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="Price" />
            </Form.Item>

            <Form.Item
              name="noOfDays"
              rules={[
                {
                  required: true,
                  message: "Please enter No. of days!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="No. of Days" />
            </Form.Item>

            <Form.Item
              name="reachLimit"
              rules={[
                {
                  required: true,
                  message: "Please enter No. Of Investors!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="No. Of Investors" />
            </Form.Item>

            <Form.Item
              name="discount"
              rules={[
                {
                  required: true,
                  message: "Please enter discount!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="Discount in %" />
            </Form.Item>

            <Form.Item className="adminAddSubscriptionPackageButton">
              {/* <Link to='/admin/subscription-packages'> */}
              <Button htmlType="submit">
                {loading ? <Loader /> : "Submit"}
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminAddSubscriptionPackage;
