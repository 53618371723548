import { createAsyncThunk } from "@reduxjs/toolkit";
import { ScheduleCallsInvestorSuccess, ScheduleCallsInvestorStart, ScheduleCallsInvestorFailure, } from "../Reducers/GetScheduleCallsInvestor";
import axios from "axios";


export const ScheduleCallsInvestors = createAsyncThunk(

  "schedulecallsinvestor",

  async ({page, size}, thunkAPI) => {
    try {
      const investorstoken= thunkAPI.getState().investorauth.investortoken
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };
      thunkAPI.dispatch(ScheduleCallsInvestorStart()); // Dispatch the start action

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/approvedDates`,{
          params,
          headers: header,
        }
      );
      thunkAPI.dispatch(ScheduleCallsInvestorSuccess(response));
      console.log("respponse", response)

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(ScheduleCallsInvestorFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);