import React from "react";
import EditProfileForm from "../../Componenets/Investor/EditProfile/EditProfileForm";
import InvestorHeader from "../../Componenets/Investor/Header/InvestorHeader";
import InvestorSidebar from "../../Componenets/Investor/Sidebar/InvestorSidebar";
import Fade from 'react-reveal/Fade';

function EditProfile() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <InvestorSidebar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <InvestorHeader />
          <Fade down delay={500}>
          <EditProfileForm />
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
