import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const UserCallsSlice = createSlice({
  name: "usercalls",
  initialState,
  reducers: {
    userCallsStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    userCallsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.items;
      state.message = action.payload.data;
      console.log(action.payload, "action.payload");
    },

    userCallsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  userCallsStart,
  userCallsFailure,
  clearError,
  clearMessage,
  userCallsSuccess,
} = UserCallsSlice.actions;

export default UserCallsSlice.reducer;
