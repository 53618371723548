import React, { Fragment, useRef, useState } from "react";
import Logo from "../../../assets/Logo.png";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Slide from "../../../assets/slide.png";

function FormSlide() {
  return (
    <Fragment>
      <div className="container slides">
        <div className="row">
          <div className="col-sm-12 youPitchLogo">
            <img src={Logo} alt="Logo" className="logo" />
          </div>

          <div className="col-ms-12">
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={Slide} alt="Slide" className="slideImageee" />
                <h2>Powerup your ideas</h2>
                <p>Everything you need</p>
              </SwiperSlide>

              {/* <SwiperSlide>
                <img src={Slide} alt="Slide" className="slideImageee" />
                <h2>Powerup your ideas</h2>
                <p>Everything you need</p>
              </SwiperSlide>

              <SwiperSlide>
                <img src={Slide} alt="Slide" className="slideImageee" />
                <h2>Powerup your ideas</h2>
                <p>Everything you need</p>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FormSlide;
