import React, { useEffect } from "react";
import Logo from "../../assets/Logo.png";
import ParticleEffect from "./ParticleEffect";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../Redux/Actions/UserProfile";
import { Fade } from "react-reveal";
const SuccessComp = () => {
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      navigate("/user/profile");
    }, 3000);
  }, []);
  useEffect(() => {
    dispatch(UserProfile());
  }, [dispatch]);

  return (
    <div className="container success">
      <ParticleEffect position="right" />
      <div className="row ">
        <div className="col-sm-12">
          <Fade down delay={100}>
            <img src={Logo} alt="" />
          </Fade>
          <Fade down delay={100}>
            <h3>
              Congratulations {data?.firstName + " " + data?.lastName} on
              signing up! Welcome aboard! 🎉
            </h3>
          </Fade>
        </div>
      </div>
      <ParticleEffect position="bottom" />
    </div>
  );
};

export default SuccessComp;
