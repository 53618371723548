import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  buyPackageStart,
  buyPackageSuccess,
  buyPackageFailure,
  getPaymentIntent,
  buyStripePackageStart,
  buyStripePackageSuccess,
  buyStripePackageFailure,
} from "../Reducers/BuyPackageReducer";
import axios from "axios";

export const buyPackage = createAsyncThunk(
  "buyPackages",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(buyPackageStart());
      const userToken = thunkAPI.getState().userAuth.userToken;

      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/buyNow/${id}`,
        null,
        config
      );

      thunkAPI.dispatch(getPaymentIntent(response.data.clientSecret));
      thunkAPI.dispatch(buyPackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(buyPackageFailure(error.response.data.message));
    }
  }
);

export const buyStripePackage = createAsyncThunk(
  "buyPackages",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(buyStripePackageStart());
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/PaymentSucceeded/${id}`,
        null,
        { headers: header }
      );

      thunkAPI.dispatch(buyStripePackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(buyStripePackageFailure(error.response.data.message));
    }
  }
);
