import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/InterestGroupReducer";
import { PostInterestGroup } from "../../../Redux/Actions/InterestGroup";
import Loader from "../../Loader";

function InterestAdminGroup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, message, loading } = useSelector(
    (state) => state.interestGroup
  );

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(PostInterestGroup(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/admin/interest-group");
    }
  }, [error, message]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminAddSubscriptionPackage">
        <div className="adminAddSubscriptionPackageData">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="groupName"
              rules={[{ required: true, message: "Please enter  name!" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item className="adminAddSubscriptionPackageButton intADmGroup">
              {/* <Link to='/admin/subscription-packages'> */}
              <Button htmlType="submit">
                {loading ? <Loader /> : "Save"}{" "}
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default InterestAdminGroup;
