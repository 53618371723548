import React from "react";
import NotFound from "../Componenets/NotFound/NotFound";
import "../Style/NotFound.css";

const NotFoundPage = () => {
  return (
    <div>
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
