import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  userToken: null,
};

const dateApprovalSlice = createSlice({
  name: "DateAprroval",
  initialState,
  reducers: {
    dataeApprovalStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    dataeApprovalSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },

    dateApprovalFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  dataeApprovalStart,
  dataeApprovalSuccess,
  dateApprovalFailure,
  clearError,
  clearMessage,
} = dateApprovalSlice.actions;

export default dateApprovalSlice.reducer;
