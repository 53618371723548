import BusinessInfoForm from "../../Componenets/Investor/Business-Info/BusinessInfoForm";
import LoginSlide from "../../Componenets/Investor/Login/LoginSlide";

function InverstorBussinessInfo() {
  return (
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginSlide />
        </div>

        <div className="col-sm-6 rightloginform inovest">
          <BusinessInfoForm />
        </div>
      </div>
    </div>
  );
}

export default InverstorBussinessInfo;
