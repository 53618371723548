import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  userToken: null,
};

const userPasswordSlice = createSlice({
  name: "userpassword",
  initialState,
  reducers: {
    userForgetPasswordStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    UserForgetPasswordSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },

    UserForgetPasswordFailure(state) {
      state.data = null;
      state.message = null;
      state.loading = false;
    },

    confirmPasswordStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    confirmPasswordSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Password is Updated Please Login again"
    },

    confirmPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  userForgetPasswordStart,
  UserForgetPasswordSuccess,
  UserForgetPasswordFailure,
  confirmPasswordStart,
  confirmPasswordSuccess,
  confirmPasswordFailure,
  clearError,
  clearMessage,
} = userPasswordSlice.actions;

export default userPasswordSlice.reducer;
