import { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./Style/Userstyle.css";
import "./Style/Investorstyle.css";
import "./Style/Admin.css";
import "./Style/Userresponsive.css";
import "./Style/AdminResponsive.css";
import "./Style/InvestorResponsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./Style/LearnigPortall.css";
import { useDispatch, useSelector } from "react-redux";
import { verify } from "./Redux/Actions/verify";
import Cookies from "js-cookie";
import { commonRoutes, generateRoutes } from "./Routes";
import NotFoundPage from "./Pages/NotFoundPage";

function App() {
  const dispatch = useDispatch();

  const { userToken, investortoken, adminToken } = useSelector((state) => ({
    userToken: state.userAuth.userToken,
    investortoken: state.investorauth.investortoken,
    adminToken: state.adminAuth.adminToken,
  }));
  const { data } = useSelector((state) => state.verify);

  let token = Cookies.get("token");

  console.log(data);

  useEffect(() => {
    dispatch(verify(token));
  }, [userToken, investortoken, adminToken, dispatch]);

  const userRole =
    (data?.user && "user") ||
    (data?.admin && "admin") ||
    (data?.investor && "investor");
  const userRoutes = generateRoutes(userRole, token);

  const allRoutes = [...commonRoutes, ...userRoutes];

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          {allRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
