import React from "react";
import FormSlide from "../../Componenets/Investor/AddMoreInfo/FormSlide";
import AddInfoForm from "../../Componenets/Investor/AddMoreInfo/AddInfoForm";

function InvestorAddInfo() {
  return (
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <FormSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <AddInfoForm />
        </div>
      </div>
    </div>
  );
}

export default InvestorAddInfo;
