import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  userToken: null,
};

const userBuyPackageSlice = createSlice({
  name: "userbuypackage",
  initialState,
  reducers: {
    userBuyPackageStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    userBuyPackageSuccess(state, action) {
      state.loading = false;

      state.message = action.payload.message;
    },

    userBuyPackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    userSinglePackageStart(state) {
      state.loading = true;
    },

    userSinglePackageSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      console.log(action.payload);

    },

    userSinglePackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  userBuyPackageStart,
  userBuyPackageSuccess,
  userBuyPackageFailure,
  userSinglePackageStart,
  userSinglePackageSuccess,
  userSinglePackageFailure,
  clearError,
  clearMessage,
} = userBuyPackageSlice.actions;

export default userBuyPackageSlice.reducer;
