import React from 'react'
import AdminSidebar from '../../Componenets/Admin/Sidebar/AdminSidebar'
import AdminHeader from '../../Componenets/Admin/Header/AdminHeader'
import InterestAdminGroup from '../../Componenets/Admin/InterestGroup/InterestAdminGroup'

function AdminInterestsGroups(){
    return(
        <div className='container-fluid userdashboard'>
            <div className='row'>
                <div className='col-sm-3 sidebar'>
                    <AdminSidebar/>
                </div>

                <div className='col-sm-9 rightloginform bgdarks'>
                    <AdminHeader/>
                    <InterestAdminGroup />
                </div>
            </div>
        </div>
    )
}

export default AdminInterestsGroups