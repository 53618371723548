import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  error: null,
  data: [],
};

const GetInvestorProfile = createSlice({
  name: "getinvestorprofile",
  initialState,
  reducers: {
    GetInvestorProfileStart(state) {
      state.loading = true;
      state.error = null;
    },

    GetInvestorProfileSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      console.log(state.data, "action profile")
    },

    GetInvestorProfileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  GetInvestorProfileStart,
  GetInvestorProfileFailure,
  clearError,
  clearMessage,
  GetInvestorProfileSuccess,
} = GetInvestorProfile.actions;

export default GetInvestorProfile.reducer;
