import React, { Fragment } from 'react'
import InvestorSidebar from '../../Componenets/Investor/Sidebar/InvestorSidebar'
import InvestorHeader from '../../Componenets/Investor/Header/InvestorHeader'
import Details from '../../Componenets/LearningPortal/Details'

const LearningPortalDetails = () => {
    return (
        <Fragment>
            <div className="container-fluid userdashboard">
                <div className="row">
                    <div className="col-sm-3 sidebar">
                        <InvestorSidebar />
                    </div>

                    <div className="col-sm-9 rightloginform bgImages">
                        <InvestorHeader />
                        <Details />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LearningPortalDetails