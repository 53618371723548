import { configureStore } from "@reduxjs/toolkit";
import { persistedReducer } from "./ConfigStore";
import { persistStore } from "redux-persist";
const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default Store;
export const persistor = persistStore(Store);
