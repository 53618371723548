import React, { Fragment, useEffect } from "react";
import { Form, Input, Image, Button } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Password from "../../../assets/pass.png";

import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/UserAuthReducer";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Loader from "../../Loader";
import { UserConfirmPassword } from "../../../Redux/Actions/UserPasswordReducer";

<Spin
  indicator={
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  }
/>;

function ConfirmPasswordForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { error, message, loading } = useSelector(
    (state) => state.userPassword
  );
  const { data } = useSelector((state) => state.verify);

  const onFinish = (values) => {
    console.log("Success:", values);

    const data = values;

    dispatch(UserConfirmPassword({ id, data }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  console.log(data, "data");

  const validatePassword = (_, value) => {
    if (value && value.length < 6) {
      return Promise.reject("Password must be at least 8 characters long.");
    }
    return Promise.resolve();
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/user/login");
    }
  }, [error, message]);

  return (
    <Fragment>
      <div className="row storyUSer">
        <div className="col-sm-12 loginform">
          <h2>Confirm You`r New Password </h2>
          <p>Don`t Worry if you Forgot You Password</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="firstpassword"
              rules={[
                { required: true, message: "Please input your password!" },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<Image src={Password} preview={false} />}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your Password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("firstpassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The two passwords do not match");
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                prefix={<Image src={Password} preview={false} />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading ? true : false}
              >
                {loading ? <Loader /> : "Send"}
              </Button>
              <br />

              <p className="ques">
                Don't have an account? <Link to="/user/signup">Signup</Link>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default ConfirmPasswordForm;
