import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  token: null,
};

const PostAdminInvestor = createSlice({
  name: "postadmininvestor",
  initialState,
  reducers: {
    PostAdminInvestorStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    PostAdminInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Investor Create Successfully!";
      state.token = action.payload.token;
    },

    PostAdminInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  PostAdminInvestorStart,
  PostAdminInvestorSuccess,
  PostAdminInvestorFailure,
  clearError,
  clearMessage,
} = PostAdminInvestor.actions;

export default PostAdminInvestor.reducer;
