import { Fragment, useEffect } from "react";
import { Button, Form, Input, Select, Upload, Spin, Steps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetInvestorGroup } from "../../../Redux/Actions/GetInvestorGroup";
import { useNavigate } from "react-router-dom";
import { InvestorBusinesss } from "../../../Redux/Actions/PostInvestorBusiness";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/InvestorBusinessPostReducer";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function BusinessInfoForm() {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const investorData = useSelector((state) => state.verify.data);
  console.log(investorData, "investorData");
  const { message, error, loading } = useSelector(
    (state) => state.investorbusiness
  );
  useEffect(() => {
    if (message) {
      toast.success(message);
      Dispatch(clearMessage());
      // navigate("/investor/call-request");
      navigate("/investor/my-profile");
    }
    if (error) {
      toast.error(error);
      Dispatch(clearError());
    }
  }, [Dispatch, error, message, navigate]);

  // Get Groups
  const { data } = useSelector((state) => state.getinvestorgroup);

  useEffect(() => {
    Dispatch(GetInvestorGroup());
  }, [Dispatch]);

  const options = data.map((item) => ({
    value: item._id,
    label: item.groupName,
  }));

  // post form
  const onFinish = async (values) => {
    const formData = new FormData();

    for (let i = 0; i < values.investorGroupIds.length; i++) {
      formData.append("investorGroupIds", values.investorGroupIds[i]);
    }

    formData.append("websiteLink", values.websiteLink);
    formData.append("noCompaniesInvestedIn", values.noCompaniesInvestedIn);
    formData.append("bio", values.bio);
    formData.append("Address", values.Address);

    // Append the video file to FormData for binary
    if (values.video && values.video[0]?.originFileObj) {
      formData.append("video", values.video[0]?.originFileObj);
    }

    try {
      await Dispatch(InvestorBusinesss(formData));
      console.log("Success:", formData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  useEffect(() => {
    if (
      investorData.investor &&
      investorData.investor?.InvestorBusinessInfoDetail
    ) {
      navigate("/investor/my-profile");
    }
  }, [investorData]);

  return (
    <Fragment>
      <div className="row investsss">
        <div className="col-sm-12 recordform bussinessForm">
          <div className="row investss">
            <div className="packageSection">
              <div className="col-sm-12">
                <Steps
                  size="small"
                  current={0}
                  items={[{ title: "Business Information" }]}
                />
                <h2>Business Information</h2>
                <p>Welcome back! please enter your detail</p>
              </div>
            </div>
          </div>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-sm-12">
                <Form.Item
                  name="investorGroupIds"
                  rules={[
                    {
                      required: true,
                      message: "Please Select your Investor Group!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Investor Group"
                    optionFilterProp="children"
                    mode="multiple" // Enable multi-select
                    maxTagCount={5} // Set the maximum number of displayed tags
                  >
                    {options.map((option, index) => (
                      <Option key={option.value || index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="websiteLink"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Website Url!",
                    },
                  ]}
                >
                  <Input placeholder="Website" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="Address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Address!",
                    },
                  ]}
                >
                  <Input placeholder="Address" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="noCompaniesInvestedIn"
                  rules={[
                    {
                      required: true,
                      message: "Please input your No of Company Invested In!",
                    },
                  ]}
                >
                  <Input placeholder="No of Company Invested In" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="bio"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Bio!",
                    },
                  ]}
                >
                  <Input placeholder="Bio" />
                </Form.Item>
              </div>

              <div className="col-sm-12 uploadbtn pitch">
                {/* <Form.Item
                  name="upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action="/upload.do"
                    beforeUpload={() => false}
                    accept="video/*"
                    name="logo"
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>Upload Video</Button>
                  </Upload>
                </Form.Item> */}

                <Form.Item
                  name="video"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action="/upload.do"
                    beforeUpload={() => false}
                    accept="video/*"
                    name="video"
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>Upload Video</Button>
                  </Upload>
                </Form.Item>
              </div>

              <div className="col-sm-12 pitchdeckbtn pitchdeckbtn1">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="recordbtn"
                  >
                    {loading ? (
                      <Spin indicator={antIcon} style={{ color: "#ffff" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default BusinessInfoForm;
