import React from 'react'
import LoginSlide from '../../Componenets/User/Login/LoginSlide'
import RecordForm from '../../Componenets/User/Record/RecordForm'

function StoryVideo() {
  return (
    <div className='container-fluid userlogin'>
        <div className='row'>
            <div className='col-sm-6 leftslideuser'>
                <LoginSlide/>
            </div>

            <div className='col-sm-6 rightloginform'>
            <RecordForm/>
            </div>
        </div>
    </div>
  )
}

export default StoryVideo