import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const editInvestorSlice = createSlice({
  name: "editinvestor",
  initialState,
  reducers: {
    editInvestorStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },

    editInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    singleInvestorStart(state) {
      // state.loading = true;
    },

    singleInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    singleInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  editInvestorStart,
  editInvestorSuccess,
  editInvestorFailure,
  singleInvestorStart,
  singleInvestorSuccess,
  singleInvestorFailure,
  clearError,
  clearMessage,
} = editInvestorSlice.actions;

export default editInvestorSlice.reducer;
