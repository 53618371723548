import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editInvestorProfileStart,
  editInvestorProfileSuccess,
  editInvestorProfileFailure,
} from "../Reducers/EditInvestorProfile";
import axios from "axios";

export const EditInvestorProfile = createAsyncThunk(
  "editInvestorProfile",
  async (data, thunkAPI) => {
    try {
      const investorstoken = thunkAPI.getState().investorauth.investortoken;

      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

      thunkAPI.dispatch(editInvestorProfileStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}investor/updateProfile`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(editInvestorProfileSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        editInvestorProfileFailure(error.response.data.message)
      );
    }
  }
);
