import React from 'react'

import LoginAdminSlide from '../../Componenets/Admin/Login/LoginAdminSlide'
import Adminlogin from '../../Componenets/Admin/Login/Adminlogin'
import { Link } from "react-router-dom";

function AdminLogin() {
  return (
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginAdminSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <Adminlogin />
        </div>
        
      </div>
    </div>
  );
}

export default AdminLogin