import React, { Fragment, useEffect, useState } from "react";
import user from "../../../assets/user.png";
import circle from "../../../assets/round.png";
import map from "../../../assets/map.png";
import city from "../../../assets/city.png";
import dail from "../../../assets/dail.png";
import linkedin from "../../../assets/linkedInn.png";
import env from "../../../assets/env.png";
import link from "../../../assets/link.png";
import circlePlay from "../../../assets/Circled Play.png";
import close from "../../../assets/Close.png";
import Document from "../../../assets/Document.png";
import calendars from "../../../assets/calendar.png";
import copy from "../../../assets/Copy.png";
import { Button, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdminCallDetail } from "../../../Redux/Actions/adminCallDetail";
import VideoPlayer from "./VideoPlayer";
import { AdminShareMeeting } from "../../../Redux/Actions/AdminShareMeeting";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/AdminShareMeeting";
import Loader from "../../Loader";
import moment from "moment";

function UserCallRequestDetails() {
  const [changeColor, setChangeColor] = useState("first");

  const { data } = useSelector((state) => state.adminCallDetail);
  const { error, loading, message } = useSelector(
    (state) => state.adminShareMeeting
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);

    // Find the schedule with callStatus set to true
    const selectedSchedule = data?.sceduleDatesDetails.find(
      (elem) => elem.callStatus === true
    );

    // If a schedule is found, assign its _id to the DateId field in the values
    if (selectedSchedule) {
      values.DateId = selectedSchedule._id;
      console.log("Selected Schedule _id:", selectedSchedule._id);
    } else {
      toast.error("No schedule with callStatus true found.");
    }

    // Dispatch your action or perform other operations as needed
    dispatch(AdminShareMeeting(values));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const colorHandler = (color) => {
    setChangeColor(color);
  };

  useEffect(() => {
    const id = state;

    dispatch(AdminCallDetail({ id }));
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/admin/call-request");
      dispatch(clearMessage());
    }
  }, [error, message]);
  console.log(data, "");
  return (
    <Fragment>
      <div className="container-fluid mainedit">
        <div className="col-sm-12 bgWhite">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            name="basic"
            initialValues={{
              remember: true,
            }}
          >
            <div className="row userDetailStory">
              <p className="callRequestHead">USER DETAILS</p>

              <div className="col-sm-7 userDetails">
                <div className="row usDt">
                  <div className="col-sm-4 usImg">
                    <img
                      src={data?.callIdDetails?.userCallDetails?.profile_pic}
                    />

                    <div className="row interestUser">
                      {data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.userInvestorGroups.map(
                        (elem) => (
                          <div className="col-sm-4 imagebox1">
                            <p>{elem?.userInvestorGroupName?.groupName}</p>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="col-sm-4 userName">
                    <p className="title">
                      {data?.callIdDetails?.userCallDetails?.firstName + " " + data?.callIdDetails?.userCallDetails?.lastName}
                    </p>
                    <p className="colorBlue">
                      <img src={circle} /> Round{" "} {data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.Round}
                    </p>
                    <p className="colorGrey">
                      Business Name <br />
                      <b>{data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.businessName}</b>
                    </p>
                    <div className="insideUsName">
                      <div className="div1">
                        <p className="small">Interest Group</p>
                        <p className="large">
                          {" "}
                          {data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.userInvestorGroups.map(
                            (elem) => (
                              <p>{elem?.userInvestorGroupName?.groupName}</p>
                            )
                          )}
                        </p>
                      </div>

                      <div className="div2">
                        <p className="small">Investment Amount</p>
                        <p className="large">$ {data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.InvestmentRaised} </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4 listDetailsss">
                    <div className="insideListDetailsDiv">
                      <img src={map} />
                      <p> {data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.currentAddress} </p>
                    </div>
                    <div className="insideListDetailsDiv">
                      <img src={city} />
                      <p> {" "} {data?.callIdDetails?.userCallDetails?.userInfo?.userCity?.name}, 
                        {" "} {data?.callIdDetails?.userCallDetails?.userInfo?.userCity?.StateData?.CountryDetail?.name}
                      </p>
                    </div>
                    <div className="insideListDetailsDiv">
                      <img src={dail} />
                      <p> {data?.callIdDetails?.userCallDetails?.userInfo?.pnum} </p>
                    </div>
                    <div className="insideListDetailsDiv">
                      <img src={linkedin} />
                      <p>
                        {
                          data?.callIdDetails?.userCallDetails
                            ?.userBusinessInfoData?.businessName
                        }
                      </p>
                    </div>
                    <div className="insideListDetailsDiv">
                      <img src={env} />
                      <p>
                        <a
                          href={`mailto:${data?.callIdDetails?.userCallDetails?.email}`}
                        >
                          {data?.callIdDetails?.userCallDetails?.email}
                        </a>
                      </p>
                    </div>
                    <div className="insideListDetailsDiv">
                      <img src={link} />
                      {/* <p>www.mybusiness.com</p> */}
                      <p>
                        {
                          data?.callIdDetails?.userCallDetails
                            ?.userBusinessInfoData?.websiteLink
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-5 userStory">
                <div className="row">
                  <div className="col-sm-6">
                    <p className="headsss">User Story</p>
                    <div className="imageBox">
                      {/* <img src={circlePlay} /> */}
                      <VideoPlayer
                        link={
                          data?.callIdDetails?.userCallDetails?.userStoryData
                            ?.Video
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 pitchDeck">
                    <div className="pitchDeckFilesss">
                      <p className="headsss">Pitch Deck</p>
                      <div className="docBox piutch">
                        <div className="doc1">
                          <div className="docImagessss">
                            <a
                              href={
                                data?.callIdDetails?.userCallDetails
                                  ?.userPitchDeckData.file
                              }
                              target="_blank"
                            >
                              {" "}
                              <img src={Document} className="documentImagesss" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pitchDeckFilesss">

                    <p className="headsss">Business Files</p>
                    <div className="docBox piutch">
                      <div className="doc1">
                        <div className="docImagessss">
                          {data?.callIdDetails?.userCallDetails?.userBusinessInfoData?.UserBusinessFileData?.map(
                            (elem, i) => (
                              <a href={elem?.file} target="_blank">
                                {" "}
                                <img
                                  src={Document}
                                  className="documentImagesss"
                                />
                              </a>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row investorCall">
              <div className="col-sm-7 investorDetails">
                <p className="callRequestHead">Investor Details</p>
                <div className="row">
                  <div className="col-sm-4 investorImage">
                    <img
                      src={data?.callIdDetails?.investorCallDetails?.image}
                    />
                  </div>
                  <div className="col-sm-4 investorDetailsss">
                    <p className="title">
                      {" "}
                      {data?.callIdDetails?.investorCallDetails?.firstName +
                        " " +
                        data?.callIdDetails?.investorCallDetails?.lastName}
                    </p>
                    <div className="insideUsName investorrrrr">
                      <div className="div2">
                        <p className="small">Interest Group</p>
                        {/* <p className="large">Accounts</p> */}
                        {data?.callIdDetails?.investorCallDetails?.investorRelationToBusinessInfo?.investorBusinessInfoData.map(
                          (elem) => (
                            <p className="large">{elem?.investorInvestorGroupName?.groupName}</p>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 listDetailsss investorDetailsss">
                    <div className="insideListDetailsDiv">
                      <img src={map} />
                      <p>
                        {" "}
                        {
                          data?.callIdDetails?.investorCallDetails
                            ?.investorRelationToBusinessInfo?.Address
                        }
                      </p>
                    </div>

                    <div className="insideListDetailsDiv">
                      <img src={city} />
                      <p>
                        {" "}
                        {
                          data?.callIdDetails?.investorCallDetails?.invesotrinfo
                            ?.investorCity?.name
                        }
                        ,{" "}
                        {
                          data?.callIdDetails?.investorCallDetails?.invesotrinfo
                            ?.investorCity?.StateData?.CountryDetail?.name
                        }
                      </p>
                    </div>

                    <div className="insideListDetailsDiv">
                      <img src={dail} />
                      <p>
                        {
                          data?.callIdDetails?.investorCallDetails?.invesotrinfo
                            ?.pnum
                        }
                      </p>
                    </div>

                    <div className="insideListDetailsDiv">
                      <img src={env} />
                      <p>
                        <a href="">
                          {
                            data?.callIdDetails?.investorCallDetails
                              ?.investorRelationToBusinessInfo?.websiteLink
                          }
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {(data?.status === "accepted" &&
                data?.callIdDetails?.meetingStatus) ||
              data?.status === "pending" ? (
                <p></p>
              ) : (
                <div className="col-sm-5 callDetailss">
                  <p className="callRequestHead">Call Details</p>
                  <div className="row ucrd">
                    <Form.Item name="dateselect">
                      <div className="col-sm-12 calDet">
                        {data?.sceduleDatesDetails?.map((elem) => (
                          <div
                            className={
                              elem?.callStatus === true
                                ? "calendarsss green"
                                : "calendarsss grey"
                            }
                            // onClick={() => colorHandler("first")}
                          >
                            <img src={calendars} />
                            <p>
                              {moment(elem.SceduledDate).format(
                                "YYYY-MM-DD hh:mm:ss A"
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    </Form.Item>
                    {/* <div className="col-sm-4 btttnnMeet">
                <Button>Generate Meeting Link</Button>
              </div> */}
                    <Form.Item
                      name="meetingLink"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Meeting Link",
                        },
                      ]}
                    >
                      <div className="col-sm-12 linkMeettt">
                        <div className="insideLinkMeet">
                          <Input.TextArea
                            rows={4}
                            placeholder="Meeting Link For Zoom"
                          />
                          <img src={copy} />
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>

            {(data?.status === "accepted" &&
              data?.callIdDetails?.meetingStatus) ||
            data?.status === "pending" ? (
              <></>
            ) : (
              <div className="row butoon">
                <div className="adminAddSubscriptionPackageButton adminSaveee">
                  {data?.status === "pending" ? (
                    <Button htmlType="submit">
                      {loading ? <Loader /> : "Send"}
                    </Button>
                  ) : (
                    <Button htmlType="submit">
                      {loading ? <Loader /> : "Send"}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default UserCallRequestDetails;
