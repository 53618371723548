import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import edit from "../../../assets/Pen Squared.png";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeletePackage, GetPackage } from "../../../Redux/Actions/Package";
import Pagination from "../../Pagination";

function AdminSubsPackage() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.packages);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const navigate = useNavigate();
  const handleEdit = (packageData) => {
    console.log("Edit");
    navigate("/admin/edit-subscription-package", {
      state: {
        data: packageData,
      },
    });
  };
  const handleDelete = async (id) => {
    await dispatch(DeletePackage({ id }));
    dispatch(GetPackage({ page, size }));
  };
  useEffect(() => {
    dispatch(GetPackage({ page, size }));
  }, [dispatch, page, size]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="adminSubsPackageButton">
          <Link to="/admin/add-subscription-package">
            <Button>Add Subscription Package</Button>
          </Link>
        </div>

        <div className="mobresponnTable">
          <table className="adminSubsPackageTable subscribeTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow">
                <th className="subAdmNAme">Name</th>
                <th className="subAdmDesc ">Description</th>
                <th className="subAdmPrice">Price</th>
                <th className="subAdmDays">No. of Days</th>
                <th className="subAdmInvestor">No. of Investors</th>
                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow" key={i}>
                  <td className="subAdmNAme">{value?.PackageName}</td>
                  <td className="subAdmDesc ">
                    <div className="heightss">
                      <> {value?.description} </>
                    </div>
                  </td>
                  <td className="subAdmPrice">{value?.price}</td>
                  <td className="subAdmDays">{value?.noOfDays}</td>
                  <td className="subAdmInvestor">{value?.reachLimit}</td>
                  <td className="actionIcons">
                    <img
                      src={edit}
                      className="actionIconsAdm"
                      onClick={() => handleEdit(value)}
                    />
                    <img
                      src={trash}
                      className="actionIconsAdm"
                      onClick={() => handleDelete(value._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
    </div>
  );
}

export default AdminSubsPackage;
