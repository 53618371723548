import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postInterestGroupStart,
  postInterestGroupSuccess,
  postInterestGroupFailure,
  editinterestGroupStart,
  editinterestGroupSuccess,
  editPackageFailure,
  interestGroupStart,
  interestGroupSuccess,
  interestGroupFailure,
  deleteInterestGroupStart,
  deleteInterestGroupSuccess,
  deleteInterestgroupFailure,
} from "../Reducers/InterestGroupReducer";
import axios from "axios";

export const PostInterestGroup = createAsyncThunk(
  "interestGroup",
  async (data, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      console.log(adminAuth, "states");
      thunkAPI.dispatch(postInterestGroupStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/addInvestorGroup`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(postInterestGroupSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(postInterestGroupFailure(error.response.data.message));
    }
  }
);

export const EditInterestGroup = createAsyncThunk(
  "interestGroup",
  async ({ id, values }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      console.log(adminAuth, "<====adminAuth");
      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      thunkAPI.dispatch(editinterestGroupStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/editInvestorGroupName/${id}`,
        values,
        { headers: header }
      );

      thunkAPI.dispatch(editinterestGroupSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(editPackageFailure(error.response.data.message));
    }
  }
);

export const GetInterestGroup = createAsyncThunk(
  "interestGroup",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(interestGroupStart());
      console.log(page, size, "page, size");
      const queryParams = {
        ...(page && { page }),

        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}getAllInvestorGroupNames`,
        { params: queryParams }
      );

      thunkAPI.dispatch(interestGroupSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(interestGroupFailure(error.response.data.message));
    }
  }
);

export const DeleteInterestGroup = createAsyncThunk(
  "interestGroup",
  async ({ id }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      thunkAPI.dispatch(deleteInterestGroupStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}admin/deleteInvestorGroupName/${id}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(deleteInterestGroupSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        deleteInterestgroupFailure(error.response.data.message)
      );
    }
  }
);

