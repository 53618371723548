import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  error: null,
  data: [],
};

const GetInvestorAccInterestSlice = createSlice({
  name: "getInvestorAccInterest",
  initialState,
  reducers: {
    getInvestorAccInterestStart(state) {
      state.loading = true;
      state.error = null;
    },

    getInvestorAccStartSuccess(state, action) {
      state.loading = false;
      state.data = action?.payload?.data;
    },

    getInvestorAccInterestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  getInvestorAccInterestStart,
  getInvestorAccInterestFailure,
  clearError,
  clearMessage,
  getInvestorAccStartSuccess,
} = GetInvestorAccInterestSlice.actions;

export default GetInvestorAccInterestSlice.reducer;
