import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  dataeApprovalStart,
  dataeApprovalSuccess,
  dateApprovalFailure,
} from "../Reducers/DateApprovalReducer";
import axios from "axios";

export const DateAprroval = createAsyncThunk(
  "DateAprroval",
  async (id, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      thunkAPI.dispatch(dataeApprovalStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/callApprove/${id}`,

        {
          headers: header,
        }
      );

      thunkAPI.dispatch(dataeApprovalSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(dateApprovalFailure(error.response.data.message));
    }
  }
);
