import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInvestorAccInterestStart,
  getInvestorAccStartSuccess,
  getInvestorAccInterestFailure,
} from "../Reducers/GetInvestorAccInterestReducer";
import axios from "axios";

export const GetInvestorAccInterest = createAsyncThunk(
  "getInvestorAccInterest",
  async ({page, size}, thunkAPI) => {
    try {
      thunkAPI.dispatch(getInvestorAccInterestStart());
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/sameInterestsInvestors`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(getInvestorAccStartSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        getInvestorAccInterestFailure(error.response.data.message)
      );
    }
  }
);
