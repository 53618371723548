import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  adminToken: null,
};

const adminAuthSlice = createSlice({
  name: "adminauth",
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    loginSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Login Successfully";
      state.adminToken = action.payload.data.token;
      Cookies.set("token", action.payload.data.token);
    },

    logOutSuccess(state) {
      state.data = null;
      state.adminToken = null;
      state.message = null; 
      Cookies.remove("token");
      localStorage.removeItem("persist:root");

    },

    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  clearError,
  clearMessage,
  logOutSuccess,
} = adminAuthSlice.actions;

export default adminAuthSlice.reducer;
