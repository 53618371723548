import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  token: null,
};

const UserRescheduleCard = createSlice({
  name: "userreschedulerard",
  initialState,
  reducers: {
    UserRescheduleCardStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    UserRescheduleCardSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      state.token = action.payload.token;
    },

    UserRescheduleCardFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  UserRescheduleCardStart,
  UserRescheduleCardSuccess,
  UserRescheduleCardFailure,
  clearError,
  clearMessage,
} = UserRescheduleCard.actions;

export default UserRescheduleCard.reducer;
