import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  businessInfoStart,
  businessInfoSuccess,
  businessFailure,
} from "../Reducers/UserBusinessInfoReducer";
import axios from "axios";

export const UserBusinessInfo = createAsyncThunk(
  "userbusinessinfo",
  async (data, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      thunkAPI.dispatch(businessInfoStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/addBusinesInfo`,
        data,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(businessInfoSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(businessFailure(error.response.data.message));
    }
  }
);
