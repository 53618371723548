import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const allCallSlice = createSlice({
  name: "allCalls",
  initialState,
  reducers: {
    allCallStart(state) {
      state.loading = true;
      state.error = null;
    },

    allCallSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    allCallFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  allCallStart,
  allCallSuccess,
  allCallFailure,
  clearError,
  clearMessage,
} = allCallSlice.actions;

export default allCallSlice.reducer;
