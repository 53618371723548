import React, { Fragment, useEffect } from "react";
import LeftSec from "./LeftSec";
import RightSec from "./RightSec";
import { useDispatch, useSelector } from "react-redux";
import { SingleUserCalls } from "../../../Redux/Actions/SingleUserCall";
import { useLocation, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function RescheduleDetail() {
  const { loading, data } = useSelector((state) => state.singleusercall);
  const Dispatch = useDispatch();
  const { id } = useParams();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    Dispatch(SingleUserCalls({ id }));
  }, [Dispatch, id]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row heights">
          <div className="col-sm-12 maincol">
            <div className="row">
              <div className="col-sm-6">
                <LeftSec data={data} />
              </div>

              <div className="col-sm-6">
                <RightSec data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RescheduleDetail;
