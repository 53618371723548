import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,

  token: null,
};

const InvestorUserDetailSlice = createSlice({
  name: "investordetail",
  initialState,
  reducers: {
    investorDetailStart(state) {
      state.loading = true;
      state.error = null;
    },

    investorDetailSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      console.log(action.payload, "<====payload");
    },

    investorDetailFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  investorDetailStart,
  investorDetailSuccess,
  investorDetailFailure,
  clearError,
  clearMessage,
} = InvestorUserDetailSlice.actions;

export default InvestorUserDetailSlice.reducer;
