import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postPackageStart,
  postPackageSuccess,
  postPackageFailure,
  editPackageStart,
  editPackageSuccess,
  editPackageFailure,
  packagestart,
  packagesuccess,
  PackageFailure,
  deletePackageStart,
  deletePackageSuccess,
  deletePackageFailure,
  userPackagestart,
  userPackagesuccess,
  userPackageFailure,
} from "../Reducers/PackageReducer";
import axios from "axios";

export const PostPackage = createAsyncThunk(
  "package",
  async (data, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      console.log(adminAuth, "<====adminAuth");
      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      console.log(adminAuth, "states");
      thunkAPI.dispatch(postPackageStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/createPackage`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(postPackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(postPackageFailure(error.response.data.message));
    }
  }
);

export const EditPackage = createAsyncThunk(
  "package",
  async ({ id, values }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      console.log(adminAuth, "<====adminAuth");
      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      thunkAPI.dispatch(editPackageStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/updatePackage/${id}`,
        values,
        { headers: header }
      );

      thunkAPI.dispatch(editPackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(editPackageFailure(error.response.data.message));
    }
  }
);

export const GetPackage = createAsyncThunk(
  "package",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(packagestart());

      const params = {
        ...(page && { page }),
        size: size || 100,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/AllPackages`,
        {
          params,
        }
      );

      thunkAPI.dispatch(packagesuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(PackageFailure(error.response.data.message));
    }
  }
);

export const DeletePackage = createAsyncThunk(
  "package",
  async ({ id }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      thunkAPI.dispatch(deletePackageStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}admin/deletePackage/${id}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(deletePackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(deletePackageFailure(error.response.data.message));
    }
  }
);

export const GetUserPackage = createAsyncThunk(
  "package",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userPackagestart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/AllPackages?size=100`
      );

      thunkAPI.dispatch(userPackagesuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(userPackageFailure(error.response.data.message));
    }
  }
);
