import React, { useEffect } from "react";
import InvestorSidebar from "../../Componenets/User/Sidebar/SideBar";
import InvestorHeader from "../../Componenets/User/Header/Header";
import InvestorDetail from "../../Componenets/User/InvestorDetail/InvestorDetail";

function InvestorDetailPage() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <InvestorSidebar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <InvestorHeader />
          <InvestorDetail />
        </div>
      </div>
    </div>
  );
}

export default InvestorDetailPage;
