import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom';
import book from '../../assets/Group-removebg-preview.png'

const convertToTitleCase = (str) => {
    const words = str.split('-');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return capitalizedWords;
};

const Details = () => {
    const { title } = useParams();
    const abc = convertToTitleCase(title);
    console.log(abc);


    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    return (
        <Fragment>
            <div className="container-fluid investorlist">
                <div className="row sociall">
                    <div className="col-sm-12 socialDetailsss">
                        <div className='boxesDetailSocial'>
                            {
                                arr.map((value, i) => (
                                    <div className="socialBox" key={i}>
                                        <div className="boxGrey">

                                        </div>
                                        <p>{abc}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="col-sm-12 bgImageRemove">
                        <img src={book} alt='bgImage' />
                    </div>
                </div>
            </div> 
        </Fragment>
    )
}

export default Details