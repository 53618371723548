import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  error: null,
  data: [],
};

const GetInvCallReqSlice = createSlice({
  name: "getinvcallreq",
  initialState,
  reducers: {
    GetInvCallReqStart(state) {
      state.loading = true;
      state.error = null;
    },

    GetInvCallReqSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      console.log(action.payload, "data")
    },

    GetInvCallReqFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  GetInvCallReqStart,
  GetInvCallReqFailure,
  clearError,
  clearMessage,
  GetInvCallReqSuccess,
} = GetInvCallReqSlice.actions;

export default GetInvCallReqSlice.reducer;
