import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCountryStart,
  getCountrySuccess,
  getCountryFailure,
} from "../Reducers/GetCountryReducer";
import axios from "axios";

export const GetCountry = createAsyncThunk(
  "getcountry",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCountryStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}country?size=300`
      );
      console.log(response);
      thunkAPI.dispatch(getCountrySuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(getCountryFailure(error.response.data.message));
    }
  }
);
