import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const newsLetterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    newsLetterStart(state) {
      state.loading = true;
      state.error = null;
    },

    newsLetterSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    newsLetterFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteNewsLetterStart(state) {
      state.loading = true;
      state.error = null;
    },

    deleteNewsLetterSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      console.log(action.payload, "<====payload");
    },

    deleteNewsLetterFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  newsLetterStart,
  newsLetterSuccess,
  newsLetterFailure,
  deleteNewsLetterStart,
  deleteNewsLetterSuccess,
  deleteNewsLetterFailure,
  clearError,
  clearMessage,
} = newsLetterSlice.actions;

export default newsLetterSlice.reducer;
