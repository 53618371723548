import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const bussinessleadsSlice = createSlice({
  name: "bussinessleads",
  initialState,
  reducers: {
    bussinessLeadsStart(state) {
      state.loading = true;
      state.error = null;
    },

    bussinessLeadsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    bussinessLeadsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deletebussinessLeadsStart(state) {
      state.loading = true;
      state.error = null;
    },

    deletebussinessLeadsSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      console.log(action.payload, "<====payload");
    },

    deletebussinessLeadsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  bussinessLeadsStart,
  bussinessLeadsSuccess,
  bussinessLeadsFailure,
  deletebussinessLeadsStart,
  deletebussinessLeadsSuccess,
  deletebussinessLeadsFailure,
  clearError,
  clearMessage,
} = bussinessleadsSlice.actions;

export default bussinessleadsSlice.reducer;
