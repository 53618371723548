import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Packagelist } from "../../../Data/Data";
import { Button, Form, Input, Image, DatePicker } from "antd";
import Cards from "../../../assets/cards.png";
import { useDispatch, useSelector } from "react-redux";
import {
  UserBuyPackage,
  userSinglePackage,
} from "../../../Redux/Actions/UserBuyPackage";
import Loader from "../../Loader";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/BuyPackageReducer";
import { toast } from "react-toastify";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { buyStripePackage } from "../../../Redux/Actions/BuyPackage";

function SinglePackage() {
  const stripe = useStripe();
  const elements = useElements();
  const [loadings, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useSelector((state) => state.buyPackage);
  const { loading, message, error } = useSelector((state) => state.buyPackages);
  const intent = useSelector((state) => state.buyPackages.paymentIntent);

  const [cardNumber, setCardNumber] = useState("");

  const formatCardNumber = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Insert hyphens after every 4 digits
    const formattedValue = numericValue.replace(/(\d{4})/g, "$1-").slice(0, 19); // Limit to 19 characters (16 digits and 3 hyphens)

    setCardNumber(formattedValue);
  };

  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };
  console.log(intent, "<======intent");
  const onFinish = async () => {
    setLoading(true);
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(intent, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (paymentIntent) {
        // Payment successful
        console.log("Payment Intent:", paymentIntent);

        // Add logic to handle successful payment
        const id = paymentIntent.id;

        await dispatch(buyStripePackage(id));
      } else if (error) {
        // Payment failed

        toast.error(error.message);
      }
    } catch (error) {
      toast.error(error.message);
      // Handle any unexpected errors during payment confirmation
    }

    setLoading(false);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate(-1);
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    dispatch(userSinglePackage(id));
  }, [dispatch]);
  console.log(data, "<===data");
  return (
    <Fragment>
      <div className="container-fluid singlepackage">
        <div className="row ">
          <div className="col-sm-8">
            <img src={Cards} alt="asd" />
            <Form name="basic" onFinish={onFinish} autoComplete="off">
              <div className="row formrow">
                <div className="col-sm-12">
                  <Form.Item name="form">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            color: "#000000",
                            "::placeholder": {
                              color: "#A9A9A9",
                            },
                          },
                        },
                      }}
                      onChange={(event) => {
                        console.log("CardElement [change] event:", event);

                        // Accessing card number, CVC, and ZIP code when available
                        if (event.complete && event.value && event.value.card) {
                          const cardData = event.value.card;
                          console.log("Card Number:", cardData.number);
                          console.log("CVC:", cardData.cvc);
                          console.log("ZIP Code:", cardData.address_zip);
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pkgbtn"
                  disabled={!stripe || loading}
                >
                  {loading ? <Loader/> : "BUY NOW"}
                </Button>
                <br />
              </Form.Item>
            </Form>
          </div>

          <div className="col-sm-4 packages">
            <p className="sale">{data?.data?.discount}% OFF</p>
            <h2 className="title">{data?.data?.PackageName}</h2>
            <div className="card">
              <p>{data?.data?.description}</p>
              <h4 className="days">{data?.data?.noOfDays} Days</h4>
              <h5>
                Contact <br /> <span>{data?.data?.reachLimit} Investor</span>
              </h5>
              <h6 className="cost">${data?.data?.price}</h6>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SinglePackage;
