import LoginSlide from "../../Componenets/Investor/Login/LoginSlide";
import LoginForm from "../../Componenets/Investor/Login/LoginForm";
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';


function InvestorLogin() {
  return (
    <Fade left delay={100}>
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <LoginForm />
        </div>


      </div>
    </div>
    </Fade>
  );
}

export default InvestorLogin;
