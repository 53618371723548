import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetInvestorProfileStart,
  GetInvestorProfileSuccess,
  GetInvestorProfileFailure,
} from "../Reducers/GetInvProfileReducer";
import axios from "axios";

export const GetInvestorProfile = createAsyncThunk(
  "getinvestorprofile",
  async (_, thunkAPI) => {
    try {

      thunkAPI.dispatch(GetInvestorProfileStart());
      const investorstoken= thunkAPI.getState().investorauth.investortoken
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };
      console.log(investorstoken, "myprofileresponsetoken")
      
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/profile` ,{ headers: header,  } 
      );
      console.log(response, "myprofileresponse")

      thunkAPI.dispatch(GetInvestorProfileSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(GetInvestorProfileFailure(error.response.data.message));
    }
  }
);
