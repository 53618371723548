import React, { Fragment, useEffect, useState } from "react";
import Users from "../../../assets/user.png";
import Round from "../../../assets/round.png";
import { Link, useNavigate } from "react-router-dom";
import PDF1 from "../../../assets/pdf1.png";
import PDF2 from "../../../assets/pdf2.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Map from "../../../assets/map.png";
import City from "../../../assets/city.png";
import Phone from "../../../assets/dail.png";
import Linkdin from "../../../assets/linkedInn.png";
import Mail from "../../../assets/mail.png";
import Chain from "../../../assets/link.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../../Redux/Actions/UserProfile";
import VideoPlayer from "./VideoPlayer";
import Avatar from "../../../assets/default.png";

function LeftSec({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [videoState, setVideoState] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNavigate = (id) => {
    navigate("/user/edit-profile", {
      state: {
        id,
      },
    });
  };

  useEffect(() => {
    dispatch(UserProfile());
  }, [dispatch]);

  useEffect(() => {
    setVideoState(data?.userStoryData?.Video);
  }, [data?.userStoryData?.Video]);

  return (
    <Fragment>
      <div className="row leftsidedetail userProffffile">
        <div className="col-sm-12 imagecol">
          <img src={data?.profile_pic ? data?.profile_pic : Avatar} alt="asd" />
          <div className="row interestUser scheduleCallsSwiper">
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination]}
              autoplay={{ delay: 1500, disableOnInteraction: false }}
              className="mySwiper"
            >
              {data?.userBusinessInfoData?.userInvestorGroups?.map((item) => (
                <SwiperSlide>
                  <div className="col-sm-8">
                    <p>{item?.userInvestorGroupName?.groupName}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="row bottombox">
        <div className="col-sm-6 deckpdf">
          <h3>Pitch Deck</h3>
          <a href={data?.userPitchDeckData?.file} target="_blank">
            {" "}
            <img src={PDF1} alt="asd" />
          </a>
        </div>
        <div className="col-sm-6 deckpdf">
          <h3>Bussiness Info</h3>
          {data?.userBusinessInfoData?.UserBusinessFileData?.map((item) => (
            <a href={item.file} target="_blank">
              {" "}
              <img src={PDF1} alt="asd" />
            </a>
          ))}
        </div>
      </div>

      <div className="row butss">
        <div className="col-sm-6">
          <Button variant="primary" onClick={handleShow}>
            Venture Story{" "}
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>View Venture Story</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              {data ? <VideoPlayer link={videoState} /> : <></>}!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {" "}
                Close{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="col-sm-6">
          <Button
            className="editLink"
            onClick={() => handleNavigate(data?._id)}
          >
            {" "}
            Edit Profile{" "}
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default LeftSec;
