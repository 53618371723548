import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const CallDetailSlice = createSlice({
  name: "callDetail",
  initialState,
  reducers: {
    CallDetailStart(state) {
      state.loading = true;
      state.error = null;
    },

    CallDetailSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    CallDetailFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  CallDetailStart,
  CallDetailSuccess,
  CallDetailFailure,
  clearError,
  clearMessage,
} = CallDetailSlice.actions;

export default CallDetailSlice.reducer;
