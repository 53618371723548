import React, { Fragment, useEffect } from "react";
import { Button, Checkbox, Form, Input, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Envlop from "../../../assets/env.png";
import Password from "../../../assets/pass.png";
import { useDispatch, useSelector } from "react-redux";
import { AdminAuthLogin } from "../../../Redux/Actions/AdminAuth";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/AdminAuthReducer";
import { toast } from "react-toastify";
import Loader from "../../Loader";

function Adminlogin() {
  const { error, message, loading } = useSelector((state) => state.adminAuth);
  const { data } = useSelector((state) => state.verify);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    dispatch(AdminAuthLogin(values));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    if (data.admin) {
      navigate("/admin/subscription-packages");
    }
  }, [data]);


  return (
    <Fragment>
      <div className="row storyUSer">
        <div className="col-sm-12 loginform">
          <h2>Admin Login</h2>
          <p>Welcome back! please enter your detail</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                placeholder="Please Enter E-mail"
                prefix={<Image src={Envlop} preview={false} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<Image src={Password} preview={false} />}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : "Login"}
              </Button>
              <br />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default Adminlogin;
