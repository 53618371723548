import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Upload, Select, Tag } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetInvestorGroup } from "../../../Redux/Actions/GetInvestorGroup";
import {
  EditAdminUser,
  SingleAdminUser,
  SingleUser,
} from "../../../Redux/Actions/User";
import { GetCity } from "../../../Redux/Actions/GetCity";
import { GetCountry } from "../../../Redux/Actions/GetCountry";
import PDF1 from "../../../assets/pdf1.png";
import { toast } from "react-toastify";
import {
  EditUserProfile,
  deleteUserFile,
} from "../../../Redux/Actions/EditUserProfile";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/editUserProfile";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";
import { roundOption } from "../../../Data/Data";
import DeleteModal from "../../Modals/DeleteModal";

function AdminEditsUser() {
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
  ];
  const { Option } = Select;
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.user);
  const { loading, message, error, deleteMessage } = useSelector(
    (state) => state.user
  );

  const interestGroup = useSelector((state) => state.getinvestorgroup.data);
  const countryData = useSelector((state) => state.country.data);
  const cityData = useSelector((state) => state.city.data);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [defaultVideoFile, setDefaultVideoFile] = useState(null);
  const [defaultPitchDeck, setDefaultPitchDeck] = useState(null);
  const [userBusinessFileData, setUserBusinessFileData] = useState([]);
  const [defaultProfilePic, setDefaultProfilePic] = useState(null);
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };
  const param = useParams();

  const handleChange = (e) => {
    dispatch(GetCity(e));
  };
  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const uploadProps = {
    name: "file",
    action: "/upload.do",
    listType: "picture",
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        toast.error("You can only upload PDF files!");
      }
      return false; // Returning false allows multiple files to be uploaded
    },
    onChange(info) {
      // ... your existing code
    },
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleUserProfileChange = ({ fileList }) => {
    // Allow only one video to be selected
    const newFileList = fileList.slice(-1);

    form.setFieldsValue({ profilePic: newFileList });
    setDefaultProfilePic(newFileList);
  };

  const handleInputSearch = (value) => {
    if (value && !tags.includes(value)) {
      setTags([...tags, value]);
      form.setFieldsValue({ NoOfPartners: "" }); // Reset the specific field
    }
  };
  const handleTagClose = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const handleFileDelete = (id) => {
    dispatch(deleteUserFile({ id }));
    dispatch(SingleUser());
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // console.log(data, "<=======data");
  const handleUserStoryChange = ({ fileList }) => {
    // Allow only one video to be selected
    const newFileList = fileList.slice(-1);

    form.setFieldsValue({ story: newFileList });
    setDefaultVideoFile(newFileList);
  };

  const onFinish = (values) => {
    console.log(values, "<=====values");

    const formData = new FormData();

    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);

    formData.append("LinkedInName", values.LinkedInName);
    formData.append("address", values.address);
    formData.append("cityId", values.cityId.value);
    formData.append("pnum", values.pnum);
    formData.append("businessName", values.businessName);
    formData.append("Round", values.Round);
    formData.append("InvestmentRaised", values.InvestmentRaised);
    formData.append("noOfEmployee", values.noOfEmployee);

    formData.append("websiteLink", values.websiteLink);
    if (values.story && values.story.length > 0) {
      formData.append("story", values.story[0].originFileObj);
    }
    if (values.pitchDeck && values.pitchDeck.length > 0) {
      formData.append("pitchDeck", values.pitchDeck[0].originFileObj);
    }
    for (let i = 0; i < tags.length; i++) {
      formData.append("NoOfPartners", tags[i]);
    }

    for (let i = 0; i < values.investorGroupIds.length; i++) {
      formData.append("InvestorGroupIds", values.investorGroupIds[i]);
    }

    if (values.businessInfo) {
      values.businessInfo.forEach((file, index) => {
        formData.append("businessInfo", file.originFileObj);
      });
    }
    if (values.profilePic && values.profilePic.length > 0) {
      formData.append("profilePic", values.profilePic[0].originFileObj);
    }
    console.log(formData, "<=====formData");
    const userId = param?.id;
    dispatch(EditAdminUser({ userId, formData }));
  };
  const handlePitchDeckChange = ({ fileList }) => {
    // Allow only one video to be selected
    const newFileList = fileList.slice(-1);

    form.setFieldsValue({ pitchDeck: newFileList });
    setDefaultPitchDeck(newFileList);
  };
  const handlePreview = (file) => {
    if (file.url && !file.preview) {
      file.preview = file.url;
    }

    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }
  };

  useEffect(() => {
    if (data) {
      const investorGroupIds =
        data?.userBusinessInfoData?.userInvestorGroups.map(
          (group) => group.InvestorGroupId
        );
      const partnersInfo = data?.userBusinessInfoData?.partnersInfo.map(
        (group) => group.nameOfPartners
      );

      const bussinessInfo =
        data?.userBusinessInfoData?.UserBusinessFileData.map((group) => group);

      form.setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        LinkedInName: data?.LinkedInName,
        // cityId: data?.userInfoModelData?.userCity?.name,
        cityId: {
          label: data?.userInfo?.userCity?.name,
          value: data?.userInfo?.cityId,
        },

        country: data?.userInfo?.userCity?.StateData?.CountryDetail?.name,
        address: data?.userInfo?.address,
        websiteLink: data?.userBusinessInfoData?.websiteLink,
        pnum: data?.userInfo?.pnum,
        businessName: data?.userBusinessInfoData?.businessName,
        Round: data?.userBusinessInfoData?.Round,
        InvestmentRaised: data?.userBusinessInfoData?.InvestmentRaised,
        noOfEmployee: data?.userBusinessInfoData?.noOfEmployee,
        profilePic: data?.profile_pic,

        investorGroupIds: investorGroupIds,
        story: data?.userStoryData?.Video || "",
        pitchDeck: data?.userPitchDeckData?.file || "",
        bussinessInfo,
      });
      if (data?.userStoryData?.Video) {
        setDefaultVideoFile([
          {
            uid: "-1",
            name: "video.mp4",
            status: "done",
            url: data.userStoryData.Video,
          },
        ]);
      }

      if (data?.userPitchDeckData?.file) {
        setDefaultPitchDeck([
          {
            uid: "-1",
            name: data.userPitchDeckData?.file,
            status: "done",
            url: data.userPitchDeckData?.file,
          },
        ]);
      }
      if (data?.profile_pic) {
        setDefaultProfilePic([
          {
            uid: "-1",
            name: data?.profile_pic,
            status: "done",
            url: data?.profile_pic,
          },
        ]);
      }
      setUserBusinessFileData(
        data?.userBusinessInfoData?.UserBusinessFileData || []
      );
      setTags(partnersInfo);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate(-1);
      dispatch(clearMessage());
    }
  }, [error, message]);
  useEffect(() => {
    dispatch(GetInvestorGroup());
    dispatch(GetCountry());
    const userId = param?.id;
    dispatch(SingleAdminUser({ userId }));
  }, [dispatch]);


  return (
    <Fragment>
      <div className="container-fluid mainedit mainedit1">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          initialValues={data}
        >
          <div className="row editform">
            <div className="col-sm-8">
              <h3>Personal Information</h3>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "Please input First Name" },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item name={"email"} rules={[{ type: "email" }]}>
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="LinkedInName"
                    rules={[{ required: true, message: "Please input Link" }]}
                  >
                    <Input placeholder="Linked URL" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Country!",
                      },
                    ]}
                  >
                    {/* <Input placeholder="Country" /> */}
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Country"
                      onChange={(e) => handleChange(e)}
                    >
                      {countryData?.map((e) => (
                        <Option value={e._id}>{e.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="cityId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="City"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => handleChange(e)}
                      labelInValue // Add this prop to get the label in value
                    >
                      {cityData?.map((city) => (
                        <Option key={city._id} value={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="address"
                    rules={[
                      { required: true, message: "Please input Address" },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="websiteLink"
                    rules={[
                      { required: true, message: "Please input Website" },
                    ]}
                  >
                    <Input placeholder="Website" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="pnum"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone Number!",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers.",
                      },
                    ]}
                  >
                    <Input placeholder="Phone Number " />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="businessName"
                    rules={[
                      { required: true, message: "Please input Business Name" },
                    ]}
                  >
                    <Input placeholder="Business Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="noOfEmployee"
                    rules={[
                      {
                        required: true,
                        message: "Please input Number Of Employees",
                      },
                    ]}
                  >
                    <Input placeholder="Number of Employees" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="InvestmentRaised"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Investment Amount",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers.",
                      },
                    ]}
                  >
                    <Input placeholder="Investment Amount " />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <div className="slectedit">
                    <Form.Item
                      name="investorGroupIds"
                      rules={[
                        {
                          required: true,
                          message: "Please Choose Investor Group",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Interest Group"
                        mode="multiple"
                        //                     defaultValue={data.userBusinessInfoData
                        // ?.userInvestorGroups?.map(
                        //                       (group) => group._id
                        //                     )}
                      >
                        {interestGroup?.map((option, index) => (
                          <Option key={option._id || index} value={option._id}>
                            {option.groupName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-sm-6 partnerNumbher">
                  <Form.Item name="NoOfPartners">
                    <Input.Search
                      // value={inputValue}
                      placeholder="Name Of Partners"
                      onChange={handleInputChange}
                      onSearch={handleInputSearch}
                      enterButton="Add Partners"
                    />
                  </Form.Item>

                  <Form.Item>
                    {tags?.map((tag) => (
                      <Tag
                        key={tag}
                        closable
                        onClose={() => handleTagClose(tag)}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <div className="slectedit">
                    <Form.Item
                      name="Round"
                      rules={[
                        {
                          required: true,
                          message: "Please Chose Investor Group",
                        },
                      ]}
                    >
                      <Select placeholder="Current Stage Of Investment">
                        {roundOption.map((option, index) => (
                          <Option
                            key={option.value || index}
                            value={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {/* 
                <div className="col-sm-6">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      prefix={<Image src={Password} preview={false} />}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="confirmpassword"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your Password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords do not match"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      prefix={<Image src={Password} preview={false} />}
                    />
                  </Form.Item>
                </div> */}
              </div>
            </div>

            <div className="col-sm-4 fileSection">
              {/* <h3>Business Information</h3> */}

              <Form.Item name="story">
                <Upload
                  name="logo"
                  beforeUpload={() => false}
                  action="/upload.do"
                  listType="picture"
                  accept="video/*"
                  fileList={defaultVideoFile}
                  onChange={(info) => handleUserStoryChange(info)}
                  onPreview={(file) => handlePreview(file)}
                >
                  <Button icon={<UploadOutlined />}>Upload Video</Button>
                </Upload>
              </Form.Item>
              <Form.Item name="pitchDeck" getValueFromEvent={normFile}>
                <Upload
                  name="logo"
                  beforeUpload={() => false}
                  action="/upload.do"
                  listType="picture"
                  fileList={defaultPitchDeck}
                  onChange={(info) => handlePitchDeckChange(info)}
                  onPreview={(file) => handlePreview(file)}
                >
                  <Button icon={<UploadOutlined />}>Pitch Deck</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name="profilePic"
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Please Select Profile Picture",
                  },
                ]}
              >
                <Upload
                  name="logo"
                  beforeUpload={() => false}
                  action="/upload.do"
                  listType="picture"
                  accept="image/*"
                  fileList={defaultProfilePic}
                  onChange={(info) => handleUserProfileChange(info)}
                  onPreview={(file) => handlePreview(file)}
                >
                  <Button icon={<UploadOutlined />}>Profile Picture</Button>
                </Upload>
              </Form.Item>
              {/* <a className="pdffUrl" onClick={openPdfInNewTab}>
                Open PDF in New Tab
              </a> */}
              <Form.Item
                name="businessInfo"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  {...uploadProps}
                  multiple
                  accept={allowedFileTypes.join(",")}
                >
                  <Button icon={<UploadOutlined />}>
                    Attach Financial Stuff
                  </Button>
                </Upload>
              </Form.Item>
              {data?.userBusinessInfoData?.UserBusinessFileData?.map((item) => (
                <>
                  {/* <button>Remove</button> */}
                  <a href={item.file} target="_blank">
                    <img src={PDF1} alt="asd" />
                  </a>
                  <span
                    className="crossIcon"
                    // onClick={() => handleFileDelete(item._id)}
                    onClick={() => handleModal(item._id)}
                  >
                    <CloseOutlined />
                  </span>{" "}
                </>
              ))}
            </div>

            <div className="col-sm-12">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {loading ? <Loader /> : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <DeleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleDelete={handleFileDelete}
        id={id}
      />
    </Fragment>
  );
}

export default AdminEditsUser;
