import React, { Fragment, useEffect, useState } from "react";
import Round from "../../../assets/round.png";
import Calender from "../../../assets/calendar.png";
import { Form, Input, Button, message, DatePicker } from "antd";
import { Calllist } from "../../../Data/Data";
import Dropdown from "react-bootstrap/Dropdown";
import Filters from "../../../assets/filter.png";
import Sorts from "../../../assets/sort.png";
import { ScheduleCallsInvestors } from "../../../Redux/Actions/GetInvestorCalls";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Paginations from "../../Pagination";

const { TextArea } = Input;

function ScheduleCard() {
  const navigate = useNavigate();
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const [cardStates, setCardStates] = useState({});
  const [clickedCardId, setClickedCardId] = useState(null);

  // Get Call Api
  const { data } = useSelector((state) => state.schedulecallsinvestor);
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(ScheduleCallsInvestors({page, size}));
  }, [Dispatch]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  // End Api

  const status = "completed";

  const handleNavigate = (id) => {
    console.log(id, "dsa");
    navigate(`/investor/reschedule-requests/${id}`);
  };

  const handleNavigateFeedBack = (id) => {
    navigate(`/investor/share-feedbacks/${id}`);
  };

  const onFinish = (values, cardKey) => {
    console.log("Form values:", values);
    // Handle form submission logic here

    // Show success message
    message.success("Form submitted successfully.");

    // Reset the form and update state
    setCardStates((prevStates) => ({
      ...prevStates,
      [cardKey]: {
        showForm: false,
        formSubmitted: true,
      },
    }));
  };

  const toggleForm = (cardKey) => {
    console.log(cardKey, "cardkey");
    console.log(cardStates, "cardstates");
    setClickedCardId(cardKey); // Set the clicked card ID
    setCardStates((prevStates) => ({
      ...prevStates,
      [cardKey]: {
        showForm: !prevStates[cardKey]?.showForm,
        formSubmitted: false,
      },
    }));
  };
  console.log(data, "datass");
  return (
    <Fragment>
      <div className="container-fluid callscontainer clContainerInvestor">
        <div className="row fltersrow">
          <div className="col-sm-2 filter">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Filters} alt="asd" /> Filter
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-sm-2 sortion">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Sorts} alt="asd" /> Sort By Date
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="row schedulerow">
          {data.map((item, i) => {
            const cardKey = item._id;
            const cardState = cardStates[cardKey] || {
              showForm: false,
              formSubmitted: false,
            };
            const isScheduledNow = moment().isSame(
              moment(item.ScheduledDate),
              "second"
            );

            const isScheduledPassed = moment(item.SceduledDate).isBefore(
              moment()
            );

            // const scheduledDate = new Date(item.ScheduledDate);
            // const currentDate = new Date();
            // const isDatePassed = scheduledDate.getTime() > currentDate.getTime();

            return (
              <div className="col-sm-3 schedulecalls" key={i}>
                <div className="myschedules">
                  <h2 className="names">
                    {
                      item?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                        ?.firstName
                    }{" "}
                    {
                      item?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                        ?.lastName
                    }
                  </h2>
                  <h3>
                    <img src={Round} alt="asd" />
                    Round
                    {
                      item?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                        ?.userBusinessInfoData?.Round
                    }
                  </h3>
                  <p className="mybusinesnamess">
                    <b>Business Name</b>:{" "}
                    {
                      item?.sceduleDatesDetails?.callIdDetails?.userCallDetails
                        ?.userBusinessInfoData?.businessName
                    }
                  </p>

                  {item?.meetingLink && (
                    <a
                      href={item?.meetingLink}
                      target="_blank"
                      className="webLinkButton"
                    >
                      Join Meeting Link
                    </a>
                  )}

                  <div className="row dats">
                    <div className="col-sm-8">
                      <h3>
                        <img src={Calender} alt="asd" />{" "}
                        {moment(item.SceduledDate).format("DD MMM - h:mm a")}
                      </h3>
                    </div>

                    <div className="col-sm-4">
                      <p
                        className={isScheduledPassed == false ? "red" : "green"}
                      >
                        {/* {item.meetingStatus == false ? "Pending" : "Completed"} */}
                        {isScheduledPassed ? "Completed" : "Pending"}
                      </p>
                    </div>
                  </div>

                  {clickedCardId === cardKey ? (
                    <>
                      {cardState.showForm ? (
                        <Form
                          name={`your-form-${cardKey}`}
                          onFinish={(values) => onFinish(values, cardKey)}
                        >
                          <Form.Item
                            name="message"
                            rules={[
                              {
                                required: true,
                                message: "Please write a reason first",
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              placeholder="Type your reason for reschedule"
                            />
                          </Form.Item>

                          <Form.Item>
                            <div className="row leftSecDateSelect rescheduleDates">
                              <div className="col-sm-12 ">
                                <div>
                                  <Form.Item name="date1" {...config}>
                                    <DatePicker
                                      showTime
                                      format="YYYY-MM-DD HH:mm:ss"
                                      // disabledDate={disabledDate}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="col-sm-12 ">
                                <div>
                                  <Form.Item name="date2" {...config}>
                                    <DatePicker
                                      showTime
                                      format="YYYY-MM-DD HH:mm:ss"
                                      // disabledDate={disabledDate}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="col-sm-12 ">
                                <div>
                                  <Form.Item name="date3" {...config}>
                                    <DatePicker
                                      showTime
                                      format="YYYY-MM-DD HH:mm:ss"
                                      // disabledDate={disabledDate}
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="col-sm-12 greyDate myheights">
                                <div className="greyBox">
                                  {/* <Form.Item name="CallId" initialValue={data?._id} rules={[{ required: true }]}>
                    <Input type="hidden" />
                  </Form.Item> */}
                                </div>
                              </div>
                            </div>
                            <Button type="primary" htmlType="submit">
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      ) : cardState.formSubmitted ? (
                        <button className="reqsub">Rescheduled</button>
                      ) : (
                        <button
                          className={status === "pending" ? "disable" : ""}
                          disabled={status === "pending"}
                        >
                          {status === "pending" ? "Pending" : "Rescheduled"}
                        </button>
                      )}
                    </>
                  ) : isScheduledPassed ? (
                    item?.sceduleDatesDetails?.status === "completed" ? (
                      <>
                   
                      <button to="#" className="resched" disabled>
                        Feedback is Shared
                      </button>
                      </>
                    ) : (
                           <button
                           to="#"
                           className="resched"
                           onClick={() =>
                             handleNavigateFeedBack(
                               item?.sceduleDatesDetails?.CallId
                             )
                           }
                         >
                           Share Feedback
                         </button>
                    )
                  ) : (
                    <button
                      to="#"
                      className="resched"
                      onClick={() =>
                        handleNavigate(item?.sceduleDatesDetails?.CallId)
                      }
                    >
                      Reschedule
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Paginations
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
      </div>
    </Fragment>
  );
}

export default ScheduleCard;
