import { Fragment, useEffect } from "react";
import { Button, Form, Input, Upload, Spin, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearError, clearMessage } from "../../../Redux/Reducers/InvestorSignupReducer";
import { InvestorSignups } from "../../../Redux/Actions/PostInvestorSignUp";
import { LoadingOutlined } from "@ant-design/icons";
import { GetCountry } from "../../../Redux/Actions/GetCountry";
import { GetCity } from "../../../Redux/Actions/GetCity";

function SignUp() {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { message, error, loading } = useSelector(
    (state) => state.investorsignup
  );
const {Option } = Select

  const { data } = useSelector((state) => state.country);
  const cityData = useSelector((state) => state.city.data);
  const onFinish = (values) => {
    const formData = new FormData();
    // formData.append("RoleId", roleid);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("pnum", values.pnum);
    formData.append("cityId", values.cityId);
    // formData.append("country", values.country);
    formData.append("password", values.password);
    if (values.image && values.image[0]?.originFileObj) {
      formData.append("image", values.image[0]?.originFileObj);
    }

    Dispatch(InvestorSignups(formData));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (e) => {
    Dispatch(GetCity(e));
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      Dispatch(clearMessage());
      navigate("/investor/login");
    }
    if (error) {
      toast.error(error);
      Dispatch(clearError());
    }
  }, [Dispatch, message, error, navigate]);

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  useEffect(() => {
    Dispatch(GetCountry());
  }, [Dispatch]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 signupform">
          <h2>Signup </h2>
          <p>Welcome back! please enter your detail</p>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-sm-6">
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item name="email" rules={[{ type: "email" }]}>
                  <Input placeholder="E-mail" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="pnum"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]+$/,
                      message:
                        "Please enter a valid phone number with no alphabets",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your Country!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Country"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleChange(e)}
                  >
                    {data?.map((e) => (
                      <Option key={e._id} value={e._id}>
                        {e.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="cityId"
                  rules={[
                    {
                      required: true,
                      message: "Please input your City!",
                    },
                  ]}
                >
                  <Select placeholder="City"
                   showSearch
                   filterOption={(input, option) =>
                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   }
                    onChange={(e) => handleChange(e)}>
                    {cityData?.map((e) => (
                      <Option value={e._id}>{e.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="confirmpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("The two passwords do not match");
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </div>

              <div className="col-sm-12 profile">
                <Form.Item>
                  <Form.Item
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        validator: (_, value) => {
                          // Check if the uploaded file is a valid image (JPG or PNG)
                          if (value) {
                            const isValidFileType =
                              value[0].type === "image/jpeg" ||
                              value[0].type === "image/png";
                            if (!isValidFileType) {
                              return Promise.reject(
                                "Please upload a valid image file (JPG or PNG)."
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      name="image"
                      listType="picture"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload Profile Picture
                      </Button>
                    </Upload>
                  </Form.Item>
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {loading ? (
                      <Spin indicator={antIcon} style={{ color: "#ffff" }} />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>

                  <p className="ques">
                    Already have an account{" "}
                    <Link to="/investor/login">Sign In</Link>
                  </p>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default SignUp