import React, { Fragment } from 'react'
import LeftSec from './LeftSec'
import RightSec from './RightSec'
import { useSelector } from "react-redux";

function ProfileView() {
  const { data } = useSelector((state) => state.userProfile);


  return (
    <Fragment>
      <div className="container-fluid heights">
        <div className="row ">
          <div className="col-sm-12 maincol">
            <div className="row">
              <div className="col-sm-3 userProfileee">
                <LeftSec data={data} />
              </div>

              <div className="col-sm-9 userProfileee">
                <RightSec data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProfileView