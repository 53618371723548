import React, { Fragment, useEffect } from "react";
import LeftProf from "./LeftProf";
import RightProf from "./RightProf";
import { useDispatch, useSelector } from "react-redux";
import { GetInvestorProfile } from "../../../Redux/Actions/GetInvProfile";

function ProfOverview() {
  const { data } = useSelector((state) => state.getinvestorprofile);


  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetInvestorProfile());
  }, [Dispatch]);


  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row heights">
          <div className="col-sm-12 maincol">
            <div className="row">
              
              <div className="col-sm-3 userProfileee">
                <LeftProf data={data}/>
              </div>

              <div className="col-sm-9 userProfileee">
                <RightProf  data={data}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProfOverview;
