import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeStatusUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    changeStatusUserSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      state.error = null;
    },
    changeStatusUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    postUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    postUserSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      state.error = null;
    },
    postUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    singleUserStart(state) {
      state.loading = true;
      state.error = null;
    },

    singleUserSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    singleUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    userStart(state) {
      state.loading = true;
      state.error = null;
    },

    userSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    userFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteUserStart(state) {
      state.loading = true;
      state.error = null;
    },

    deleteUserSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      console.log(action.payload, "<====payload");
    },

    deleteUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    editUserStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editUserSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
    },

    editUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    adminSingleUserStart(state) {
      state.loading = true;
      state.error = null;
    },

    adminSingleUserSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    adminSingleUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    adminEditUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    adminEditUserSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      state.error = null;
    },
    adminEditUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  postUserStart,
  postUserSuccess,
  postUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  userStart,
  userSuccess,
  userFailure,
  editUserStart,
  editUserSuccess,
  editUserFailure,
  changeStatusUserStart,
  changeStatusUserSuccess,
  changeStatusUserFailure,
  singleUserStart,
  singleUserSuccess,
  singleUserFailure,
  adminSingleUserStart,
  adminSingleUserSuccess,
  adminSingleUserFailure,
  adminEditUserStart,
  adminEditUserSuccess,
  adminEditUserFailure,
  clearError,
  clearMessage,
} = userSlice.actions;

export default userSlice.reducer;
