import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Image, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Password from "../../../assets/pass.png";
import { useDispatch, useSelector } from "react-redux";
import { GetInvestorGroup } from "../../../Redux/Actions/GetInvestorGroup";
import { GetCountry } from "../../../Redux/Actions/GetCountry";
import { GetSingleInvestor } from "../../../Redux/Actions/GetInvestor";
import { GetCity } from "../../../Redux/Actions/GetCity";
import { EditInvestorProfile } from "../../../Redux/Actions/EditInvestorProfile";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/EditInvestorReducer";
import {
  EditInvestor,
  singleInvestor,
} from "../../../Redux/Actions/EditInvestor";

function AdminInvestorEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const [form] = Form.useForm();
  const [defaultVideoFile, setDefaultVideoFile] = useState(null);
  const [defaultProfilePic, setDefaultProfilePic] = useState(null);

  const interestGroup = useSelector((state) => state.getinvestorgroup.data);
  const countryData = useSelector((state) => state.country.data);
  const cityData = useSelector((state) => state.city.data);
  const { data } = useSelector((state) => state.editInvestorAdmin);
  const { loading, message, error } = useSelector(
    (state) => state.editInvestorAdmin
  );
  console.log(loading, "<=====loading");

  // email and number validation
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // for upload
  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const { Option } = Select;

  // onfinfsh values

  const handleInvestorProfileChange = ({ fileList }) => {
    // Allow only one video to be selected
    const newFileList = fileList.slice(-1);

    form.setFieldsValue({ profilePic: newFileList });
    setDefaultProfilePic(newFileList);
  };

  const handleInvestorStoryChange = ({ fileList }) => {
    // Allow only one video to be selected
    const newFileList = fileList.slice(-1);

    form.setFieldsValue({ Video: newFileList });
    setDefaultVideoFile(newFileList);
  };
  const handlePreview = (file) => {
    if (file.url && !file.preview) {
      file.preview = file.url;
    }

    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }
  };

  const onFinish = (values) => {
    const formData = new FormData();

    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);

    // formData.append("LinkedInName", values.LinkedInName);
    formData.append("Address", values.Address);
    formData.append("cityId", values.cityId.value);
    formData.append("pnum", values.pnum);
    formData.append("bio", values.bio);
    formData.append("websiteLink", values.websiteLink);
    formData.append("noCompaniesInvestedIn", values.noCompaniesInvestedIn);
    for (let i = 0; i < values.investorGroupIds.length; i++) {
      formData.append("investorGroupIds", values.investorGroupIds[i]);
    }
    if (values.Video && values.Video.length > 0) {
      formData.append("Video", values.Video[0].originFileObj);
    }
    if (values.profilePic && values.profilePic.length > 0) {
      formData.append("profilePic", values.profilePic[0].originFileObj);
    }
    const id = param.id;
    dispatch(EditInvestor({ id, formData }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (e) => {
    dispatch(GetCity(e));
  };
  useEffect(() => {
    if (data) {
      const investorGroupIds =
        data?.investorRelationToBusinessInfo?.investorBusinessInfoData.map(
          (group) => group.InvestorGroupId
        );

      form.setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        Address: data?.investorRelationToBusinessInfo?.Address,

        cityId: {
          label: data?.invesotrinfo?.investorCity?.name,
          value: data?.invesotrinfo?.cityId,
        },
        country:
          data?.invesotrinfo?.investorCity?.StateData?.CountryDetail?.name,
        pnum: data?.invesotrinfo?.pnum,
        noCompaniesInvestedIn:
          data?.investorRelationToBusinessInfo?.noCompaniesInvestedIn,
        bio: data?.investorRelationToBusinessInfo?.bio,
        websiteLink: data?.investorRelationToBusinessInfo?.websiteLink,
        investorGroupIds: investorGroupIds,
        Video: data?.investorRelationToBusinessInfo?.Video || "",
        profilePic: data?.image || "",
      });
      if (data?.investorRelationToBusinessInfo?.Video) {
        setDefaultVideoFile([
          {
            uid: "-1",
            name: "video.mp4",
            status: "done",
            url: data.investorRelationToBusinessInfo?.Video,
          },
        ]);
      }
      if (data?.image) {
        setDefaultProfilePic([
          {
            uid: "-1",
            name: data?.image,
            status: "done",
            url: data?.image,
          },
        ]);
      }
    }
  }, [data]);
  // console.log(data, "data,");
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate(-1);
      dispatch(clearMessage());
    }
  }, [error, message]);
  useEffect(() => {
    dispatch(GetInvestorGroup());
    dispatch(GetCountry());
    const id = param.id;
    dispatch(singleInvestor(id));
  }, [dispatch]);
  return (
    <Fragment>
      <div className="container-fluid mainedit investorForm investorEditProfileForm">
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="row editform">
            <div className="col-sm-12 activate">
              <button>Activate</button>
            </div>

            <div className="col-sm-8">
              <h3>Personal Information</h3>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "Please input First Name" },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input Email",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="Address"
                    rules={[
                      { required: true, message: "Please add your Address " },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Country!",
                      },
                    ]}
                  >
                    {/* <Input placeholder="Country" /> */}
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Country"
                      onChange={(e) => handleChange(e)}
                    >
                      {countryData?.map((e) => (
                        <Option value={e._id}>{e.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="cityId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="City"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => handleChange(e)}
                      labelInValue // Add this prop to get the label in value
                    >
                      {cityData?.map((city) => (
                        <Option key={city._id} value={city._id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="bio"
                    rules={[{ required: true, message: "Please Add Bio" }]}
                  >
                    <Input placeholder="Bio" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="websiteLink"
                    rules={[
                      { required: true, message: "Please input Website" },
                    ]}
                  >
                    <Input placeholder="Website" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="pnum"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone Number!",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers.",
                      },
                    ]}
                  >
                    <Input placeholder="Phone Number " />
                  </Form.Item>
                </div>

                {/* <div className="col-sm-6">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      prefix={<Image src={Password} preview={false} />}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="confirmpassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Confirm password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      prefix={<Image src={Password} preview={false} />}
                    />
                  </Form.Item>
                </div> */}
              </div>
            </div>

            <div className="col-sm-4">
              <h3>Business Information</h3>
              {/* <Form.Item
                name="businessname"
                rules={[
                  { required: true, message: "Please input Business Name" },
                ]}
              >
                <Input placeholder="Business Name" />
              </Form.Item> */}

              <div className="slectedit investor">
                <Form.Item
                  name="investorGroupIds"
                  rules={[
                    {
                      required: true,
                      message: "Please Choose Investor Group",
                    },
                  ]}
                >
                  <Select
                    placeholder="Interest Group"
                    mode="multiple"
                    //                     defaultValue={data.userBusinessInfoData
                    // ?.userInvestorGroups?.map(
                    //                       (group) => group._id
                    //                     )}
                  >
                    {interestGroup?.map((option, index) => (
                      <Option key={option._id || index} value={option._id}>
                        {option.groupName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                name="noCompaniesInvestedIn"
                rules={[
                  {
                    required: true,
                    message: "Please input name of associate partners",
                  },
                ]}
              >
                <Input placeholder="No of Company Invested In" />
              </Form.Item>

              <Form.Item
                name="profilePic"
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Please Select Profile Picture",
                  },
                ]}
              >
                <Upload
                  name="logo"
                  beforeUpload={() => false}
                  action="/upload.do"
                  listType="picture"
                  accept="image/*"
                  fileList={defaultProfilePic}
                  onChange={(info) => handleInvestorProfileChange(info)}
                  onPreview={(file) => handlePreview(file)}
                >
                  <Button icon={<UploadOutlined />}>Profile Picture</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="Video"
                rules={[
                  {
                    required: true,
                    message: "Please Select Video",
                  },
                ]}
              >
                <Upload
                  name="logo"
                  beforeUpload={() => false}
                  action="/upload.do"
                  listType="picture"
                  accept="video/*"
                  fileList={defaultVideoFile}
                  onChange={(info) => handleInvestorStoryChange(info)}
                  onPreview={(file) => handlePreview(file)}
                >
                  <Button icon={<UploadOutlined />}>Upload Video</Button>
                </Upload>
              </Form.Item>
            </div>

            <div className="col-sm-12 adminSaveee">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {loading ? <Loader /> : "Submit"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
}

export default AdminInvestorEdit;
