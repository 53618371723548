import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  investorDetailStart,
  investorDetailSuccess,
  investorDetailFailure,
} from "../Reducers/InvestorUserDetail";
import axios from "axios";

export const InvestorUserDetail = createAsyncThunk(
  "investordetail",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(investorDetailStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/InvestorProfileByID/${id}`
      );

      thunkAPI.dispatch(investorDetailSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(investorDetailFailure(error.response.data.message));
    }
  }
);
