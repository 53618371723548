import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/PackageReducer";
import { toast } from "react-toastify";
import { EditPackage } from "../../../Redux/Actions/Package";

// const { TextArea } = Input;

function AdminEditSubscriptionPackage() {
  const { error, message, loading } = useSelector((state) => state.packages);

  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const { data } = state;
  const [form] = Form.useForm();

  const navigate = useNavigate();
  
  const onFinish = (values) => {
    const id = data._id;
    dispatch(EditPackage({ id, values }));
    // navigate("/admin/subscription-packages");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/admin/subscription-packages");
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        PackageName: data?.PackageName,
        description: data?.description,
        price: data?.price,
        noOfDays: data?.noOfDays,
        reachLimit: data?.reachLimit,
        discount: data?.discount,
      });
    }
  }, [data]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminAddSubscriptionPackage ">
        <div className="adminSubsPackageButton">
          <Link to="/admin/add-subscription-package">
            <Button>Add Subscription Package</Button>
          </Link>
        </div>
        <div className="adminAddSubscriptionPackageData">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              name="PackageName"
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              name="description"
              rules={[{ required: true, message: "Please enter description!" }]}
            >
              <Input.TextArea placeholder="Description" rows={5} />
            </Form.Item>

            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please enter price!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="Price" />
            </Form.Item>

            <Form.Item
              name="noOfDays"
              rules={[
                {
                  required: true,
                  message: "Please enter No. of days!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="No. of Days" />
            </Form.Item>

            <Form.Item
              name="reachLimit"
              rules={[
                {
                  required: true,
                  message: "Please enter No. Of Investors!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="No. Of Investors" />
            </Form.Item>

            <Form.Item
              name="discount"
              rules={[
                {
                  required: true,
                  message: "Please enter discount!",
                },
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter only numbers.",
                },
              ]}
            >
              <Input placeholder="Discount in %" />
            </Form.Item>

            <Form.Item className="adminAddSubscriptionPackageButton">
              {/* <Link to='/admin/subscription-packages'> */}
              <Button htmlType="submit">Save</Button>
              {/* </Link> */}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminEditSubscriptionPackage;
