import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  userBuyPackageStart,
  userBuyPackageSuccess,
  userBuyPackageFailure,
  userSinglePackageStart,
  userSinglePackageSuccess,
  userSinglePackageFailure,
} from "../Reducers/UserBuyPackageReducer";
import axios from "axios";

export const UserBuyPackage = createAsyncThunk(
  "userbuypackage",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(userBuyPackageStart());
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/buySubscription`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(userBuyPackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(userBuyPackageFailure(error.response.data.message));
    }
  }
);

export const userSinglePackage = createAsyncThunk(
  "userbuypackage",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(userSinglePackageStart());
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/singlePackage/${id}`,

        { headers: header }
      );

      thunkAPI.dispatch(userSinglePackageSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(userSinglePackageFailure(error.response.data.message));
    }
  }
);
