import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCityStart,
  getCitySuccess,
  getCityFailure,
} from "../Reducers/GetCityReducer";
import axios from "axios";

export const GetCity = createAsyncThunk("getcity", async (id, thunkAPI) => {
  try {
    thunkAPI.dispatch(getCityStart());

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}city/CityWithRespectToCountry/${id}?size=10000`
    );

    thunkAPI.dispatch(getCitySuccess(response.data));
  } catch (error) {
    thunkAPI.dispatch(getCityFailure(error.response.data.message));
  }
});
