import React from "react";
import AdminSidebar from "../../Componenets/Admin/Sidebar/AdminSidebar";
import AdminHeader from "../../Componenets/Admin/Header/AdminHeader";
import NewsLetterListing from "../../Componenets/Admin/NewsLetters/NewsLetterListing";

function NewsLetterList() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <AdminSidebar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <AdminHeader />
          <NewsLetterListing />
        </div>
      </div>
    </div>
  );
}

export default NewsLetterList;
