import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  bussinessLeadsFailure,
  bussinessLeadsStart,
  bussinessLeadsSuccess,
  deletebussinessLeadsFailure,
  deletebussinessLeadsStart,
  deletebussinessLeadsSuccess,
} from "../Reducers/BussinessLeadsReducer";

export const GetBussinessLeads = createAsyncThunk(
  "bussinessleads",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(bussinessLeadsStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/contactLead`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(bussinessLeadsSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(bussinessLeadsFailure(error.response.data.message));
    }
  }
);

export const DeleteBussinessLeads = createAsyncThunk(
  "bussinessleads",
  async ({ id }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer  ${adminAuth}`,
      };
      thunkAPI.dispatch(deletebussinessLeadsStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/contactLead/${id}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(deletebussinessLeadsSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        deletebussinessLeadsFailure(error.response.data.message)
      );
    }
  }
);
