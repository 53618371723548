import React, { Fragment } from "react";
import VideoPlayer from "./VideoPlayer";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

function RightProf({data}) {

  if (!data) {
    return <div>Data is not available.</div>;
  }

  // const videoId = data.investorRelationToBusinessInfo?.Video;

  // const videoId = "https://youpitchassets.s3.amazonaws.com/InvestorBussiness/d0fd63a7406d43d498c992535915d7ffe3c2443b91eac88e31da42c989966167.m3u8";

  return (
    <Fragment>
      <div className="row">
        <div className="profDetailsTitle">
          <h2>
            {data.firstName} {data.lastName}{" "}
          </h2>
        </div>

        <div className="row investorWeb">
          <div className="col-sm-12">
            <p> {data.investorRelationToBusinessInfo?.bio} </p>
          </div>
        </div>

        <div className="row bottombox bottombox1">
          <div className="col-sm-12 textSection">
            <div className="row LeftSecDDateSelect profiles leftProfColSMSix">
              <div className="col-sm-6">
                <p>
                  Interest Groups
                  <div className="row interestUser">
                    <Swiper
                      pagination={{
                        type: "fraction",
                      }}
                      navigation={true}
                      modules={[Autoplay, Pagination]}
                      autoplay={{
                        delay: 20000000,
                        disableOnInteraction: false,
                      }}
                      className="mySwiper"
                    >
                      {data?.investorRelationToBusinessInfo?.investorBusinessInfoData?.map(
                        (item) => (
                          <SwiperSlide>
                            <div className="col-sm-12">
                              {" "}
                              <p className="pointss">
                                {item?.investorInvestorGroupName?.groupName}
                              </p>
                            </div>
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  E-mail: <span>{data.email}</span>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  No of Companies Invested:{" "}
                  <span>
                    {data.investorRelationToBusinessInfo?.noCompaniesInvestedIn}{" "}
                  </span>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  Website:{" "}
                  <span>
                    {data.investorRelationToBusinessInfo?.websiteLink}
                  </span>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  Address:{" "}
                  <span>{data.investorRelationToBusinessInfo?.Address}</span>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  Phone Number: <span>{data.invesotrinfo?.pnum}</span>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  City: <span>{data.invesotrinfo?.investorCity?.name}</span>
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  No. of Companies Associated With:{" "}
                  <span>
                    {
                      data?.investorRelationToBusinessInfo
                        ?.noCompaniesInvestedIn
                    }
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RightProf;
