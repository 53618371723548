import React from 'react'
import LoginSlide from '../../Componenets/User/Login/LoginSlide'
import PitchDekForm from '../../Componenets/User/Pitchdeck/PitchDekForm'
import GroupForm from '../../Componenets/User/InvestorGroup/GroupForm'

function InvestorGroup() {
    return (
        <div className='container-fluid userlogin'>
            <div className='row'>
                <div className='col-sm-6 leftslideuser'>
                    <LoginSlide />
                </div>

                <div className='col-sm-6 rightloginform'>
                    <GroupForm />
                </div>
            </div>
        </div>
    )
}

export default InvestorGroup