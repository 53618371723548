import React from "react";
import Logo from "../../assets/Logo.png";
import { Link } from "react-router-dom";

const NotFoundHeader = () => {
  return (
    <div className="col-sm-12">
      <header>
        <Link to="/">
          <img src={Logo} alt="" />
        </Link>
      </header>
    </div>
  );
};

export default NotFoundHeader;
