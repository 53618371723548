export const  Packagelist = [
    {
        key:"1",
        title:"gold",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        days:"10",
        contacts:"10 Investors",
        cost:"50",
        sale:"10",
    },

    {
        key:"2",
        title:"platanium",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        days:"20",
        contacts:"10 Investors",
        cost:"50",
        sale:"10",
    },

    {
        key:"3",
        title:"silver",
        desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        days:"30",
        contacts:"10 Investors",
        cost:"50",
        sale:"10",
    },

]


export const CallRequestList = [
  {
    key: "1",
    title: "Mr. John Doe",
    cost: "1000$",
    round: "Round 1",
    webUrl: "www.innoenhance.com",
    businessName: "Innoenhance",
    status: "Pending",
    location: "Ontorio Canada",
    image: "../../images/johnDoe.png",
  },

  {
    key: "2",
    title: "Mr. John Doe",
    cost: "1000$",
    round: "Round 1",
    webUrl: "www.innoenhance.com",
    businessName: "Innoenhance",
    status: "Pending",
    location: "Ontorio Canada",
    image: "../../images/johnDoe.png",
  },

  {
    key: "3",
    title: "Mr. John Doe",
    cost: "1000$",
    round: "Round 1",
    webUrl: "www.innoenhance.com",
    businessName: "Innoenhance",
    status: "Pending",
    location: "Ontorio Canada",
    image: "../../images/johnDoe.png",
  },
];
export const Calllist = [
    {
        key:"1",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 30 - 04:30 PM",
        status:"pending",
    },
    {
        key:"2",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 01 - 04:30 PM",
        status:"completed",
    },

    {
        key:"3",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 22 - 04:30 PM",
        status:"pending",
    },
    {
        key:"4",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 28 - 04:30 PM",
        status:"pending",
    },
    {
        key:"5",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 16 - 04:30 PM",
        status:"pending",
    },
    {
        key:"6",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 12 - 04:30 PM",
        status:"completed",
    },
    {
        key:"7",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 10 - 04:30 PM",
        status:"completed",
    },
    {
        key:"8",
        name:"Mr. John Doe",
        round:"Round 1",
        business:"Food & Beverages",
        datentime:"Nov 20 - 04:30 PM",
        status:"completed",
    },

]



export const InvestorCalllist = [
  {
    key: "1",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 30 - 04:30 PM",
    status: "pending",
  },
  {
    key: "2",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 01 - 04:30 PM",
    status: "completed",
  },

  {
    key: "3",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 22 - 04:30 PM",
    status: "pending",
  },
  {
    key: "4",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 28 - 04:30 PM",
    status: "pending",
  },
  {
    key: "5",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 16 - 04:30 PM",
    status: "pending",
  },
  {
    key: "6",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 12 - 04:30 PM",
    status: "completed",
  },
  {
    key: "7",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 10 - 04:30 PM",
    status: "completed",
  },
  {
    key: "8",
    name: "Mr. John Doe",
    round: "Round 1",
    business: "Food & Beverages",
    datentime: "Nov 20 - 04:30 PM",
    status: "completed",
  },
];

export const Investorsist =[
    {
        key:"1",
        investorname:"Mr. John Doe",
        username:"Emma Stone",
        investents:"02",
        details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.        ",
        city:"Ontorio, Canada",
        industry:"IT INDUSTRY"
    },

    {
        key:"2",
        investorname:"Mr. Doe",
        username:"Emma Stone",
        investents:"04",
        details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.        ",
        city:"Ontorio, Canada",
        industry:"IT INDUSTRY"
    },

    {
        key:"3",
        investorname:"Mr. John ",
        username:"Emma Stone",
        investents:"05",
        details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.        ",
        city:"Ontorio, Canada",
        industry:"IT INDUSTRY"
    },

    {
        key:"4",
        investorname:"John Doe",
        username:"Emma Stone",
        investents:"06",
        details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.        ",
        city:"Ontorio, Canada",
        industry:"IT INDUSTRY"
    },

    {
        key:"5",
        investorname:"Mr. John Doe",
        username:"Emma Stone",
        investents:"08",
        details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.        ",
        city:"Ontorio, Canada",
        industry:"IT INDUSTRY"
    },
]

export const roundOption = [
  { value: 1, label: 1 },

  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
]; 
