import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const AdminShareMeetingSlice = createSlice({
  name: "sharemeeting",
  initialState,
  reducers: {
    shareMeetingStart(state) {
      state.loading = true;
      state.error = null;
    },

    shareMeetingSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
    },

    shareMeetingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  shareMeetingStart,
  shareMeetingSuccess,
  shareMeetingFailure,
  clearError,
  clearMessage,
} = AdminShareMeetingSlice.actions;

export default AdminShareMeetingSlice.reducer;
