


import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Image, Upload, Select, Tag, Steps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { GetInvestorGroup } from "../../../Redux/Actions/GetInvestorGroup";
import { UserBusinessInfo } from "../../../Redux/Actions/UserBusinessInfo";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/UserBusinessInfoReducer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Loader from "../../Loader";
import { roundOption } from "../../../Data/Data";
import { verify } from "../../../Redux/Actions/verify";
import Cookies from "js-cookie";

function BusinessForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
  ];
  const [form] = Form.useForm();

  const { Option } = Select;
  const { data } = useSelector((state) => state.getinvestorgroup);
  const verifyData = useSelector((state) => state.verify.data);

  const { error, message, loading } = useSelector(
    (state) => state.userBusinessInfo
  );
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const uploadProps = {
    name: "file",
    action: "/upload.do",
    listType: "picture",
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF files!");
      }
      return false; // Returning false allows multiple files to be uploaded
    },
    onChange(info) {
      // ... your existing code
    },
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputSearch = (value) => {
    if (value && !tags.includes(value)) {
      setTags([...tags, value]);
      form.setFieldsValue({ NoOfPartners: "" }); // Reset the specific field
    }
  };

  const handleTagClose = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = async (values) => {
    const formData = new FormData();

    formData.append("businessName", values.businessName);
    formData.append("websiteLink", values.websiteLink);
    formData.append("currentAddress", values.currentAddress);
    formData.append("NoOfPartners", values.NoOfPartners);

    formData.append("InvestmentRaised", values.InvestmentRaised);

    formData.append("Round", values.Round);

    formData.append("noOfEmployee", values.noOfEmployee);

    for (let i = 0; i < tags.length; i++) {
      formData.append("NoOfPartners", tags[i]);
    }

    for (let i = 0; i < values.investorGroupIds.length; i++) {
      formData.append("investorGroupIds", values.investorGroupIds[i]);
    }

    if (values.image) {
      values.image.forEach((file, index) => {
        formData.append("image", file.originFileObj);
      });
    }

  await  dispatch(UserBusinessInfo(formData));

    const token = Cookies.get("token");
    dispatch(verify(token));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatch(GetInvestorGroup());
  }, [dispatch]);
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/user/success");
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message, navigate]);
  useEffect(() => {
    if (verifyData?.user && verifyData.user?.userBusinessInfoDetail) {
      navigate("/user/profile");
    }
  }, [verifyData]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 recordform businessforms userBusinessforms">
          <div className="row">
            <div className="col-sm-12">
              <Steps
                size="small"
                current={2}
                items={[
                  { title: "Story" },
                  { title: "PitchDeck" },
                  { title: "Business Detail" },
                ]}
              />
              <h2>Business Details </h2>
              <p>Welcome back! please enter your detail</p>
            </div>
          </div>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <div className="row">
              <div className="col-sm-12">
                <Form.Item
                  name="businessName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Business Details",
                    },
                  ]}
                >
                  <Input placeholder="Business Name" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="websiteLink"
                  rules={[
                    { required: true, message: "Please input your Website" },
                  ]}
                >
                  <Input placeholder="Website" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="currentAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Current Address",
                    },
                  ]}
                >
                  <Input placeholder="Current Address" />
                </Form.Item>
              </div>
              <div className="col-sm-12">
                <Form.Item
                  name="investorGroupIds"
                  rules={[
                    {
                      required: true,
                      message: "Please Select your Interest Group!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Interest Group"
                    optionFilterProp="children"
                    mode="multiple" // Enable multi-select
                    maxTagCount={2} // Set the maximum number of displayed tags
                  >
                    {data.map((option, index) => (
                      <Option
                        key={option.groupName || index}
                        value={option._id}
                      >
                        {option.groupName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-sm-12">
                <Form.Item
                  name="InvestmentRaised"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Investment!",
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numbers.",
                    },
                  ]}
                >
                  <Input placeholder="Investment Required" />
                </Form.Item>
              </div>
              <div className="col-sm-12">
                <Form.Item
                  name="noOfEmployee"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Number Of Employee",
                    },
                  ]}
                >
                  <Input placeholder="Number Of Employee" />
                </Form.Item>
              </div>
              <div className="col-sm-12">
                <Form.Item
                  name="Round"
                  rules={[
                    {
                      required: true,
                      message: "Please Select your No Of Rounds!",
                    },
                  ]}
                >
                  <Select placeholder="Rounds">
                    {roundOption?.map((option, index) => (
                      <Option key={option.value || index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-sm-12 addNoOfPartnerds">
                <Form.Item name="NoOfPartners">
                  <Input.Search
                    // value={inputValue}
                    placeholder="Name Of Partners"
                    onChange={handleInputChange}
                    onSearch={handleInputSearch}
                    enterButton="Add Partners"
                  />
                </Form.Item>

                <div className="col-sm-12 tafs">
                  <Form.Item>
                    {tags.map((tag) => (
                      <Tag
                        key={tag}
                        closable
                        onClose={() => handleTagClose(tag)}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Form.Item>
                </div>
              </div>
              <div className="col-sm-12 pdfupd">
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    {...uploadProps}
                    multiple
                    accept={allowedFileTypes.join(",")}
                  >
                    <Button icon={<UploadOutlined />}>
                      Attach Financial Stuff
                    </Button>
                  </Upload>
                </Form.Item>

                <div style={{ marginTop: "16px" }}>
                  {numPages && (
                    <Document
                      file="your-pdf-file-url.pdf"
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  )}
                </div>
              </div>

              <div className="col-sm-12">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="recordbtn"
                  >
                    {loading ? <Loader /> : "Submit"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default BusinessForm