import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const ScheduleCallsInvestor = createSlice({
  name: "schedulecallsinvestor",
  initialState,
  reducers: {
    ScheduleCallsInvestorStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null
      console.log("working")
    },

    ScheduleCallsInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data.items;
      state.message = action.payload.data.message;

      console.log(state.data, "popasdasd")
 
    },

    ScheduleCallsInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
    ScheduleCallsInvestorStart,
    ScheduleCallsInvestorSuccess,

  ScheduleCallsInvestorFailure,
  clearError,
  clearMessage,
} = ScheduleCallsInvestor.actions;

export default ScheduleCallsInvestor.reducer;