import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import edit from "../../../assets/Pen Squared.png";
import { Button, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteInvestor,
  GetInvestor,
  changeInvestorStatus,
} from "../../../Redux/Actions/GetInvestor";
import Pagination from "../../Pagination";
import DeleteModal from "../../Modals/DeleteModal";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/GetInvestorReducer";

function AdminInvestor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, message } = useSelector((state) => state.GetInvestor);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const handlePageChange = (page) => {
    setPage(page);
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };
  const handleEdit = (id) => {
    console.log("Edit");
    navigate(`/admin/edit-investor/${id}`);
  };

  const handleDelete = async (id) => {
    await dispatch(DeleteInvestor({ id }));
    // dispatch(Getuser());
    await dispatch(GetInvestor({ page, size }));
  };
  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };

  const onChange = async (InvestorId, checked) => {
    const data = {
      status: checked,
      InvestorId,
    };
    await dispatch(changeInvestorStatus({ data }));
    await dispatch(GetInvestor({ page, size }));
  };

  useEffect(() => {
    dispatch(GetInvestor({ page, size }));
  }, [dispatch, page, size]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [error, message]);
  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="adminSubsPackageButton">
          <Link to="/admin/add-investor">
            <Button>Add Investor</Button>
          </Link>
        </div>
        <div className="mobresponnTable">
          <table className="adminSubsPackageTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow">
                <th className="invAdmNAme">Name</th>
                <th className="invAdmEmail">Email</th>
                <th className="invAdmInv">Investment</th>
                <th className="invAdmInt">Interest Group</th>
                <th className="invAdmStatus">Status</th>
                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow" key={i}>
                  <td className="invAdmNAme">
                    {value?.firstName + " " + value?.lastName}
                  </td>
                  <td className="invAdmEmail">{value?.email}</td>
                  <td className="invAdmInv">
                    {
                      value?.investorRelationToBusinessInfo
                        ?.noCompaniesInvestedIn
                    }
                  </td>
                  <td className="invAdmInt">
                    {" "}
                    <div className="heightss">
                      {value?.investorRelationToBusinessInfo?.investorBusinessInfoData?.map(
                        (e, i) => (
                          <>{e.investorInvestorGroupName?.groupName} <br /> </>
                        )
                      )}
                    </div>
                  </td>
                  <td className="invAdmStatus">
                    <Switch
                      defaultChecked={value?.isActive}
                      onChange={() => onChange(value?._id, !value?.isActive)}
                    />
                  </td>
                  <td className="actionIcons">
                    <img
                      src={edit}
                      className="actionIconsAdm"
                      onClick={() => handleEdit(value._id)}
                    />

                    <img
                      src={trash}
                      className="actionIconsAdm"
                      // onClick={handleDelete}
                      onClick={() => handleModal(value._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
      <DeleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleDelete={handleDelete}
        id={id}
      />
    </div>
  );
}

export default AdminInvestor;
