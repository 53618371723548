import React, { Fragment, useEffect, useState } from "react";
import User from "../../../assets/user.png";
import Maps from "../../../assets/maps.png";
import { Investorsist } from "../../../Data/Data";
import Round from "../../../assets/round.png";
import CirclePlay from "../../../assets/circlePlay.png";
import { useNavigate } from "react-router-dom";
import UserFilters from "./UserFilters";
import Paginations from "../../Pagination";
import { useDispatch, useSelector } from "react-redux";
import { InvestorUserListing } from "../../../Redux/Actions/InvestorUserListing";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import Modal from "react-bootstrap/Modal";
import "swiper/css";
import "swiper/css/pagination";
import VideoPlayer from "../Call-Request/VideoPlayer";
import Avatar from "../../../assets/default.png";
import UserVideoPlayer from "./UserVideoPlayer";
// import Paginations from "../../Pagination";

function Userview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    setVideoUrl(id);
    setShow(true);
  };
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [videoUrl, setVideoUrl] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const { data } = useSelector((state) => state.InvestorUserListing);

  const handleRedirect = (id) => {
    navigate(`/investor/user-to-request/${id}`);
  };
  console.log(data, "<=====data");
  const handleRedirectDetail = (id) => {
    navigate(`/investor/user-to-request/${id}`);
  };

  console.log(data, "<=====data");
  useEffect(() => {
    dispatch(InvestorUserListing({ page, size }));
  }, [dispatch, page, size]);

  return (
    <Fragment>
      <div className="container-fluid investorlist ">
        {/* <UserFilters /> */}
        <div className="row">
          {data?.items?.map((data) => {
            return (
              <div className="col-sm-3 invetrs uslIs" key={data.key}>
                <div className="imagebox">
                  <img
                    src={
                      data?.userBusinessInfoData?.profile_pic
                        ? data?.userBusinessInfoData?.profile_pic
                        : Avatar
                    }
                    alt="asd"
                  />
                  <Swiper
                    pagination={{
                      type: "fraction",
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination]}
                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                    className="mySwiper"
                  >
                    {data?.userInvestorGroups?.map((item, i) => (
                      <SwiperSlide>
                        <div className="col-sm-12">
                          <p className="bstgre">
                            {item?.userInvestorGroupName?.groupName}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="row detailinvestor callList">
                  <div className="col-sm-7">
                    <h2>
                      {data.userBusinessInfoData?.firstName +
                        " " +
                        data.userBusinessInfoData?.lastName}
                    </h2>
                  </div>
                  <div className="col-sm-5">
                    <h4>${data?.InvestmentRaised}</h4>
                  </div>
                  <div className="col-sm-12 profdetails">
                    <h3>
                      <img src={Round} alt="as" /> Round {data?.Round}
                    </h3>

                    <p className="urls aaurls">
                      Website Links:{" "}
                      <a
                        href={data?.websiteLink}
                        target="_blank"
                        className="linkss"
                      >
                        Click To Visit
                      </a>
                    </p>

                    <div className="col-sm-12">
                      {" "}
                      <p>
                        Business Name:<span>{data?.businessName}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row playSection">
                  <div className="col-sm-12">
                    <button
                      onClick={() =>
                        handleShow(
                          data.userBusinessInfoData?.userStoryData?.Video
                        )
                      }
                    >
                      <img src={CirclePlay} alt="" /> Venture Story
                    </button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Body>
                        {/* <iframe
                          width="100%"
                          height="500"
                          src={item.userCallDetails?.userStoryData?.Video}
                        ></iframe> */}

                        <UserVideoPlayer link={videoUrl} />
                      </Modal.Body>
                      <Modal.Footer className="clareqwModal">
                        <button onClick={handleClose}>Close</button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>

                {/* <div className="row maps">
                  <div className="col-sm-3">
                    <img src={Maps} alt="asd" />
                  </div>
                  <div className="col-sm-9">
                    <p>{data.city}</p>
                  </div>
                </div> */}
                <div className="row btnSection">
                  <div className="col-sm-12">
                    {/* <button
                      onClick={() =>
                        handleRedirect(data?.userBusinessInfoData?._id)
                      }
                    >
                      Request a Call
                    </button> */}

                    <button
                      onClick={() =>
                        handleRedirectDetail(data?.userBusinessInfoData?._id)
                      }
                    >
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Paginations
          TotalData={data?.totalCount}
          handlePageChange={handlePageChange}
          currentPage={page}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
      {/* <Paginations /> */}
    </Fragment>
  );
}

export default Userview;
