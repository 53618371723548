import RootReducer from "./RootReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createTransform } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userAuth", "investorauth", "verify", "adminAuth"],
  // transforms: [
  //   createTransform(
  //     (inboundState, key) => {
  //       // Only persist the 'token' field for 'userAuth'
  //       if (key === "userAuth") {
  //         return { userToken: inboundState.userToken };
  //       }
  //       return inboundState;
  //     },

  //     // Use the default 'out' method for rehydration
  //     (outboundState) => outboundState,
  //     // Configuration options if needed
  //     {}
  //   ),
  // ],
};
export const persistedReducer = persistReducer(persistConfig, RootReducer);
