import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  deleteNewsLetterFailure,
  deleteNewsLetterStart,
  deleteNewsLetterSuccess,
  newsLetterFailure,
  newsLetterStart,
  newsLetterSuccess,
} from "../Reducers/NewsLetterReducer";

export const GetNewsLetter = createAsyncThunk(
  "newsletter",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(newsLetterStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/newsletter`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(newsLetterSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(newsLetterFailure(error.response.data.message));
    }
  }
);

export const DeleteNewsLetter = createAsyncThunk(
  "newsletter",
  async ({ id }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      thunkAPI.dispatch(deleteNewsLetterStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/newsletter/${id}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(deleteNewsLetterSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(deleteNewsLetterFailure(error.response.data.message));
    }
  }
);
