import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  reScheduleCallStart,
  reScheduleCallSuccess,
  reScheduleCallFailure,
} from "../Reducers/InvestorReschedule";
import axios from "axios";

export const InvestorReschedule = createAsyncThunk(
  "scheduleCalls",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(reScheduleCallStart());
      const investorstoken = thunkAPI.getState().investorauth.investortoken;
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/investor/InvestorReschedule`,
        data,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(reScheduleCallSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(reScheduleCallFailure(error.response.data.message));
    }
  }
);
