import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postUserStoryStart,
  postUserStorySuccess,
  PostUserStoryFailure,
  setUnderUploadedProgress,
} from "../Reducers/PostUserStoryReducer";
import axios from "axios";

export const PostUserStory = createAsyncThunk(
  "postuserstory",
  async (formData, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      thunkAPI.dispatch(postUserStoryStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/addStory`,
        formData,
        { headers: header },
        {
          onUploadProgress: (data) => {
            const progress = Math.round((data.loaded / data.total) * 100);
            thunkAPI.dispatch(setUnderUploadedProgress(progress));
          },
        }
      );

      thunkAPI.dispatch(postUserStorySuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(PostUserStoryFailure(error.response.data.message));

    }
  }
);
