import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  PostAdminInvestorStart,
  PostAdminInvestorSuccess,
  PostAdminInvestorFailure,
} from "../Reducers/PostAdminInvestor";
import axios from "axios";

export const AdminInvestor = createAsyncThunk(
  "postadmininvestor",
  async (data, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      thunkAPI.dispatch(PostAdminInvestorStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/createInvestor`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(PostAdminInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(PostAdminInvestorFailure(error.response.data.message));
    }
  }
);
