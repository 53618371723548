import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const GetCitySlice = createSlice({
  name: "getcity",
  initialState,
  reducers: {
    getCityStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getCitySuccess(state, action) {
      state.loading = false;
      state.data = action.payload?.data.items;
      state.message = action.payload.message;
    },

    getCityFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getCityStart,
  getCityFailure,
  clearError,
  clearMessage,
  getCitySuccess,
} = GetCitySlice.actions;

export default GetCitySlice.reducer;
