import React, { Fragment, useEffect, useState } from "react";
import Users from "../../../assets/user.png";
import Round from "../../../assets/round.png";
import { Link, useNavigate } from "react-router-dom";
import PDF1 from "../../../assets/pdf1.png";
import PDF2 from "../../../assets/pdf2.png";
import moment from "moment/moment";
import calender from "../../../assets/calendar.png";
import maps from "../../../assets/maps.png";
import { Form, DatePicker, Button, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { InvestorDatess } from "../../../Redux/Actions/InvestorDates";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/PostInvDateReducer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import VideoPlayer from "../Profile/VideoPlayer";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { InvestorReschedule } from "../../../Redux/Actions/InvestorRescheduleCalls";
import {
  clearError as clearErrors,
  clearMessage as clearMessages,
} from "../../../Redux/Reducers/InvestorReschedule";

function LeftSec({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabledDates, setDisabledDates] = useState([]);
  const { message, error, loading } = useSelector(
    (state) => state.investordates
  );
  const successMessage = useSelector((state) => state.InvestorReschedule.error);
  const errorMessage = useSelector((state) => state.InvestorReschedule.message);

  const videoId = data.userCallDetails?.userStoryData?.Video;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      date1: moment(fieldsValue.date1.toString()).utc().format(), // Use 'date1' instead of 'date-time-picker'
      date2: moment(fieldsValue.date2.toString()).utc().format(),
      date3: moment(fieldsValue.date3.toString()).utc().format(),
    };
    values.CallId = data._id;
    dispatch(InvestorReschedule(values));
    toast.success("we have your response");
    navigate(-1);
    console.log("Received values of form: ", values);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/investor/call-request");
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (successMessage) {
      toast.message(successMessage);
      dispatch(clearMessages());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(clearErrors());
    }
  }, [dispatch, error, message, navigate]);

  if (!data) {
    return <div>Data is not available.</div>;
  }
  console.log(data, "single data");
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const disabledDate = (current) => {
    // Disable dates that are before the current date or more than three days in the future
    return (
      current &&
      (current < dayjs() || current > dayjs().add(3, "day").endOf("day"))
    );
  };

  return (
    <Fragment>
      <div className="row leftsidedetail investerLeftsidedetail">
        <div className="col-sm-4 usImg">
          <img src={data?.userCallDetails?.profile_pic} />
          <div className="row">
            <div className="row interestInvestor">
              <Swiper
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Autoplay, Pagination]}
                autoplay={{ delay: 200000, disableOnInteraction: false }}
                className="mySwiper"
              >
                {data?.userCallDetails?.userBusinessInfoData?.userInvestorGroups?.map(
                  (item, i) => (
                    <SwiperSlide>
                      <div className="col-sm-12">
                        <p className="bstgre">
                          {item?.userInvestorGroupName?.groupName}
                        </p>
                      </div>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </div>
        </div>

        <div className="col-sm-8 profdetails axbhbxhsbx">
          {/* <Link to="/investor/edit-profile" className="linkEdit">Edit Profile</Link> */}
          <button
            className={
              data?.callIdDetails?.status === "accepted"
                ? "greenData"
                : "redData"
            }
          >
            {data?.callIdDetails?.status}
          </button>
          <div className="profDetailsTitle">
            <h2>
              {data?.userCallDetails?.firstName}{" "}
              {data?.userCallDetails?.lastName}
            </h2>
            <h3>
              <img src={Round} alt="as" /> Round{" "}
              {data.userCallDetails?.userBusinessInfoData?.Round}
            </h3>

            <p>
              Business Name:
              <span>
                {data?.userCallDetails?.userBusinessInfoData?.businessName}
              </span>
            </p>
          </div>

          <div className="row investorWeb">
            <div className="col-sm-12">
              <p>
                No. of Associated Partners:{" "}
                {
                  data?.userCallDetails?.userBusinessInfoData?.partnersInfo
                    .length
                }
              </p>
            </div>
            <div className="col-sm-12 urrrllss">
              <p className="urls">
                Website Links:{" "}
                <a
                  href={
                    data?.userCallDetails?.userBusinessInfoData?.websiteLink
                  }
                  target="_blank"
                  className="linkss"
                >
                  Click To Visit
                </a>
              </p>
            </div>
          </div>

          <div className="row inner">
            <div className="col-sm-6">
              <h2>Investment</h2>
              <h3>
                $ {data.userCallDetails?.userBusinessInfoData?.InvestmentRaised}
              </h3>
            </div>

            <div className="col-sm-6 ">
              <img src={maps} alt="" />
              <p>
                {data?.userCallDetails?.userInfo?.userCity?.name},{" "}
                {
                  data?.userCallDetails?.userInfo?.userCity?.StateData
                    ?.CountryDetail?.name
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <Form onFinish={onFinish}>
        <div className="row bottombox bottombox1">
          <div className="col-sm-4 deckpdf">
            <div className="pitch">
              <h3>Pitch Deck</h3>
              <Link
                to={data?.userCallDetails?.userPitchDeckData?.file}
                target="_blank"
              >
                {" "}
                <img src={PDF1} alt="asd" />
                <p className="titles">
                  {/* {data?.userCallDetails?.userPitchDeckData?.title} */}
                </p>
              </Link>
            </div>

            <div className="pitch">
              <h3>Business Info</h3>
              {data.userCallDetails?.userBusinessInfoData?.UserBusinessFileData?.map(
                (items) => {
                  return (
                    <Link to={items.file} target="_blank">
                      <img src={PDF1} alt="asd" />
                      {/* <p className="titles">{items.file}</p> */}
                    </Link>
                  );
                }
              )}
            </div>
          </div>

          <div className="col-sm-8 textSection">
            {/* <p>{data?.userCallDetails?.userStoryData?.description}</p> */}

            <div className="row leftSecDateSelect">
              <div className="col-sm-12 greyDate">
                {/* <div className="greyBox">
                  <img src={calender} alt="" />
                  <p> {moment(data?.createdAt).format("YYYY-MM-DD HH:mm a")}</p>
                </div> */}
              </div>

              <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <Form.Item
                    name="Rescheduled"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        message: "Please Type Reason",
                      },
                    ]}
                  >
                    <Input.TextArea
                      showTime
                      placeholder="Type Reason Why Reschedule the call"
                      format="YYYY-MM-DD HH:mm:ss"
                      // disabledDate={disabledDate}
                    />
                  </Form.Item>
                </div>
                <div className="greyBox">
                  <Form.Item name="date1" {...config}>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      // disabledDate={disabledDate}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <Form.Item name="date2" {...config}>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      // disabledDate={disabledDate}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-sm-12 greyDate">
                <div className="greyBox">
                  <Form.Item name="date3" {...config}>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      // disabledDate={disabledDate}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="col-sm-12 greyDate myheights">
                <div className="greyBox">
                  <Form.Item
                    name="status"
                    initialValue="accepted"
                    rules={[{ required: true }]}
                  >
                    <Input type="hidden" />
                  </Form.Item>
                  {/* <Form.Item name="CallId" initialValue={data?._id} rules={[{ required: true }]}>
                    <Input type="hidden" />
                  </Form.Item> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 rightvideo mob">
          {/* <iframe
            title="YouTube Video"
            width="100%"
            height="600"
            style={{ borderRadius: "20px" }}
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          /> */}
          <VideoPlayer />
        </div>

        <div className="row btnSection calReqLeft">
          <div className="col-sm-6 btnLefty">
            <Button type="primary" htmlType="submit">
              {loading ? (
                <Spin indicator={antIcon} style={{ color: "#ffff" }} />
              ) : (
                "Schedule"
              )}
            </Button>
          </div>
          <div className="col-sm-6 btnRights">
            <button className="backBtn">Back </button>
          </div>
        </div>
      </Form>
    </Fragment>
  );
}

export default LeftSec;
