import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  investorForgetPasswordStart,
  investorForgetPasswordSuccess,
  investorForgetPasswordFailure,
  investorConfirmPasswordStart,
  investorConfirmPasswordSuccess,
  investorConfirmPasswordFailure,
} from "../Reducers/InvestorPasswordReducer";
import axios from "axios";

export const InvestorForgetPassword = createAsyncThunk(
  "investorpassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(investorForgetPasswordStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}investor/password/forgot`,
        data
      );

      thunkAPI.dispatch(investorForgetPasswordSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        investorForgetPasswordFailure(error.response.data.message)
      );
    }
  }
);

export const InvestorConfirmPassword = createAsyncThunk(
  "investorpassword",
  async ({ id, data }, thunkAPI) => {
    try {
      thunkAPI.dispatch(investorConfirmPasswordStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/investor/password/reset/${id}`,
        data
      );

      thunkAPI.dispatch(investorConfirmPasswordSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        investorConfirmPasswordFailure(error.response.data.message)
      );
    }
  }
);
