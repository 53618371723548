import React, { Fragment, useState } from "react";
import user from "../../../assets/user.png";
import circle from "../../../assets/round.png";
import map from "../../../assets/map.png";
import city from "../../../assets/city.png";
import dail from "../../../assets/dail.png";
import linkedin from "../../../assets/linkedInn.png";
import env from "../../../assets/env.png";
import link from "../../../assets/link.png";
import circlePlay from "../../../assets/Circled Play.png";
import Document from "../../../assets/Document.png";
import copy from "../../../assets/Copy.png";
import { Button, Form, Input, Radio, TimePicker } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

function AdminEditScheduledCalls(){
    // const [changeColor, setChangeColor] = useState("first");

    const [value, setValue] = useState();

    const navigate = useNavigate();

    const handleFinish =(e) => {
        console.log(e);
        navigate('/admin/scheduled-calls');
    }
    
    dayjs.extend(customParseFormat);
    
    const onRadioChange = (e) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };

    const onDateChange = (time, timeString) => {
        console.log(time, timeString);
    };

    // const colorHandler = (color) => {
    //   setChangeColor(color);
    // };

    
  
    return (
        <Fragment>
            <div className="container-fluid mainedit">
                <div className="col-sm-12 bgWhite scheduleee">
                <Form onFinish={handleFinish}>
                    <div className="row userDetailStory">
                        <p className="callRequestHead">USER DETAILS</p>
            
                        <div className="col-sm-7 userDetails">
                            <div className="row usDt">
                                <div className="col-sm-4 usImg">
                                    <img src={user} />
                                    <div className="bgGreen">
                                        <p>IT INDUSTRY</p>
                                    </div>
                                </div>
                
                                <div className="col-sm-4 userName">
                                <p className="title">Ali</p>
                                <p className="colorBlue">
                                    <img src={circle} /> Beginner
                                </p>
                                <p className="colorGrey">
                                    Business Name <br />
                                    <b>YouPitch</b>
                                </p>
                                <div className="insideUsName">
                                    <div className="div1">
                                    <p className="small">Interest Group</p>
                                    <p className="large">IT Industry</p>
                                    </div>
                                    <div className="div2">
                                    <p className="small">Investment Amount</p>
                                    <p className="large">$ 10,000</p>
                                    </div>
                                </div>
                                </div>
                
                                <div className="col-sm-4 listDetailsss">
                                    <div className="insideListDetailsDiv">
                                        <img src={map} />
                                        <p>2972 Westheimer Rd.</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={city} />
                                        <p>United States</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={dail} />
                                        <p>+1 2322 23234</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={linkedin} />
                                        <p>in/businnessname</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={env} />
                                        <p><a href="">info@mybusiness.com</a></p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={link} />
                                        <p>www.mybusiness.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div className="col-sm-5 userStory">
                            <div className="row">
                                <div className="col-sm-6">
                                    <p className="headsss">User Story</p>
                                    <div className="imageBox">
                                        <img src={circlePlay} />
                                    </div>
                                </div>
                                <div className="col-sm-6 pitchDeck">
                                    <p className="headsss">Pitch Deck</p>
                                    <div className="docBox piutch">
                                        <div className="doc1">
                                            <div className="docImagessss">
                                                <img src={Document} className="documentImagesss" />
                                            </div>
                                            <p>financial.pdf</p>
                                        </div>
                                        <div className="doc1">
                                            <div className="docImagessss">
                                                <img src={Document} className="documentImagesss" />
                                            </div>
                                            <p>financial.pdf</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row investorCall">
                        <div className="col-sm-7 investorDetails">
                            <p className="callRequestHead">Investor Details</p>
                            <div className="row">
                                <div className="col-sm-4 investorImage">
                                    <img src={user} />
                                </div>
                                <div className="col-sm-4 investorDetailsss">
                                    <p className="title">Ben</p>
                                    <div className="insideUsName investorrrrr">
                                        <div className="div2">
                                            <p className="small">Interest Group</p>
                                            <p className="large">IT Industry</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 listDetailsss investorDetailsss">
                                    <div className="insideListDetailsDiv">
                                        <img src={map} />
                                        <p>2972 Westheimer Rd.</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={city} />
                                        <p>United States</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={dail} />
                                        <p>+1 2322 23234</p>
                                    </div>
                                    <div className="insideListDetailsDiv">
                                        <img src={env} />
                                        <p><a href="">info@mybusiness.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div className="col-sm-5 callDetailss">
                            <p className="callRequestHead">Call Details</p>
                            <div className="row">
                                <Form.Item
                                    name='radioDates'
                                    rules={[{ required: true, message: 'Please select date', },
                                ]}
                                >

                                    <div className="col-sm-12 calDet calDets">
                                        <Radio.Group onChange={onRadioChange} value={value}>
                                            <Radio value={1} >13-Nov-2023</Radio>
                                            <Radio value={2}>19-Nov-2023</Radio>
                                            <Radio value={3}>23-Nov-2023</Radio>
                                        </Radio.Group>
                                    </div>
                                </Form.Item>

                                <div className="col-sm-12 calDets dtaePicker">
                                <Form.Item
                                    name='timemeet'
                                    rules={[{ required: true, message: 'Please select time' }]}
                                >
                                        <TimePicker onChange={onDateChange} size="large"/>
                                </Form.Item>
                                    </div>
                                
                                <Form.Item
                                    name='desc'
                                    rules={[{ required: true, message: 'Please input Meeting Link For Zoom', },
                                    ]}
                                >

                                    <div className="col-sm-12 linkMeettt">
                                        <div className="insideLinkMeet">
                                            <Input.TextArea rows={4} placeholder="Meeting Link For Zoom" />
                                            <img src={copy} />
                                        </div>
                                    </div>
                                </Form.Item>
                                
                                
                            </div>
                        </div>
                    </div>
                    
                    <div className="row butoon">
                        <div className="adminAddSubscriptionPackageButton adminSaveee">
                        {/* <Link to="/admin/scheduled-calls"> */}
                            <Button htmlType="submit">Update</Button>
                        {/* </Link> */}
                        </div>
                    </div>
                </Form>
                    
        
        
                </div>
            </div>
        </Fragment>
    );
}

export default AdminEditScheduledCalls