import React from 'react'
import SideBar from '../../Componenets/User/Sidebar/SideBar'
import Packages from '../../Componenets/User/SubsPackage/Packages'
import Header from '../../Componenets/User/Header/Header'

function SubscriptionPackage() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row ">
        <div className="col-sm-3 sidebar">
          <SideBar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <Header />
          <Packages />
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPackage