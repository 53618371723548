import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  InvestorDatesStart,
  InvestorDatesSuccess,
  InvestorDatesFailure,
} from "../Reducers/PostInvDateReducer";
import axios from "axios";

export const InvestorDatess = createAsyncThunk(
  "investordates",
  async (data, thunkAPI) => {
    try {
      const investorstoken= thunkAPI.getState().investorauth.investortoken
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };
      console.log(investorstoken, "myprofileresponsetoken")

      thunkAPI.dispatch(InvestorDatesStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/investor/callStatusSubmit`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(InvestorDatesSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(InvestorDatesFailure(error.response.data.message));
    }
  }
);
