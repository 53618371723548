import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

function UserVideoPlayer({ link }) {
  const videoRef = useRef(null);
  console.log(link, "<============link");
  useEffect(() => {
    const video = videoRef.current;

    if (link) {
      if (video) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(link);
          hls.attachMedia(video);
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
          video.src = link;
        }
      }
    }
  }, [link]);

  return (
    <div>
      <video
        style={{ height: "70vh", width: "100%" }}
        ref={videoRef}
        controls
      />
    </div>
  );
}

export default UserVideoPlayer;
