import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginStart,
  loginSuccess,
  loginFailure,
  signUpStart,
  signUpSuccess,
  signUpFailure,
} from "../Reducers/UserAuthReducer";
import axios from "axios";

export const UserAuthLogin = createAsyncThunk(
  "userauth",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(loginStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/login`,
        data
      );

      thunkAPI.dispatch(loginSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(loginFailure(error.response.data.message));
    }
  }
);

export const UserSignUp = createAsyncThunk(
  "userauth",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(signUpStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/register`,
        data
      );

      thunkAPI.dispatch(signUpSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(signUpFailure(error.response.data.message));
    }
  }
);