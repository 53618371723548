import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInvestorStart,
  getInvestorSuccess,
  getInvestorFailure,
  getSingleInvestorStart,
  getSingleInvestorSuccess,
  getSingleInvestorFailure,
  deleteInvestorStart,
  deleteInvestorSuccess,
  deleteInvestorFailure,
  changeStatusInvestorStart,
  changeStatusInvestorSuccess,
  changeStatusInvestorFailure,
} from "../Reducers/GetInvestorReducer";
import axios from "axios";

export const GetInvestor = createAsyncThunk(
  "getInvestor",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getSingleInvestorStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      const queryParam = {
        ...(page && { page }),
        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/allInvestors`,
        {
          headers: header,
          params: queryParam,
        }
      );

      thunkAPI.dispatch(getSingleInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(getSingleInvestorFailure(error.response.data.message));
    }
  }
);

export const GetSingleInvestor = createAsyncThunk(
  "getInvestor",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getInvestorStart());
      const investorstoken = thunkAPI.getState().investorauth.investortoken;
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/editProfileData`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(getInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(getInvestorFailure(error.response.data.message));
    }
  }
);

export const DeleteInvestor = createAsyncThunk(
  "user",
  async ({ id }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      thunkAPI.dispatch(deleteInvestorStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}admin/deleteInvestor/${id}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(deleteInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(deleteInvestorFailure(error.response.data.message));
    }
  }
);

export const changeInvestorStatus = createAsyncThunk(
  "user",
  async ({ data }, thunkAPI) => {
    try {
      thunkAPI.dispatch(changeStatusInvestorStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      console.log(adminAuth, "<====adminAuth");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/activateDeactivateInvestor`,
        data,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(changeStatusInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        changeStatusInvestorFailure(error.response.data.message)
      );
    }
  }
);