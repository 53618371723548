import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, Input, Select, Upload, Switch,Spin } from 'antd';
import UploadIcon from '../../../assets/Upload.png';
import Password from "../../../assets/pass.png"
import { Link, useNavigate } from 'react-router-dom';

import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { AdminInvestor } from "../../../Redux/Actions/AdminInvestor";
import { clearError, clearMessage } from "../../../Redux/Reducers/InvestorBusinessPostReducer";
import { GetInvestorGroup } from "../../../Redux/Actions/GetInvestorGroup";
import { GetCountry } from "../../../Redux/Actions/GetCountry";
import { GetCity } from "../../../Redux/Actions/GetCity";




const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function AdminAddInvestors() {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const { message, error, loading } = useSelector(
    (state) => state.postadmininvestor
  );
  useEffect(() => {
    if (message) {
      toast.success(message);
      Dispatch(clearMessage());
      navigate(-1);
    }
    if (error) {
      toast.error(error);
      Dispatch(clearError());
    }
  }, [Dispatch, error, message, navigate]);

  // Get Groups
  const { data } = useSelector((state) => state.getinvestorgroup);

  useEffect(() => {
    Dispatch(GetInvestorGroup());
  }, [Dispatch]);

  const options = data.map((item) => ({
    value: item._id,
    label: item.groupName,
  }));

  // get country n city
  const Countrydata = useSelector((state) => state.country.data);
  const cityData = useSelector((state) => state.city.data);

  const handleChange = (e) => {
    Dispatch(GetCity(e));
  };

  useEffect(() => {
    Dispatch(GetCountry());
  }, [Dispatch]);

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  // post form
  const onFinish = async (values) => {
    const formData = new FormData();

    for (let i = 0; i < values.InvestorGroupIds.length; i++) {
      formData.append("InvestorGroupIds", values.InvestorGroupIds[i]);
    }

    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("cityId", values.cityId);
    formData.append("password", values.password);
    formData.append("pnum", values.pnum);
    formData.append("noCompaniesInvestedIn", values.noCompaniesInvestedIn);
    formData.append("Address", values.Address);
    formData.append("Bio", values.Bio);
    formData.append("businessName", values.businessName);
    formData.append("WebsiteLink", values.WebsiteLink);

    if (values.image && values.image[0]?.originFileObj) {
      formData.append("image", values.image[0]?.originFileObj);
    }
    // Append the video file to FormData for binary
    if (values.video && values.video[0]?.originFileObj) {
      formData.append("video", values.video[0]?.originFileObj);
    }

    try {
      await Dispatch(AdminInvestor(formData));
      console.log("Success:", formData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <Fragment>
      <div className="container-fluid mainedit">
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="row editform adminForrrmmm">
            <div className="col-sm-8">
              <h3>Personal Information</h3>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="firstName"
                    rules={[
                      { required: true, message: "Please input First Name" },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="lastName"
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input Email Address",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="Address"
                    rules={[
                      { required: true, message: "Please input Address" },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your Country!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Country"
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => handleChange(e)}
                    >
                      {Countrydata?.map((e) => (
                        <Option key={e._id} value={e._id}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="cityId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                    ]}
                  >
                    <Select placeholder="City"
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => handleChange(e)}>
                      {cityData?.map((e) => (
                        <Option value={e._id}>{e.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-12">
                  <Form.Item
                    name="Bio"
                    rules={[{ required: true, message: "Please input Bio" }]}
                  >
                    <Input placeholder="Bio" />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="WebsiteLink"
                    rules={[
                      { required: true, message: "Please input Website" },
                    ]}
                  >
                    <Input placeholder="Website" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="pnum"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone Number!",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numbers.",
                      },
                    ]}
                  >
                    <Input placeholder="Phone" />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="confirmpassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Confirm password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                </div>
                {/* <div className="col-sm-6 switchh">
                  <Form.Item name="isActive ">
                    <Input placeholder="Approve" readOnly='true' />
                    <Switch defaultChecked onChange={onChange} />
                  </Form.Item>
                </div> */}
                {/* <div className="col-sm-6 switchh">
                  <Form.Item name="activate">
                    <Input placeholder="Activate" readOnly='true' />
                    <Switch defaultChecked onChange={onChange} />
                  </Form.Item>
                </div> */}
              </div>
            </div>

            <div className="col-sm-4">
              <h3>Business Information</h3>
              <Form.Item
                name="businessName"
                rules={[
                  { required: true, message: "Please input Business Name" },
                ]}
              >
                <Input placeholder="Business Name" />
              </Form.Item>

              {/* <Form.Item
                  name="interestGroup"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Group Of Interest",
                    },
                  ]}
                >
                  <Input placeholder="Interest Group" />
                </Form.Item> */}

              <div className='slectedits'>
                <Form.Item
                  name="InvestorGroupIds"
                  rules={[{ required: true, message: 'Please Chose Investor Group', },
                  ]}    >
                  <Select
                    showSearch
                    placeholder="Select Investor Group"
                    optionFilterProp="children"
                    mode="multiple" // Enable multi-select
                    maxTagCount={5} // Set the maximum number of displayed tags
                  >
                    {options.map((option, index) => (
                      <Option key={option.value || index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

              </div>

              <Form.Item
                name="noCompaniesInvestedIn"
                rules={[
                  {
                    required: true,
                    message: "Please Input Number Of Companies",
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter only numbers.",
                  },
                ]}
              >
                <Input placeholder="No. Of Company Invested In" />
              </Form.Item>

              <Form.Item
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        validator: (_, value) => {
                          // Check if the uploaded file is a valid image (JPG or PNG)
                          if (value) {
                            const isValidFileType =
                              value[0].type === "image/jpeg" ||
                              value[0].type === "image/png";
                            if (!isValidFileType) {
                              return Promise.reject(
                                "Please upload a valid image file (JPG or PNG)."
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      beforeUpload={() => false}
                      accept="image/jpeg, image/png"
                      name="image"
                      listType="picture"
                    >
                  <div className="uploadddDocumentInvestor">
                    <p>Profile Picture</p>
                    <img src={UploadIcon} />
                  </div>
                </Upload>
              </Form.Item>

              <Form.Item
                  name="video"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    action="/upload.do"
                    beforeUpload={() => false}
                    accept="video/*"
                    name="video"
                    listType="picture"
                  >
                  <div className="uploadddDocumentInvestor">
                    <p>Upload Video</p>
                    <img src={UploadIcon} />
                  </div>
                </Upload>
              </Form.Item>
            </div>

            <Form.Item className="adminAddSubscriptionPackageButton adminSaveee">
              {/* <Link to="/admin/investors"> */}
              <Button htmlType="submit">
                {loading ? (
                      <Spin indicator={antIcon} style={{ color: "#ffff" }} />
                    ) : (
                      "Save"
                    )}</Button>
              {/* </Link> */}
            </Form.Item>
          </div>
        </Form>
      </div >
    </Fragment >
  );
}

export default AdminAddInvestors