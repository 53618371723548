import React from 'react'
import LoginSlide from '../../Componenets/Investor/Login/LoginSlide'
import SignUp from '../../Componenets/Investor/SignUp/SignUp'

function InvestorSignUp() {
  return (
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <SignUp />
        </div>
      </div>

    </div>
  );
}

export default InvestorSignUp;
