import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  error: null,
  data: [],
};

const CalltoInvestorSlice = createSlice({
  name: "calltoinvestor",
  initialState,
  reducers: {
    callToInvestorStart(state) {
      state.loading = true;
      state.error = null;
    },

    callToInvestorSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
    },

    callToInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  callToInvestorStart,
  callToInvestorFailure,
  clearError,
  callToInvestorSuccess,
  clearMessage,
} = CalltoInvestorSlice.actions;

export default CalltoInvestorSlice.reducer;
