import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  userProfleStart,
  userProfileSuccess,
  userProfileFailure,
} from "../Reducers/UserProfileReducer";
import axios from "axios";

export const UserProfile = createAsyncThunk(
  "userprofile",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(userProfleStart());

      const userToken = thunkAPI.getState().userAuth.userToken;
console.log(userToken, "token");
const header = {
  Authorization: `Bearer ${userToken}`,
};
console.log(userToken, "<====userToken");
const response = await axios.get(
  `${process.env.REACT_APP_BASE_URL}/user/profile`,
  {
    headers: header,
  }
);

      thunkAPI.dispatch(userProfileSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(userProfileFailure(error.response.data.message));
    }
  }
);
