import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditInterestGroup } from "../../../Redux/Actions/InterestGroup";
import { toast } from "react-toastify";
import { clearError, clearMessage } from "../../../Redux/Reducers/InterestGroupReducer";

function AdminInterestGroupEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { state } = location;
  const { data } = state;
  const { error, message, loading } = useSelector((state) => state.interestGroup);

  const onFinish = (values) => {
    const id = data._id;
    dispatch(EditInterestGroup({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({
      groupName: data?.groupName,
    });
  }, []);
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/admin/interest-group");
      dispatch(clearMessage());
    }
  }, [error, message]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminAddSubscriptionPackage">
        <div className="adminAddSubscriptionPackageData">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <Form.Item
              name="groupName"
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item className="adminAddSubscriptionPackageButton intADmGroup">
              {/* <Link to='/admin/subscription-packages'> */}
              <Button htmlType="submit">Save</Button>
              {/* </Link> */}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminInterestGroupEdit;
