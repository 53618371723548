import React, { Fragment, useEffect } from "react";
import { Button, Checkbox, Form, Input, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";

import Envlop from "../../../assets/env.png";
import Password from "../../../assets/pass.png";
import UserLinkedin from "./UserLinkedin";
import { useDispatch, useSelector } from "react-redux";
import { UserAuthLogin } from "../../../Redux/Actions/UserAuth";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/UserAuthReducer";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Loader from "../../Loader";
import Cookies from "js-cookie";

<Spin
  indicator={
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  }
/>;

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading, isLinkedin } = useSelector(
    (state) => state.userAuth
  );
  const { data } = useSelector((state) => state.verify);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      if (data.user && isLinkedin) {
        if (data?.user && !data.user?.UsersInfoDetail) {
          navigate("/user/add-info");
        } else if (data?.user && !data.user?.userStoryDetail) {
          navigate("/user/story");
        } else if (data?.user && !data.user?.userPitchDeckDetail) {
          navigate("/user/pitchdeck");
        } else if (data?.user && !data.user?.userBusinessInfoDetail) {
          navigate("/user/business-details");
        } else if (data?.user && data.user?.userBusinessInfoDetail) {
          navigate("/user/profile");
        }
      } else {
        if (data?.user && !data.user?.userStoryDetail) {
          navigate("/user/story");
        } else if (data?.user && !data.user?.userPitchDeckDetail) {
          navigate("/user/pitchdeck");
        } else if (data?.user && !data.user?.userBusinessInfoDetail) {
          navigate("/user/business-details");
        } else if (data?.user && data.user?.userBusinessInfoDetail) {
          navigate("/user/profile");
        }
      }
    }
  }, [data]);

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(UserAuthLogin(values));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  console.log(data, "data");

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [error, message]);



  return (
    <Fragment>
      <div className="row useLoginR">
        <div className="col-sm-12 loginform">
          <h2>Venture Login</h2>
          <p>Welcome back! please enter your detail</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                placeholder="Username or E-mail"
                prefix={<Image src={Envlop} preview={false} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<Image src={Password} preview={false} />}
              />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              className="checkrem"
            >
              <Checkbox>Remember me</Checkbox>
              <Link to="/user/forget-password">Forget Password</Link>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading ? true : false}
              >
                {loading ? <Loader /> : "Submit"}
              </Button>
              <br />

              {/* <Link to="" className='authlog'><img src={Linkdin} alt='' />Login through Linkedin</Link> */}

              <UserLinkedin />
              <p className="ques">
                Don't have an account? <Link to="/user/signup">Signup</Link>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default LoginForm;
