import React, { Fragment, useState, useEffect } from 'react'
import Logo from "../../../assets/Logo.png"
import { Link, NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Icons from "../../../assets/iconimg.png"

function AdminSidebar() {
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    // Retrieve the active link from localStorage on component mount
    const storedActiveLink = localStorage.getItem('activeLink');
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    // Save the active link to localStorage
    localStorage.setItem('activeLink', link);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <div className="col-sm-12 sidelogo">
        <img src={Logo} alt="" />
      </div>

      {/* menu for desktop */}
      <div className="col-sm-12 desktop">
        <ul>
          <li>
            <Link
              to=""
              onClick={() => handleLinkClick("Dashboard")}
              className={activeLink === "Dashboard" ? "active" : ""}
              activeClassName="active"
            >
              Dashboard
            </Link>
          </li>
          <li>
            <NavLink
              to="/admin/subscription-packages"
              onClick={() => handleLinkClick("Subscription Package")}
              //   className={activeLink === "Subscription Package" ? "active" : ""}
              activeClassName="active"
            >
              Subscription Package
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/scheduled-calls"
              onClick={() => handleLinkClick("Schedule Calls")}
              className={activeLink === "Schedule Calls" ? "active" : ""}
              activeClassName="active"
            >
              Schedule Calls
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/users"
              // onClick={() => handleLinkClick("Users")}
              onClick={() => handleLinkClick("Users")}
              className={activeLink === "Users" ? "active" : ""}
              activeClassName="a"
            >
              Ventures
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/call-request"
              onClick={() => handleLinkClick("List Of Call Request")}
              className={activeLink === "List Of Call Request" ? "active" : ""}
              activeClassName="active"
            >
              List Of Call Request
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/investors"
              onClick={() => handleLinkClick("Investors")}
              className={activeLink === "Investors" ? "active" : ""}
              activeClassName="active"
            >
              Investors
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/interest-group"
              onClick={() => handleLinkClick("interest-group")}
              className={activeLink === "interest-group" ? "active" : ""}
              activeClassName="active"
            >
              Interest Group
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/contact-list"
              onClick={() => handleLinkClick("contact-list")}
              className={activeLink === "contact-list" ? "active" : ""}
              activeClassName="active"
            >
              Contact List
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/newsletter"
              onClick={() => handleLinkClick("newsletter")}
              className={activeLink === "newsletter" ? "active" : ""}
              activeClassName="active"
            >
              Newsletter Subscriptions
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/business-leads"
              onClick={() => handleLinkClick("business-leads")}
              className={activeLink === "business-leads" ? "active" : ""}
              activeClassName="active"
            >
              Business Leads
            </NavLink>
          </li>
        </ul>
      </div>

      {/* menu for mobile */}

      <div className="col-sm-12 mobile">
        <Button variant="primary" onClick={handleShow}>
          <img src={Icons} alt="asd" />
        </Button>

        <Offcanvas
          show={show}
          onHide={handleClose}
          className="offCanvasSection"
        >
          <Offcanvas.Header closeButton>
            <img src={Logo} alt="" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              <li>
                <Link
                  to=""
                  onClick={() => handleLinkClick("Dashboard")}
                  className={activeLink === "Dashboard" ? "active" : ""}
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/subscription-packages"
                  onClick={() => handleLinkClick("Subscription Package")}
                  className={
                    activeLink === "Subscription Package" ? "active" : ""
                  }
                >
                  Subscription Package
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/scheduled-calls"
                  onClick={() => handleLinkClick("Schedule Calls")}
                  className={activeLink === "Schedule Calls" ? "active" : ""}
                >
                  Schedule Calls
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/users"
                  onClick={() => handleLinkClick("Users")}
                  className={activeLink === "Users" ? "active" : ""}
                >
                  Ventures
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/call-request"
                  onClick={() => handleLinkClick("List Of Call Request")}
                  className={
                    activeLink === "List Of Call Request" ? "active" : ""
                  }
                >
                  List Of Call Request
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/investors"
                  onClick={() => handleLinkClick("Investor")}
                  className={activeLink === "Investor" ? "active" : ""}
                >
                  Investor
                </Link>
              </li>

              <li>
                <Link
                  to="/admin/interest-group"
                  onClick={() => handleLinkClick("interest-group")}
                  className={activeLink === "interest-group" ? "active" : ""}
                >
                  Interest Group
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/contact-list"
                  onClick={() => handleLinkClick("contact-list")}
                  className={activeLink === "contact-list" ? "active" : ""}
                >
                  Contact List
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/newsletter"
                  onClick={() => handleLinkClick("newsletter")}
                  className={activeLink === "newsletter" ? "active" : ""}
                >
                  Newsletter Subscriptions
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/business-leads"
                  onClick={() => handleLinkClick("business-leads")}
                  className={activeLink === "business-leads" ? "active" : ""}
                >
                  Business Leads
                </Link>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Fragment>
  );
}

export default AdminSidebar