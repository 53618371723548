import React, { Fragment } from 'react'
import wordImage from '../../assets/WordFiless.png'
import courses from '../../assets/courses.png'
import link from '../../assets/Linksss.png'
import pdf from '../../assets/PDFss.png'
import ecxcel from '../../assets/ExcelFiless.png'
import videos from '../../assets/videosss.png'
import book from '../../assets/Group-removebg-preview.png'
import { Link } from 'react-router-dom'

const LearnPortal = () => {
    return (
        <Fragment>
            <div className="container-fluid investorlist">
                <div className="row sociall">
                    <div className="col-sm-6 imagesDesc">
                        <Link to='/investor/learning-portal/short-courses'>
                            <div className="imagess">
                                <img src={courses} alt='course'/>
                                <p>Short Courses</p>
                            </div>
                        </Link>

                        <Link to='/investor/learning-portal/videos'>   
                            <div className="imagess">
                                <img src={videos} alt='videos'/>
                                <p>Videos</p>
                            </div>
                        </Link>

                        <Link to='/investor/learning-portal/links'>
                            <div className="imagess">
                                <img src={link} alt='link'/>
                                <p>Links</p>
                            </div>
                        </Link>

                        <Link to="/investor/learning-portal/pdf's">
                            <div className="imagess">
                                <img src={pdf} alt='pdf'/>
                                <p>PDF's</p>
                            </div>
                        </Link>

                        <Link to='/investor/learning-portal/excel-files'>
                            <div className="imagess">
                                <img src={ecxcel} alt='ecxcel'/>
                                <p>Excel Files</p>
                            </div>
                        </Link>

                        <Link to='/investor/learning-portal/word-files'>
                            <div className="imagess">
                                <img src={wordImage} alt='wordImage'/>
                                <p>Word Files</p>
                            </div>
                        </Link>
                    </div>

                    <div className="col-sm-6 backgroundImageBook">
                        <img src={book} alt='grpImage' />
                    </div>
                </div>
            </div> 
        </Fragment>
    )
}

export default LearnPortal