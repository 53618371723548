import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  token: null,
};

const InvestorSignup = createSlice({
  name: "investorsignup",
  initialState,
  reducers: {
    InvestorSignupStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    InvestorSignupSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Investor Register !";
      state.token = action.payload.token;
    },

    logOutSuccess(state) {
      state.data = null;
      state.token = null;
      state.message = null;
      console.log("chala");
      // Cookies.remove("token");
    },

    InvestorSignupFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  InvestorSignupStart,
  InvestorSignupSuccess,
  InvestorSignupFailure,
  clearError,
  clearMessage,
  logOutSuccess,
} = InvestorSignup.actions;

export default InvestorSignup.reducer;
