import React, { Fragment, useEffect } from "react";
import { Button, Checkbox, Form, Input, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Envlop from "../../../assets/env.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/UserPasswordReducer";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Loader from "../../Loader";
import { UserForgetPassword } from "../../../Redux/Actions/UserPasswordReducer";

<Spin
  indicator={
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  }
/>;

function ForgetForm() {
  console.log(window.location.href, "<=========href");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading } = useSelector(
    (state) => state.userPassword
  );
  const { data } = useSelector((state) => state.verify);

  const onFinish = (values) => {
    console.log("Success:", values);
    values.ClientUrl = window.location.href.split("/")[2];
    dispatch(UserForgetPassword(values));
    // toast.success("Please Check your Email");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  console.log(data, "data");

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [error, message]);
  // useEffect(() => {
  //   // Get current URL
  //   const currentUrl = window.location.href;
  //   console.log('Current URL:', currentUrl);
  // }, []);
  return (
    <Fragment>
      <div className="row storyUSer">
        <div className="col-sm-12 loginform">
          <h2>Forget Password</h2>
          <p>Don`t Worry If You Forget Your Password</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                  type: "email",
                },
              ]}
            >
              <Input
                placeholder="Username or E-mail"
                prefix={<Image src={Envlop} preview={false} />}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading ? true : false}
              >
                {loading ? <Loader /> : "Send"}
              </Button>
              <br />

              <p className="ques">
                Don't have an account? <Link to="/user/signup">Signup</Link>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default ForgetForm;
