import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  userForgetPasswordStart,
  UserForgetPasswordSuccess,
  UserForgetPasswordFailure,
  confirmPasswordStart,
  confirmPasswordSuccess,
  confirmPasswordFailure,
} from "../Reducers/UserPasswordReducer";
import axios from "axios";

export const UserForgetPassword = createAsyncThunk(
  "userpassword",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(userForgetPasswordStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/password/forgot`,
        data
      );

      thunkAPI.dispatch(UserForgetPasswordSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(UserForgetPasswordFailure(error.response.data.message));
    }
  }
);

export const UserConfirmPassword = createAsyncThunk(
  "userauth",
  async ({ id, data }, thunkAPI) => {
    try {
      thunkAPI.dispatch(confirmPasswordStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}password/reset/${id}`,
        data
      );

      thunkAPI.dispatch(confirmPasswordSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(confirmPasswordFailure(error.response.data.message));
    }
  }
);
