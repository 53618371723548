import React, { Fragment, useEffect, useState } from "react";
import User from "../../../assets/user.png";
import Maps from "../../../assets/maps.png";
import { Investorsist } from "../../../Data/Data";
import Round from "../../../assets/round.png";
import CirclePlay from "../../../assets/circlePlay.png";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { GetCallReqInvs } from "../../../Redux/Actions/GetInvCallReq";
import VideoPlayer from "./VideoPlayer";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Dropdown from "react-bootstrap/Dropdown";
import Filters from "../../../assets/filter.png";
import Sorts from "../../../assets/sort.png";
import Nodata from "../../../assets/nodata.jpg";
import Paginations from "../../Pagination";

function Investorview() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data } = useSelector((state) => state.getinvcallreq);
  const Dispatch = useDispatch();

  console.log(data, "useselector");

  useEffect(() => {
    Dispatch(GetCallReqInvs());
  }, [Dispatch]);

  const navigate = useNavigate();
  const handleAcceptClick = (id) => {
    navigate(`/investor/user-requests/${id}`);
    console.log(id, "asd");
  };

  const handleDeclineClick = (id) => {
    navigate(`/investor/decline-user-requests/${id}`);
  };

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  console.log(data.length, "asdasd")
  return (
    <Fragment>
      <div className="container-fluid investorlist ">
        <div className="row invCallREquestList">

        <div className="row fltersrow">
          <div className="col-sm-2 filter">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Filters} alt="asd" /> Filter
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-sm-2 sortion">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={Sorts} alt="asd" /> Sort By Date
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="col-sm-1 plus">

            <p>+</p>
          </div>
        </div>

          {data?.items?.length > 0 ? (
            data?.items.map((item) => {
              return (
                <div
                  className="col-sm-3 invetrs invessssstor lists"
                  key={item.key}
                >
                  <div className="imagebox">
                    <img src={item.userCallDetails?.profile_pic} alt="asd" />

                    <div className="bgGreen sd">
                      <Swiper
                        pagination={{
                          type: 'fraction',
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination]}
                        autoplay={{ delay: 2000, disableOnInteraction: false, }}
                        className="mySwiper"
                      >
                        {item.userCallDetails?.userBusinessInfoData?.userInvestorGroups?.map(
                          (items) => {
                            return <SwiperSlide><p>{items.userInvestorGroupName?.groupName}</p> </SwiperSlide>;
                          }
                        )}
                      </Swiper>
                    </div>

                  </div>

                  <div className="row detailinvestor callList">
                    <div className="col-sm-7">
                      <h2>
                        {item.userCallDetails?.firstName}{" "}
                        {item.userCallDetails?.lastName}{" "}
                      </h2>
                    </div>
                    <div className="col-sm-5">
                      <h4>
                        ${" "}
                        {
                          item.userCallDetails?.userBusinessInfoData
                            ?.InvestmentRaised
                        }
                      </h4>
                    </div>
                    <div className="col-sm-12 profdetails">
                      <h3>
                        <img src={Round} alt="as" /> Round{" "}
                        {item.userCallDetails?.userBusinessInfoData?.Round}
                      </h3>

                      <div className="col-sm-12">
                        {" "}
                        <p>
                          Business Name:
                          <span>
                            {item.userCallDetails?.userInfo?.businessName}
                          </span>
                        </p>
                      </div>

                      <div className="col-sm-12 linkP">
                        <p>
                          Website Link : <a
                            href={
                              item.userCallDetails?.userBusinessInfoData
                                ?.websiteLink
                            }
                            target="_blank"
                            className="weblinks"
                          >
                            {" "}
                            Click To Visit
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row playSection">
                    <div className="col-sm-8">
                      <button onClick={handleShow}>
                        <img src={CirclePlay} alt="" /> User Story
                      </button>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Body>
                          {/* <iframe
                          width="100%"
                          height="500"
                          src={item.userCallDetails?.userStoryData?.Video}
                        ></iframe> */}

                          <VideoPlayer link={item.userCallDetails?.userStoryData?.Video} />
                        </Modal.Body>
                        <Modal.Footer className="clareqwModal">
                          <button onClick={handleClose}>Close</button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className="col-sm-4">
                      <button
                        className="names"
                        style={{
                          color:
                            item.callIdDetails?.status === "pending" || "rejected"
                              ? "red"
                              : "green",
                        }}
                      >
                        {item.callIdDetails?.status}
                      </button>
                    </div>
                  </div>

                  <div className="row maps">
                    <div className="col-sm-3">
                      <img src={Maps} alt="asd" />
                    </div>
                    <div className="col-sm-9">
                      <p>{item.userCallDetails?.userInfo?.address}</p>
                    </div>
                  </div>
                  <div className="row btnSection investorCallRequest">
                    {item.callIdDetails?.status === "pending" ? (
                      <>
                        <div className="col-sm-6">
                          <button onClick={() => handleAcceptClick(item._id)}>
                            Accept
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <button
                            className="backBtn"
                            onClick={() => handleDeclineClick(item._id)}
                          >
                            Decline
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {item.callIdDetails?.status === "rejected" ? (
                          <div className="col-sm-12">
                        <button className="backBtn">Rejected</button>
                      </div>
                        ) : (
                          <div className="col-sm-12">
                        <button className="acceptedBtn">Accepted</button>
                      </div>
                        )}
                      
                      </>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="nodatas"><img src={Nodata} alt="asd"/> <h2>Sorry No Data Found</h2></div>
          )}
          <Paginations
            TotalData={data?.totalCount}
            handlePageChange={handlePageChange}
            currentPage={page}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Investorview;
