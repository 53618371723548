import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const interestGroupSlice = createSlice({
  name: "interestgroup",
  initialState,
  reducers: {
    postInterestGroupStart(state) {
      state.loading = true;
      state.error = null;
    },
    postInterestGroupSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      console.log(action.payload);
      state.error = null;
      
    },
    postInterestGroupFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    interestGroupStart(state) {
      state.loading = true;
      state.error = null;
    },

    interestGroupSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    interestGroupFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteInterestGroupStart(state) {
      state.loading = true;
      state.error = null;
    },

    deleteInterestGroupSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      console.log(action.payload, "<====eorror");
    },

    deleteInterestgroupFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    editinterestGroupStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editinterestGroupSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
    },

    editPackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  postInterestGroupStart,
  postInterestGroupSuccess,
  postInterestGroupFailure,
  deleteInterestGroupStart,
  deleteInterestGroupSuccess,
  deleteInterestgroupFailure,
  interestGroupStart,
  interestGroupSuccess,
  interestGroupFailure,
  editinterestGroupStart,
  editinterestGroupSuccess,
  editPackageFailure,
  clearError,
  clearMessage,
} = interestGroupSlice.actions;

export default interestGroupSlice.reducer;
