import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postUserPitchStart,
  postUserPitchSuccess,
  postUserPitchFailure,
} from "../Reducers/PostUserPitchDeckReducer";
import axios from "axios";

export const PostUserPitchDeck = createAsyncThunk(
  "postuserPitch",
  async (data, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };
      console.log(userToken, "<======token");
      thunkAPI.dispatch(postUserPitchStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/addPitchDeck`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(postUserPitchSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(postUserPitchFailure(error.response.data.message));
    }
  }
);
