import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editUserProfileStart,
  editUserProfileSuccess,
  editUserProfileFailure,
  deleteFileStart,
  deleteFileSuccess,
  deleteFileFailure,
} from "../Reducers/editUserProfile";
import axios from "axios";

export const EditUserProfile = createAsyncThunk(
  "editUserProfile",
  async (data, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      thunkAPI.dispatch(editUserProfileStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/user/edit-profile`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(editUserProfileSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(editUserProfileFailure(error.response.data.message));
    }
  }
);

export const deleteUserFile = createAsyncThunk(
  "editUserProfile",
  async ({ id }, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      thunkAPI.dispatch(deleteFileStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/user/deleteFile/${id}`,
        { headers: header }
      );

      thunkAPI.dispatch(deleteFileSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(deleteFileFailure(error.response.data.message));
    }
  }
);
