import React, { useEffect } from "react";
import { Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Bell from "../../../assets/bell.png";
import Avatar from "../../../assets/default.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOutSuccess } from "../../../Redux/Reducers/UserAuthReducer";
import { toast } from "react-toastify";
import { UserProfile } from "../../../Redux/Actions/UserProfile";

import { logOutSuccess as adminLogout } from "../../../Redux/Reducers/AdminAuthReducer";
import { logOutSuccess as investorLogout } from "../../../Redux/Reducers/InvestorAuthReducer";

const { Search } = Input;
function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.userProfile);

  const handleSearch = (value) => {
    // Handle search functionality here
    console.log("Search:", value);
  };
  useEffect(() => {
    dispatch(UserProfile());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logOutSuccess());
    dispatch(investorLogout());
    dispatch(adminLogout());
    navigate("/");
    toast.success("Logged Out Successfully");
  };

  const getActiveLinkText = () => {
    const lastPath = location.pathname.split("/").pop();
    const formattedText = lastPath
      ? lastPath.replace(/-/g, " ")
      : "Subscription Package";

    // Check if a number exists in the URL
    const hasNumber = /\d/.test(lastPath);

    return hasNumber ? "Investor Detail" : formattedText;
  };

  return (
    <div className="col-sm-12 header">
      <div className="row headsser">
        <div className="col-sm-5">
          <h2>{getActiveLinkText()}</h2>
        </div>

        <div className="col-sm-5 headsser">
          {/* <Search
            placeholder="Search..."
            onSearch={handleSearch}
            style={{ marginBottom: "16px" }}
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          /> */}
        </div>

        <div className="col-sm-1 noti">
          {/* <img src={Bell} alt="Dropdown Icon" /> */}

          {/* <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item> */}
        </div>

        <div className="col-sm-1">
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <img
                className="headerImg"
                src={data.profile_pic ? data.profile_pic : Avatar}
                alt="Dropdown Icon"
              />
            }
          >
            <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>{" "}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}

export default Header;
