import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  investorCallingStart,
  investorCallingSuccess,
  investorCallingFailure,
} from "../Reducers/InvestorCallingReducer";
import axios from "axios";

export const InvestorCalling = createAsyncThunk(
  "scheduleCalls",
  async ({ id, data }, thunkAPI) => {
    try {
      thunkAPI.dispatch(investorCallingStart());
      const investorstoken = thunkAPI.getState().investorauth.investortoken;
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/InvestorCalltoUser/${id}`,
        data,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(investorCallingSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(investorCallingFailure(error.response.data.message));
    }
  }
);
