import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const UserProfileSlice = createSlice({
  name: "userprofile",
  initialState,
  reducers: {
    userProfleStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    userProfileSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.data.message;
    },

    userProfileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  userProfleStart,
  userProfileFailure,
  clearError,
  clearMessage,
  userProfileSuccess,
} = UserProfileSlice.actions;

export default UserProfileSlice.reducer;
