import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

function VideoPlayer({ link }) {
  const videoRef = useRef(null);

  console.log(link);
  useEffect(() => {
    const video = videoRef.current;

    if (link) {
      if (video) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(link);
          hls.attachMedia(video);
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
          video.src = link;
        }
      }
    }
  }, [link]);

  return (
    <div>
      <video
        style={{ width: "14vw", height: "20vh" }}
        ref={videoRef}
        controls
      />
    </div>
  );
}

export default VideoPlayer;
