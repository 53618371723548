import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  deleteMessage: null,
  data: {},
  token: null,
};

const EditUserProfile = createSlice({
  name: "editUserProfile",
  initialState,
  reducers: {
    editUserProfileStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editUserProfileSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },

    editUserProfileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    deleteFileStart(state) {
      state.error = null;
      state.deleteMessage = null;
    },

    deleteFileSuccess(state, action) {
      state.data = action.payload;
      state.deleteMessage = action.payload.message;
    },

    deleteFileFailure(state, action) {
      state.error = action.payload;
      state.deleteMessage = null;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
      state.deleteMessage = null;
    },
  },
});

export const {
  editUserProfileStart,
  editUserProfileSuccess,
  editUserProfileFailure,
  deleteFileStart,
  deleteFileSuccess,
  deleteFileFailure,
  clearError,
  clearMessage,
} = EditUserProfile.actions;

export default EditUserProfile.reducer;
