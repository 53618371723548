import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  InvestorShareFeedbackStart,
  InvestorShareFeedbackSuccess,
  InvestorShareFeedbackFailure,
} from "../Reducers/InvestorShareFeedbackReducer";
import axios from "axios";

export const InvestorShareFeedback = createAsyncThunk(
  "editinvestor",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(InvestorShareFeedbackStart());

      const investorstoken = thunkAPI.getState().investorauth.investortoken;

      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/investor/ShareFeedBack`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(InvestorShareFeedbackSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        InvestorShareFeedbackFailure(error.response.data.message)
      );
    }
  }
);
