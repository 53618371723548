import React, { Fragment, useEffect, useState, useRef } from "react";
import User from "../../../assets/user.png";
import Maps from "../../../assets/maps.png";
import { Investorsist } from "../../../Data/Data";
import { useDispatch, useSelector } from "react-redux";
import { GetInvestor } from "../../../Redux/Actions/GetInvestor";
import { CalltoInvestor } from "../../../Redux/Actions/CalltoInvestor";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/CalltoInvestorReducer";
import { GetInvestorAccInterest } from "../../../Redux/Actions/GetInvestorAccInterest";
import WarningModal from "../../Modals/WarningModel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Nodata from "../../../assets/nodata.jpg";
import Paginations from "../../Pagination";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/default.png";

function Investorview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };
  const [id, setId] = useState();
  const { data, loading } = useSelector(
    (state) => state.getInvestorAccInterest
  );

  const { error, message } = useSelector((state) => state.calltoInvestor);

  const handleWarn = async (id) => {
    await dispatch(CalltoInvestor(id));
    await dispatch(GetInvestorAccInterest());
  };
  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };
  const handleNavigate = (id) => {
    navigate(`/user/investor-detail/${id}`);
  };

  useEffect(() => {
    dispatch(GetInvestorAccInterest({ page, size }));
  }, [dispatch, page, size]);
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [message, error]);

  return (
    <Fragment>
      <div className="container-fluid investorlist">
        <div className="row investorLissst">
          {data?.items?.length > 0 ? (
            data?.items?.map((data) => {
              return (
                <div className="col-sm-3 invetrs" key={data.key}>
                  <div className="imagebox">
                    <img
                      src={
                        data.investorRelationToBusinessInfo?.image
                          ? data.investorRelationToBusinessInfo?.image
                          : Avatar
                      }
                      alt="asd"
                    />
                    {/* <p>{data.industry}</p> */}
                    <div className="row scheduleCallsSwiper">
                      <Swiper
                        pagination={{
                          type: "fraction",
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination]}
                        autoplay={{ delay: 2000, disableOnInteraction: false }}
                        className="mySwiper"
                      >
                        {data?.investorBusinessInfoData?.map((item, i) => (
                          <SwiperSlide>
                            <p>{item?.investorInvestorGroupName?.groupName}</p>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>

                  <div className="row detailinvestor">
                    <div className="col-sm-7 investorNamee">
                      <h2>
                        {data?.investorRelationToBusinessInfo?.firstName +
                          " " +
                          data?.investorRelationToBusinessInfo?.lastName}
                      </h2>
                      {/* <h3>By Emma Stone</h3> */}
                    </div>
                    <div className="col-sm-5 investorNamee">
                      <h4>{data.noCompaniesInvestedIn} Investments</h4>
                    </div>
                  </div>

                  <div className="text">
                    <p>{data?.bio}</p>
                  </div>

                  <div className="row maps invDetestore">
                    <div className="col-sm-3">
                      <img src={Maps} alt="asd" />
                    </div>
                    <div className="col-sm-9">
                      <p>
                        {data?.investorRelationToBusinessInfo?.invesotrinfo
                          ?.investorCity?.name +
                          ", " +
                          data?.investorRelationToBusinessInfo?.invesotrinfo
                            ?.investorCity?.StateData?.CountryDetail?.name}
                      </p>
                    </div>
                  </div>

                  {/* {!data?.investorRelationToBusinessInfo?.RequestedCallId ? (
                    <button
                      // onClick={() =>
                      //   handleRequest(data?.investorRelationToBusinessInfo?._id)
                      // }
                      onClick={() =>
                        handleModal(data.investorRelationToBusinessInfo?._id)
                      }
                    >
                      Request a Call
                    </button>
                  ) : (
                    <button disabled className="disabledeqButton">
                      Requested
                    </button>
                  )} */}
                  <button onClick={() => handleNavigate(data?.InvestorId)}>
                    View Detail
                  </button>
                </div>
              );
            })
          ) : (
            <div className="nodatas">
              <img src={Nodata} alt="asd" /> <h2>Sorry No Data Found</h2>
            </div>
          )}
        </div>
        <Paginations
          TotalData={data?.totalCount}
          handlePageChange={handlePageChange}
          currentPage={page}
          onShowSizeChange={onShowSizeChange}
        />
        <WarningModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleWarn={handleWarn}
          id={id}
        />
      </div>
    </Fragment>
  );
}

export default Investorview;
