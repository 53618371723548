import React, { Fragment } from "react";
import VideoPlayer from "../Profile/VideoPlayer";

function RightSecD({data}) {
 
  if (!data) {
    return <div>Data is not available.</div>;
  }

  const videoId = data.userCallDetails?.userStoryData?.Video;

  console.log (data, "videoasdasdasd")

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 rightvideo">
          {/* <iframe
            title="YouTube Video"
            width="100%"
            height="600"
            style={{ borderRadius: "20px" }}
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          /> */}
          <VideoPlayer link={videoId} />
        </div>
      </div>
    </Fragment>
  );
}

export default RightSecD;


