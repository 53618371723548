import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    GetInvCallReqStart,
    GetInvCallReqSuccess,
    GetInvCallReqFailure,
} from "../Reducers/GetCallReqInvReducer";
import axios from "axios";

export const GetCallReqInvs = createAsyncThunk(
  "getcallreqinv",
  async (_, thunkAPI) => {
    try {

      thunkAPI.dispatch(GetInvCallReqStart());
      const investorstoken= thunkAPI.getState().investorauth.investortoken
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

      
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/myCalls?size=50`,
        { headers: header }
      );


      thunkAPI.dispatch(GetInvCallReqSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(GetInvCallReqFailure(error.response.data.message));
    }
  }
);
