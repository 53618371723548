import React, { Fragment, useEffect } from "react";
import { Button, Form, Input, Upload, Select, Steps, Tooltip } from "antd";
import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserSignUp } from "../../../Redux/Actions/UserAuth";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/UserAuthReducer";
import Loader from "../../Loader";
import { GetCountry } from "../../../Redux/Actions/GetCountry";
import { GetCity } from "../../../Redux/Actions/GetCity";

function SignupForm() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Option } = Select;

  const { error, message, loading } = useSelector((state) => state.userAuth);
  const { data } = useSelector((state) => state.country);
  const cityData = useSelector((state) => state.city.data);
  console.log(data, "data,dsasa");
  const validatePassword = (_, value) => {
    if (value && value.length < 6) {
      return Promise.reject("Password must be at least 6 characters long.");
    }
    return Promise.resolve();
  };
  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onFinish = (values) => {
    const formData = new FormData();

    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("LinkedInName", values.LinkedInName);
    formData.append("cityId", values.city);
    formData.append("country", values.country);
    formData.append("address", values.address);
    formData.append("businessName", values.businessName);
    formData.append("pnum", parseFloat(values.pnum));
    formData.append("password", values.password);

    if (values.profile_pic && values.profile_pic[0]?.originFileObj) {
      formData.append("image", values.profile_pic[0]?.originFileObj);
    }

    dispatch(UserSignUp(formData));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  const handleChange = (e) => {
    dispatch(GetCity(e));
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/user/login");
      form.resetFields();
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 signupform">
          {/* <Steps  size="small"  current={0}  items={[  { title: 'Signup', }, { title: 'Story', }, {  title: 'PitchDeck', },{  title: 'Business Detail', }, ]}  /> */}

          <h2>Signup </h2>
          <p>Welcome back! please enter your detail</p>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row usersignup">
              <div className="col-sm-6">
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item name={"email"} rules={[{ type: "email" }]}>
                  <Input placeholder="E-mail" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="LinkedInName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Linked In Url!",
                    },
                  ]}
                >
                  <Input placeholder="Linked In URL" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your Country!",
                    },
                  ]}
                >
                  {/* <Input placeholder="Country" /> */}
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country"
                    onChange={(e) => handleChange(e)}
                  >
                    {data?.map((e) => (
                      <Option value={e._id}>{e.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please input your City!",
                    },
                  ]}
                >
                  <Select
                    placeholder="City"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleChange(e)}
                  >
                    {cityData?.map((e) => (
                      <Option value={e._id}>{e.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-sm-6">
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Address" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="businessName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Business Name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="pnum"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]+$/,
                      message:
                        "Please enter a valid phone number with no alphabets",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="profile_pic"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,

                      message: "Profile Picture is Required",
                    },
                  ]}
                >
                  <Upload
                    name="logo"
                    beforeUpload={() => false}
                    action="/upload.do"
                    listType="picture"
                    accept={allowedImageTypes.join(",")}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Profile Picture</Button>
                    <Tooltip title="Select image for your profile"></Tooltip>
                  </Upload>
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    { validator: validatePassword },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name="confirmpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("The two passwords do not match");
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {loading ? <Loader /> : "Sign Up"}
                  </Button>

                  <p className="ques">
                    Already have an account{" "}
                    <Link to="/user/login">Sign In</Link>
                  </p>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default SignupForm;
