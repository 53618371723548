import React, { Fragment } from "react";

function RightSec() {
  const videoId =
    "https://www.youtube.com/embed/ScDKs8m3CdU?si=neQoIIH00CBs5plC";

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 rightvideo">
          <iframe
            title="YouTube Video"
            width="100%"
            height="600"
            style={{ borderRadius: "20px" }}
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </Fragment>
  );
}

export default RightSec;
