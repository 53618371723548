import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postUserStart,
  postUserSuccess,
  postUserFailure,
  editUserStart,
  editUserSuccess,
  editUserFailure,
  userStart,
  userSuccess,
  userFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  changeStatusUserStart,
  changeStatusUserFailure,
  changeStatusUserSuccess,
  singleUserStart,
  singleUserSuccess,
  singleUserFailure,
  adminSingleUserStart,
  adminSingleUserSuccess,
  adminSingleUserFailure,
  adminEditUserStart,adminEditUserSuccess,
  adminEditUserFailure
} from "../Reducers/UserReducer";
import axios from "axios";

export const Postuser = createAsyncThunk("user", async (data, thunkAPI) => {
  try {
    const adminAuth = thunkAPI.getState().adminAuth.adminToken;

    console.log(adminAuth, "<====adminAuth");
    const header = {
      Authorization: `Bearer ${adminAuth}`,
    };

    console.log(adminAuth, "states");
    thunkAPI.dispatch(postUserStart());

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/addInvestorGroup`,
      data,
      { headers: header }
    );

    thunkAPI.dispatch(postUserSuccess(response.data));
  } catch (error) {
    thunkAPI.dispatch(postUserFailure(error.response.data.message));
  }
});

export const Edituser = createAsyncThunk(
  "user",
  async ({ id, values }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      console.log(adminAuth, "<====adminAuth");
      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      thunkAPI.dispatch(editUserStart());

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/editInvestorGroupName/${id}`,
        values,
        { headers: header }
      );

      thunkAPI.dispatch(editUserSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(editUserFailure(error.response.data.message));
    }
  }
);

export const Getuser = createAsyncThunk(
  "user",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(userStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/AllUser`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(userSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(userFailure(error.response.data.message));
    }
  }
);

export const Deleteuser = createAsyncThunk("user", async ({ id }, thunkAPI) => {
  try {
    const adminAuth = thunkAPI.getState().adminAuth.adminToken;

    const header = {
      Authorization: `Bearer ${adminAuth}`,
    };
    thunkAPI.dispatch(deleteUserStart());

    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}admin/delteUser/${id}`,
      {
        headers: header,
      }
    );

    thunkAPI.dispatch(deleteUserSuccess(response.data));
  } catch (error) {
    thunkAPI.dispatch(deleteUserFailure(error.response.data.message));
  }
});

export const changeStatus = createAsyncThunk(
  "user",
  async ({ data }, thunkAPI) => {
    try {
      thunkAPI.dispatch(changeStatusUserStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      console.log(adminAuth, "<====adminAuth");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/activateDeactivateUser`,
        data,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(changeStatusUserSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(changeStatusUserFailure(error.response.data.message));
    }
  }
);

export const SingleUser = createAsyncThunk("user", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(singleUserStart());
    const userToken = thunkAPI.getState().userAuth.userToken;

    const header = {
      Authorization: `Bearer ${userToken}`,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}user/editProfileData`,
      {
        headers: header,
      }
    );

    thunkAPI.dispatch(singleUserSuccess(response.data));
  } catch (error) {
    thunkAPI.dispatch(singleUserFailure(error.response.data.message));
  }
});

export const SingleAdminUser = createAsyncThunk(
  "user",
  async ({ userId }, thunkAPI) => {
    try {
      console.log(userId, "userId");
      thunkAPI.dispatch(adminSingleUserStart());
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/editProfileData/${userId}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(adminSingleUserSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(adminSingleUserFailure(error.response.data.message));
    }
  }
);

export const EditAdminUser = createAsyncThunk(
  "user",
  async ({ userId, formData }, thunkAPI) => {
    try {
      // console.log(userId, "userId");
      thunkAPI.dispatch(adminEditUserStart());
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      // console.log(formData, "dsadsa");
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/editProfile/${userId}`,
        formData,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(adminEditUserSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(adminEditUserFailure(error.response.data.message));
    }
  }
);
