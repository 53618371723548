import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allCallStart,
  allCallSuccess,
  allCallFailure,
} from "../Reducers/AllCallListReducer";
import axios from "axios";

export const AllCallsListing = createAsyncThunk(
  "allCalls",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(allCallStart());
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/allCalls`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(allCallSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(allCallFailure(error.response.data.message));
    }
  }
);
