import React from "react";
import { Link } from "react-router-dom";
import Linkdin from "../../../assets/LinkedIn.png";
const UserLinkedin = () => {
  const ClickHandler = () => {
    window.location.href = `https://server.youpitchlive.com/auth/linkedin/user`;
  };

  return (
    <Link to="" className="authlog" onClick={ClickHandler}>
      <img src={Linkdin} alt="" />
      Continue with LinkedIn
    </Link>
  );
};

export default UserLinkedin;
