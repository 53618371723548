import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editInvestorStart,
  editInvestorSuccess,
  editInvestorFailure,
  singleInvestorStart,
  singleInvestorSuccess,
  singleInvestorFailure,
} from "../Reducers/EditInvestorReducer";
import axios from "axios";

export const EditInvestor = createAsyncThunk(
  "editinvestor",
  async ({ id, formData }, thunkAPI) => {
    try {
      thunkAPI.dispatch(editInvestorStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/updateInvestor/${id}`,
        formData,
        { headers: header }
      );

      thunkAPI.dispatch(editInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(editInvestorFailure(error.response.data.message));
    }
  }
);

export const singleInvestor = createAsyncThunk(
  "editinvestor",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(singleInvestorStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/singleInvestor/${id}`
      );

      thunkAPI.dispatch(singleInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(singleInvestorFailure(error.response.data.message));
    }
  }
);
