import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  investorUserListingStart,
  investoreUserListingCallSuccess,
  investorUserLisitngFailure,
  investorUserSingleStart,
  investoreUserSingleCallSuccess,
  investorUserSingleFailure,
} from "../Reducers/InvestorUserListingReducer";
import axios from "axios";

export const InvestorUserListing = createAsyncThunk(
  "investoruserlisting",
  async ({page, size}, thunkAPI) => {
    try {
      const investorstoken = thunkAPI.getState().investorauth.investortoken;
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };
      thunkAPI.dispatch(investorUserListingStart());
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/userListing`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(investoreUserListingCallSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(
        investorUserLisitngFailure(error.response.data.message)
      );
    }
  }
);

export const InvestorSingleUser = createAsyncThunk(
  "investoruserlisting",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(investorUserSingleStart());

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/profile/${id}`
      );

      thunkAPI.dispatch(investoreUserSingleCallSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(investorUserSingleFailure(error.response.data.message));
    }
  }
);
