import React, { Fragment } from 'react'
import Home from '../Componenets/Home'

function Homepage() {
    return (
        <Fragment>
            <Home />
        </Fragment>
    )
}

export default Homepage