import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
  data: [],
};

const packageSlice = createSlice({
  name: "postpackage",
  initialState,
  reducers: {
    packagestart(state) {
      state.loading = true;
      state.error = null;
    },

    packagesuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    PackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deletePackageStart(state) {
      state.loading = true;
      state.error = null;
    },

    deletePackageSuccess(state, action) {
      state.loading = false;
    },

    deletePackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    postPackageStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    postPackageSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
    },

    postPackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    editPackageStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    editPackageSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
    },

    editPackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    userPackagestart(state) {
      state.loading = true;
      state.error = null;
    },

    userPackagesuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.items;
    },

    userPackageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  postPackageStart,
  postPackageSuccess,
  postPackageFailure,
  deletePackageStart,
  deletePackageSuccess,
  deletePackageFailure,
  packagestart,
  packagesuccess,
  PackageFailure,
  editPackageStart,
  editPackageSuccess,
  editPackageFailure,
  userPackagestart,
  userPackagesuccess,
  userPackageFailure,
  clearError,
  clearMessage,
} = packageSlice.actions;

export default packageSlice.reducer;
