import { Fragment, useEffect } from "react";
import { Button, Form, Input, Steps, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Sample from "../../../assets/sample.png";
import { useDispatch, useSelector } from "react-redux";
import { PostUserPitchDeck } from "../../../Redux/Actions/PostUserPitchDeck";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/PostUserPitchDeckReducer";
import Loader from "../../Loader";
import Pitchdeck from "../../../assets/airbnb-original-deck.pdf";
import { verify } from "../../../Redux/Actions/verify";
import Cookies from "js-cookie";

function PitchDekForm() {
  const verifyData = useSelector((state) => state.verify.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
  ];

  const { data, error, message, loading } = useSelector(
    (state) => state.postUserPitch
  );
  const userData = useSelector((state) => state.verify.data);

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("description", values.description);

    if (values.image && values.image.length > 0) {
      // Append the first file in the fileList (assuming single file upload)
      formData.append("image", values.image[0].originFileObj);
    }

    await dispatch(PostUserPitchDeck(formData));
    const token = Cookies.get("token");
    dispatch(verify(token));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/user/business-details");
      dispatch(clearMessage());
    }
  }, [error, message]);
  useEffect(() => {
    if (verifyData?.user && verifyData.user?.userPitchDeckDetail) {
      navigate("/user/business-details");
    }
  }, [verifyData]);

  return (
    <Fragment>
      <div className="row storyUSer">
        <div className="col-sm-12 recordform">
          <Steps
            size="small"
            current={1}
            items={[
              { title: "Story" },
              { title: "PitchDeck" },
              { title: "Business Detail" },
            ]}
          />
          <div className="row">
            <div className="col-sm-8">
              <h2>Upload Pitch Deck </h2>
              <p>Welcome back! please enter your detail</p>
            </div>
            <div className="col-sm-4">
              {/* <Link to="">
                <img src={Sample} alt="" onClick={() => {
                  window.open('../../../assets/airbnb-original-deck.pdf')
                }}/>
              </Link> */}
              <a
                href={Pitchdeck}
                target="_blank"
                onclick="window.open(this.href); return false;"
              >
                <img src={Sample} alt="Preview" />
              </a>
            </div>
          </div>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-sm-12">
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Pitch Deck  Title!",
                    },
                  ]}
                >
                  <Input placeholder="Pitch Deck Title" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Description!",
                    },
                  ]}
                >
                  <Input placeholder="Description" />
                </Form.Item>
              </div>

              <div className="col-sm-12 uploadbtn pitch">
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    beforeUpload={() => false}
                    name="logo"
                    action="/upload.do"
                    listType="picture"
                    accept={allowedFileTypes.join(",")}
                  >
                    <Button icon={<UploadOutlined />}>Upload PDF</Button>
                  </Upload>
                </Form.Item>
              </div>

              <div className="col-sm-12 pitchdeckbtn">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="recordbtn"
                  >
                    {loading ? <Loader /> : "Submit"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default PitchDekForm;
