import { combineReducers } from "redux";
import UserAuth from "./Reducers/UserAuthReducer";
import InvestorAuthReducer from "./Reducers/InvestorAuthReducer";
import GetInvestorGroupReducers from "./Reducers/GetInvestorGroupReducers";
import InvestorBusinessPostReducer from "./Reducers/InvestorBusinessPostReducer";
import UserProfile from "./Reducers/UserProfileReducer";
import UserBusinessInfo from "./Reducers/UserBusinessInfoReducer";
import GetInvestorReducer from "./Reducers/GetInvestorReducer";
import postUserStory from "./Reducers/PostUserStoryReducer";
import InvestorSignup from "./Reducers/InvestorSignupReducer";
import CalltoInvestorReducer from "./Reducers/CalltoInvestorReducer";
import UserAddInfo from "./Reducers/UserAddInfoReducer";
import PostUserPitchDeck from "./Reducers/PostUserPitchDeckReducer";
import UserCalls from "./Reducers/UserCallsReducer";
import ScheduleCallsInvestor from "./Reducers/GetScheduleCallsInvestor";
import InvestorAddInfo from "./Reducers/InvestorAddInfoReducer";
import GetCountry from "./Reducers/GetCountryReducer";
import GetCity from "./Reducers/GetCityReducer";
import GetInvProfileReducer from "./Reducers/GetInvProfileReducer";
import GetInvCallReqReducer from "./Reducers/GetCallReqInvReducer";
import SingleUserCallReducer from "./Reducers/SingleUserCallReducer";
import AdminAuth from "./Reducers/AdminAuthReducer";
import PackageReducer from "./Reducers/PackageReducer";
import InterestGroup from "./Reducers/InterestGroupReducer";
import PostInvDateReducer from "./Reducers/PostInvDateReducer";
import User from "./Reducers/UserReducer";
import verify from "./Reducers/verifyReducer";
import GetInvestorAccInterestReducer from "./Reducers/GetInvestorAccInterestReducer";
import DateApproval from "./Reducers/DateApprovalReducer";
import AllCallList from "./Reducers/AllCallListReducer";
import editUserProfile from "./Reducers/editUserProfile";
import EditInvestorProfile from "./Reducers/EditInvestorProfile";
import AdminCallDetail from "./Reducers/AdminCallDetail";
import AdminShareMeeting from "./Reducers/AdminShareMeeting";
import ScheduleCalls from "./Reducers/ScheduleCalls";
import UserBuyPackage from "./Reducers/UserBuyPackageReducer";
import PostAdminInvestor from "./Reducers/PostAdminInvestor";
import EditInvestor from "./Reducers/EditInvestorReducer";
import BuyPackage from "./Reducers/BuyPackageReducer";
import InvestorReschedule from "./Reducers/InvestorReschedule";
import InvestorShareFeedback from "./Reducers/InvestorShareFeedbackReducer";
import InvestorUserListing from "./Reducers/InvestorUserListingReducer";
import UserRescheduleCard from "./Reducers/UserRescheduleCard";
import InvestorCalling from "./Reducers/InvestorCallingReducer";
import UserPassword from "./Reducers/UserPasswordReducer";
import InvestorPassword from "./Reducers/InvestorPasswordReducer";
import InvestorUserDetail from "./Reducers/InvestorUserDetail";
import ContactReducer from "./Reducers/ContactReducer";
import NewsLetterReducer from "./Reducers/NewsLetterReducer";
import BussinessLeadsReducer from "./Reducers/BussinessLeadsReducer";

const rootReducer = combineReducers({
  userAuth: UserAuth,
  investorauth: InvestorAuthReducer,
  getinvestorgroup: GetInvestorGroupReducers,
  investorbusiness: InvestorBusinessPostReducer,
  packages: PackageReducer,
  userProfile: UserProfile,
  userBusinessInfo: UserBusinessInfo,
  GetInvestor: GetInvestorReducer,
  postUserStory: postUserStory,
  investorsignup: InvestorSignup,
  userAddInfo: UserAddInfo,
  calltoInvestor: CalltoInvestorReducer,
  postUserPitch: PostUserPitchDeck,
  userCalls: UserCalls,
  schedulecallsinvestor: ScheduleCallsInvestor,
  investorAddInfo: InvestorAddInfo,
  country: GetCountry,
  city: GetCity,
  getinvestorprofile: GetInvProfileReducer,
  getinvcallreq: GetInvCallReqReducer,
  singleusercall: SingleUserCallReducer,
  adminAuth: AdminAuth,
  interestGroup: InterestGroup,
  investordates: PostInvDateReducer,
  user: User,
  verify: verify,
  getInvestorAccInterest: GetInvestorAccInterestReducer,
  dateApproval: DateApproval,
  allCalls: AllCallList,
  editUserProfile: editUserProfile,
  editInvestorProfile: EditInvestorProfile,
  adminCallDetail: AdminCallDetail,
  adminShareMeeting: AdminShareMeeting,
  scheduleCalListing: ScheduleCalls,
  buyPackage: UserBuyPackage,
  postadmininvestor: PostAdminInvestor,
  editInvestorAdmin: EditInvestor,
  buyPackages: BuyPackage,
  InvestorReschedule: InvestorReschedule,
  InvestorShareFeedback: InvestorShareFeedback,
  InvestorUserListing: InvestorUserListing,
  userreschedulerard: UserRescheduleCard,
  InvestorCalling: InvestorCalling,
  userPassword: UserPassword,
  investorPassword: InvestorPassword,
  InvestorUserDetail: InvestorUserDetail,
  contact: ContactReducer,
  newsletter: NewsLetterReducer,
  bussinessLeads: BussinessLeadsReducer,
});
export default rootReducer;