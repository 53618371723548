
import React from "react";
import InvestorSidebar from "../../Componenets/Investor/Sidebar/InvestorSidebar";
import InvestorHeader from "../../Componenets/Investor/Header/InvestorHeader";
import DeclinePreview from "../../Componenets/Investor/CallDecline/DeclinePreview";

function DeclineCallRequest() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <InvestorSidebar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <InvestorHeader />
          <DeclinePreview />
        </div>
      </div>
    </div>
  );
}

export default DeclineCallRequest;
