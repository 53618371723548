import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import edit from "../../../assets/Pen Squared.png";
import calendars from "../../../assets/calendar.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllCallsListing } from "../../../Redux/Actions/allCallList";
import moment from "moment";
import Pagination from "../../Pagination";

function AdminCallRequestList() {
  const { data } = useSelector((state) => state.allCalls);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const handleEdit = () => {
    console.log("Edit");
  };
  const handleDelete = () => {
    console.log("Delete");
  };

  const handleRedirect = (id) => {
    navigate("/admin/user-call-request", {
      state: id,
    });
  };

  useEffect(() => {
    dispatch(AllCallsListing({ page, size }));
  }, [dispatch, page, size]);
  console.log(data, "<=====data");

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="mobresponnTable schdcalss">
          <table className="adminSubsPackageTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow">
                <th className="admCallName">Investor Name</th>
                <th className="admCallEmail">Username</th>
                <th className="admCallInv">Investment Stage</th>
                <th className="admCallInt">Interest Group</th>

                <th className="admCallInt">Call Request Date</th>
                <th className="admCallInt">Status</th>
                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow" key={i}>
                  <td className="admCallName">
                    {value?.callIdDetails?.investorCallDetails?.firstName +
                      " " +
                      value?.callIdDetails?.investorCallDetails?.lastName}
                  </td>
                  <td className="admCallEmail">
                    {" "}
                    {value?.callIdDetails?.userCallDetails?.firstName +
                      " " +
                      value?.callIdDetails?.userCallDetails?.lastName}
                  </td>
                  <td className="admCallInv">
                    {
                      value?.callIdDetails?.userCallDetails
                        ?.userBusinessInfoData?.Round
                    }
                  </td>

                  <td className="invAdmInt">
                    <div className="heightss">
                      {value?.callIdDetails?.investorCallDetails?.investorRelationToBusinessInfo?.investorBusinessInfoData?.map(
                        (item, e) => (
                          <> {item?.investorInvestorGroupName?.groupName} <br /> </>
                        )
                      )}
                    </div>
                  </td>

                  <td className="invAdmInt">
                    <div className="heightss">
                      {value?.sceduleDatesDetails?.map((elem) => (
                        <div className="callDate">
                          <img src={calendars} />
                          <>
                            {moment(elem.SceduledDate).format(
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="statusAdminListCall">{value?.status}</td>
                  <td
                    className="actionIcons"
                    onClick={() => handleRedirect(value.callIdDetails._id)}
                  >
                    {/* <Link to="/admin/user-call-request"> */}
                    <img
                      src={edit}
                      className="actionIconsAdm"
                      onClick={handleEdit}
                    />
                    {/* </Link> */}
                    <img
                      src={trash}
                      className="actionIconsAdm"
                      onClick={handleDelete}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
    </div>
  );
}

export default AdminCallRequestList;
