import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/NewsLetterReducer";
import DeleteModal from "../../Modals/DeleteModal";
import Pagination from "../../Pagination";
import {
  DeleteNewsLetter,
  GetNewsLetter,
} from "../../../Redux/Actions/NewsLetter";

function NewsLetterListing() {
  const dispatch = useDispatch();

  const { data, message, error, loading } = useSelector(
    (state) => state.newsletter
  );
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);

  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const handleDelete = async (id) => {
    console.log("Delete", id);

    await dispatch(DeleteNewsLetter({ id }));
    // dispatch(Getuser());
    await dispatch(GetNewsLetter({ page, size }));
  };
  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    dispatch(GetNewsLetter({ page, size }));
  }, [dispatch, page, size]);

  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="mobresponnTable schdcalss">
          <table className="adminSubsPackageTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow">
                <th className="userAdmInv">Email</th>

                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow" key={i}>
                  <td className="userAdmEmail">{value?.email}</td>

                  <td className="actionIcons">
                    <img
                      src={trash}
                      className="actionIconsAdm"
                      // onClick={() => handleDelete(value?._id)}
                      onClick={() => handleModal(value._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
      <DeleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleDelete={handleDelete}
        id={id}
      />
    </div>
  );
}

export default NewsLetterListing;
