import React, { Fragment, useEffect, useState } from "react";
import Round from "../../../assets/round.png";
import Calender from "../../../assets/calendar.png";
import { Form, Input, Button, message, Radio } from "antd";
import { Calllist } from "../../../Data/Data";
import { useDispatch, useSelector } from "react-redux";
import { UserCalls } from "../../../Redux/Actions/UserCalls";
import moment from "moment/moment";
import { DateAprroval } from "../../../Redux/Actions/DateAprroval";
import { toast } from "react-toastify";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/DateApprovalReducer";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Nodata from "../../../assets/nodata.jpg"

const { TextArea } = Input;

function ScheduleCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.userCalls);
  const { message, error, loading } = useSelector(
    (state) => state.dateApproval
  );

  const [cardStates, setCardStates] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  const toggleForm = (cardKey) => {
    setCardStates((prevStates) => ({
      ...prevStates,
      [cardKey]: {
        showForm: !prevStates[cardKey]?.showForm,
        formSubmitted: false,
      },
    }));
  };
  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedId(selectedValue);
  };
  console.log(selectedId, "id");
  const onFinish = (values, cardKey) => {
    console.log("Form values:", values);

    setCardStates((prevStates) => ({
      ...prevStates,
      [cardKey]: {
        showForm: false,
        formSubmitted: true,
      },
    }));
  };
  const handleNavigate = (id) => {
    navigate(`/user/reschedule/${id}`);
  };

  const handleDates = async () => {
    dispatch(DateAprroval(selectedId));
    setSelectedId("");
    await dispatch(UserCalls());
  };

  const checkStatus = (status) => {
    return status === "pending";
  };

  useEffect(() => {
    dispatch(UserCalls());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [message, error]);

  console.log(data, "user/schedule-calls");

  return (
    <Fragment>
      <div className="container-fluid callscontainer">
        <div className="row schedulerow scheduledROwww">

          {data?.length > 0 ? (
            data.map((data) => {
              const cardKey = data.key;
              const cardState = cardStates[cardKey] || {
                showForm: false,
                formSubmitted: false,
              };

              return (
                <div className="col-sm-3 schedulecalls" key={cardKey}>
                  <div className="myschedules">
                    <h2>
                      {data?.investorCallDetails?.firstName +
                        " " +
                        data?.investorCallDetails?.lastName}
                    </h2>
                    
                    {/* <p>{data.business}</p> */}
                    <div className="row scheduleCallsSwiper">
                      <Swiper
                        pagination={{
                          type: 'fraction',
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination]}
                        autoplay={{ delay: 2000, disableOnInteraction: false, }}
                        className="mySwiper"
                      >
                        {data?.investorCallDetails?.investorRelationToBusinessInfo?.investorBusinessInfoData?.map(
                          (elem) => (
                            <SwiperSlide><div className="col-sm-12">
                              <p>{elem?.investorInvestorGroupName?.groupName}</p>
                            </div></SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>

                    

                    <div className="row dats">
                      <div className="col-sm-12 selectsdate">
                        <h3 className="myselectdatsss">
                          Select One Date Please <span className="reds">*</span>
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={handleRadioChange}
                          >
                            <Swiper
                              pagination={{
                                type: 'fraction',
                              }}
                              navigation={true}
                              modules={[Pagination]}
                              className="mySwiper"
                            >
                              {data?.callIdDetails?.ApprovedSheduledDateId ? (
                                <p className="singledtes">
                                  <img src={Calender} alt="asd" />{" "}
                                  {moment(
                                    data?.callIdDetails?.ApprovedSheduledDate
                                  ).format("YYYY-MM-DD hh:mm:ss A")}
                                </p>
                              ) : (
                                data?.callIdDetails?.sceduleDatesDetails?.map(
                                  (item) => (
                                    <>

                                      <SwiperSlide>
                                        <Radio.Button value={item._id}>
                                          {" "}
                                          {moment(item?.SceduledDate).format(
                                            "YYYY-MM-DD hh:mm:ss A"
                                          )}
                                        </Radio.Button>
                                      </SwiperSlide>

                                    </>
                                  )
                                )
                              )}
                            </Swiper>
                          </Radio.Group>

                        </h3>
                      </div>

                      {/* <div className="col-sm-4">
                      <p className={checkStatus(data.status) ? "red" : "green"}>
                        {data?.callIdDetails?.status}
                      </p>
                    </div> */}
                    </div>
                    {data?.callIdDetails?.sceduleDatesDetails?.map((i) => (
                      <a href={i.meetingLink} target="_blank">
                        {i.meetingLink ? <h3>Click To Join</h3> : <></>}
                      </a>
                    ))}

                    {/* {cardState.showForm ? (
                    <Form
                      name={`your-form-${cardKey}`}
                      onFinish={(values) => onFinish(values, cardKey)}
                    >
                      <Form.Item
                        name="message"
                        rules={[
                          {
                            required: true,
                            message: "Please Write reason first",
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="Type your reason for reschedule"
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : cardState.formSubmitted ? (
                    <button className="reqsub"> Request submitted</button>
                  ) : (
                    <button
                      onClick={() => toggleForm(cardKey)}
                      className={
                        checkStatus(data.status) ? "pending" : "disable"
                      }
                    >
                      {checkStatus(data.status) ? "Reschedule" : "Completed"}
                    </button>
                  )} */}
                    {data?.callIdDetails?.ApprovedSheduledDateId ? (
                      <button onClick={() => handleNavigate(data?._id)}>
                        Reschedule
                      </button>
                    ) : data.callIdDetails.status === "pending" ? (
                      <button disabled style={{opacity:0.5}}>
                        Wait For Invester Response..
                      </button>
                    ) : data.callIdDetails.status === "rescheduled" ? (
                      <button className="resched">reschedule requested</button>
                    ) : data.callIdDetails.status === "rejected" ? (
                      <button>Rejected</button>
                    ) : (
                      <button onClick={handleDates}>
                        {loading ? <Loader /> : "Select Date And Submit"}
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="nodatas"><img src={Nodata} alt="asd"/> <h2>Sorry No Data Found</h2></div>
          
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default ScheduleCard;
