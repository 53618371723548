import React from "react";
import LoginSlide from "../../Componenets/User/Login/LoginSlide";
import ForgetPasswordForm from "../../Componenets/User/ForgetPassword/ForgetForm";

function ForgetPassword() {
  return (
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <ForgetPasswordForm />
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
