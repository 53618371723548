
import React, { Fragment, useState,useEffect } from 'react'
import Logo from "../../../assets/Logo.png"
import { Link, NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Icons from "../../../assets/iconimg.png"

function InvestorSidebar() {
  const [activeLink, setActiveLink] = useState("Profie");

  const handleLinkClick = async (link) => {
    await setActiveLink(link);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <div className="col-sm-12 sidelogo">
        <img src={Logo} alt="" />
      </div>

      {/* menu for desktop */}
      <div className="col-sm-12 desktop">
        <ul>
          {/* <li>
            <NavLink
              to="/investor/business-info"
              onClick={() => handleLinkClick("Dashboard")}
              className={activeLink === "Dashboard" ? "active" : ""}
            >
              Dashboard
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to="/investor/schedule-calls"
              onClick={() => handleLinkClick("Schedule Calls")}
              className={activeLink === "Schedule Calls" ? "active" : ""}
              activeClassName="active"
            >
              Schedule Calls
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to="/investor/reschedule-request"
              onClick={() => handleLinkClick("ReSchedule Calls")}
              className={activeLink === "ReSchedule Calls" ? "active" : ""}
              activeClassName="active"
            >
              Reschedule Requests
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink
              to="/investor/call-request"
              onClick={() => handleLinkClick("User Call Request")}
              className={activeLink === "User Call Request" ? "active" : ""}
            >
              User Call Request
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/investor/my-profile"
              onClick={() => handleLinkClick("Profile")}
              // className={activeLink === "Profile" ? "active" : ""}
            >
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/investor/venture"
              onClick={() => handleLinkClick("user")}
              // className={activeLink === "user" ? "active" : ""}
            >
              Venture
            </NavLink>
          </li>
        </ul>
      </div>

      {/* menu for mobile */}

      <div className="col-sm-12 mobile">
        <Button variant="primary" onClick={handleShow}>
          <img src={Icons} alt="asd" />
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <img src={Logo} alt="" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              {/* <li>
                <NavLink
                  to="/investor/business-info"
                  onClick={() => handleLinkClick("Dashboard")}
                  className={activeLink === "Dashboard" ? "active" : ""}
                >
                  Dashboard
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to="/investor/schedule-calls"
                  onClick={() => handleLinkClick("Schedule Calls")}
                  className={activeLink === "Schedule Calls" ? "active" : ""}
                >
                  Schedule Calls
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to="/investor/call-request"
                  onClick={() => handleLinkClick("User Call Request")}
                  className={activeLink === "User Call Request" ? "active" : ""}
                >
                  User Call Request
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/investor/my-profile"
                  onClick={() => handleLinkClick("Profile")}
                  className={activeLink === "Profile" ? "active" : ""}
                >
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/investor/venture"
                  onClick={() => handleLinkClick("user")}
                  className={activeLink === "user" ? "active" : ""}
                >
                  Venture
                </NavLink>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Fragment>
  );
}

export default InvestorSidebar