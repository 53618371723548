import React, { useEffect } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Bell from "../../../assets/bell.png";
import Avatar from "../../../assets/default.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutSuccess } from "../../../Redux/Reducers/InvestorAuthReducer";

import { logOutSuccess as userLogout } from "../../../Redux/Reducers/UserAuthReducer";
import { logOutSuccess as adminLogout } from "../../../Redux/Reducers/AdminAuthReducer";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GetInvestorProfile } from "../../../Redux/Actions/GetInvProfile";

const { Search } = Input;

function InvestorHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearch = (value) => {
    // Handle search functionality here
    console.log("Search:", value);
  };
  const handleLogout = () => {
    dispatch(logOutSuccess());
    dispatch(adminLogout());
    dispatch(userLogout());

    navigate("/");
    toast.success("Log Out Successfully");
  };

  const getActiveLinkText = () => {
    const lastPath = location.pathname.split("/").pop();
    const formattedText = lastPath
      ? lastPath.replace(/-/g, " ")
      : "Subscription Package";

    // Check if a number exists in the URL
    const hasNumber = /\d/.test(lastPath);

    return hasNumber ? "Venture Detail" : formattedText;
  };

  const { data } = useSelector((state) => state.getinvestorprofile);
  useEffect(() => {
    dispatch(GetInvestorProfile());
  }, [dispatch]);

  return (
    <div className="col-sm-12 header">
      <div className="row headsser">
        <div className="col-sm-5">
          <h2>{getActiveLinkText()}</h2>
        </div>

        <div className="col-sm-5 headsser">
          {/* <Search
            placeholder="Search..."
            onSearch={handleSearch}
            style={{ marginBottom: "16px" }}
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          /> */}
        </div>

        <div className="col-sm-1 noti">
          {/* <img src={Bell} alt="Dropdown Icon" /> */}
        </div>

        <div className="col-sm-1 profimg">
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <img
                src={!data?.image ? data?.image : Avatar}
                alt="Dropdown Icon"
              />
            }
          >
            <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
            {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item> */}
            {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}

export default InvestorHeader;
