import React, { Fragment, useEffect, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import Round from "../../../assets/round.png";
import Map from "../../../assets/map.png";
import City from "../../../assets/city.png";
import Phone from "../../../assets/dail.png";
import Linkdin from "../../../assets/linkedInn.png";
import Mail from "../../../assets/mail.png";
import Chain from "../../../assets/link.png";

function RightSec({ data }) {
  const [videoState, setVideoState] = useState();

  useEffect(() => {
    setVideoState(data?.userStoryData?.Video);
  }, [data?.userStoryData?.Video]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 rightvideo">
          <div className="col-sm-12 profdetails">
            <h2>{data?.firstName + " " + data?.lastName}</h2>
            <h3>
              <img src={Round} alt="as" /> Round{" "}
              {data?.userBusinessInfoData?.Round}
            </h3>
            <p className="userbusiness">
              Business Name: <h4>{data?.userBusinessInfoData?.businessName}</h4>
            </p>

            <p className="userbusiness">
              Investment Required:{" "}
              <h4>{data?.userBusinessInfoData?.InvestmentRaised} $</h4>
            </p>

            <p className="">
              Story Description:{" "}
              <span className="mydescript">
                {data?.userStoryData?.description}
              </span>
            </p>

            <div className="row inner userProfileee">
              <div className="col-sm-4 personaldetail">
                <h2>Interest Group</h2>
                {data?.userBusinessInfoData?.userInvestorGroups?.map(
                  (item, index) => (
                    <div className="col-sm-12">
                      <p>
                        {index + 1}) {item?.userInvestorGroupName?.groupName}
                      </p>
                    </div>
                  )
                )}
              </div>

              <div className="col-sm-4 personaldetail">
                <h2> Details </h2>
                <p>
                  <img src={Map} alt="asd" /> {data?.userInfo?.city}{" "}
                  {data?.userInfo?.address}
                </p>
                <p>
                  <img src={City} alt="asd" />{" "}
                  {data?.userInfo?.userCity?.name +
                    ", " +
                    data?.userInfo?.userCity?.StateData?.CountryDetail?.name}
                </p>
                <p>
                  <img src={Phone} alt="asd" />
                  {data?.userInfo?.pnum}
                </p>
                <p>
                  <img src={Linkdin} alt="asd" />{" "}
                  {data?.userBusinessInfoData?.businessName}
                </p>
                <p>
                  <img src={Mail} alt="asd" />
                  {data?.email}
                </p>
                <p>
                  <img src={Chain} alt="asd" />
                  {data?.userBusinessInfoData?.websiteLink}
                </p>
              </div>

              <div className="col-sm-4 personaldetail">
                <h2>Number of Associated Partners </h2>

                {data?.userBusinessInfoData?.partnersInfo.map((item, index) => (
                  <p>
                    {index + 1}) {item?.nameOfPartners}
                  </p>
                ))}
              </div>
            </div>
          </div>

          {/* <h2>User Story</h2>
          {data ? <VideoPlayer link={videoState} /> : <></>} */}
        </div>
      </div>
    </Fragment>
  );
}

export default RightSec;
