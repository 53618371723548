import React, { Fragment, useState, useEffect } from 'react'
import Logo from "../../../assets/Logo.png"
import Icons from "../../../assets/iconimg.png"
import { Link, NavLink } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
 
function SideBar() {
    const [activeLink, setActiveLink] = useState("Profile");

    // useEffect(() => {
    //   // Retrieve the active link from localStorage on component mount
    //   const storedActiveLink = localStorage.getItem("activeLink");
    //   if (storedActiveLink) {
    //     setActiveLink(storedActiveLink);
    //   }
    // }, []);

    const handleLinkClick = (link) => {
      setActiveLink(link);
      // Save the active link to localStorage
    };
    console.log(activeLink, "<======activeLink");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <Fragment>
        <div className="col-sm-12 sidelogo">
          <img src={Logo} alt="" />
        </div>

        {/* menu for desktop */}
        <div className="col-sm-12 desktop">
          <ul>
            {/* <li><Link to="" onClick={() => handleLinkClick('Dashboard')} className={activeLink === 'Dashboard' ? 'active' : ''}>Dashboard</Link></li> */}
            {/* <li>
              <Link
                to="/user/subscription-package"
                onClick={() => handleLinkClick("Subscription Package")}
                className={
                  activeLink === "Subscription Package" ? "active" : ""
                }
              >
                Subscription Package
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="/user/schedule-calls"
                onClick={() => handleLinkClick("Schedule Calls")}
                className={activeLink === "Schedule Calls" ? "active" : ""}
              >
                Schedule Calls
              </Link>
            </li> */}
            <li>
              <NavLink
                to="/user/profile"
                onClick={() => handleLinkClick("Profile")}
                // className={activeLink == "Profile" ? "active" : ""}
                activeClassName="active"
              >
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/user/investor"
                onClick={() => handleLinkClick("Investor")}
                // className={activeLink === "Investor" ? "active" : ""}
                activeClassName="active"
              >
                Investor
              </NavLink>
            </li>
          </ul>
        </div>

        {/* menu for mobile */}

        <div className="col-sm-12 mobile">
          <Button variant="primary" onClick={handleShow}>
            <img src={Icons} alt="asd" />
          </Button>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <img src={Logo} alt="" />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul>
                {/* <li><Link to="" onClick={() => handleLinkClick('Dashboard')} className={activeLink === 'Dashboard' ? 'active' : ''}>Dashboard</Link></li> */}
                {/* <li>
                  <Link
                    to="/user/subscription-package"
                    onClick={() => handleLinkClick("Subscription Package")}
                    className={
                      activeLink === "Subscription Package" ? "active" : ""
                    }
                  >
                    Subscription Package
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to="/user/schedule-calls"
                    onClick={() => handleLinkClick("Schedule Calls")}
                    className={activeLink === "Schedule Calls" ? "active" : ""}
                  >
                    Schedule Calls
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/user/profile"
                    onClick={() => handleLinkClick("Profile")}
                    className={activeLink === "Profile" ? "active" : ""}
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  <Link
                    to="/user/investor"
                    onClick={() => handleLinkClick("Investor")}
                    className={activeLink === "Investor" ? "active" : ""}
                  >
                    Investor
                  </Link>
                </li>
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </Fragment>
    );
}

export default SideBar