import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  UserRescheduleCardStart,
  UserRescheduleCardSuccess,
  UserRescheduleCardFailure,
} from "../Reducers/UserRescheduleCard";
import axios from "axios";

export const UserReschedulePost = createAsyncThunk(
  "userreschedulecard",
  async ({id, data}, thunkAPI) => {
    try {
      const userToken= thunkAPI.getState().userAuth.userToken;
      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      thunkAPI.dispatch(UserRescheduleCardStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/UserReschedule/${id}`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(UserRescheduleCardSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(UserRescheduleCardFailure(error.response.data.message));
    }
  }
);
