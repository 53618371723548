import React from 'react'
import LoginSlide from '../../Componenets/User/Login/LoginSlide'
import LoginForm from '../../Componenets/User/Login/LoginForm'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

function Login() {
  return (
    <Fade right delay={100}>
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <LoginForm />
        </div>
      </div>
    </div>
    </Fade>
  );
} 

export default Login