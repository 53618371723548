import React from 'react'
import SideBar from '../../Componenets/User/Sidebar/SideBar'
import Header from '../../Componenets/User/Header/Header'
import EditForm from '../../Componenets/User/EditProfile/EditForm'

function EditProf() {
  return (
    <div className='container-fluid userdashboard'>
        <div className='row'>
            <div className='col-sm-3 sidebar'>
                <SideBar/>
            </div>
            

            <div className='col-sm-9 rightloginform bgdarks'>
            <Header/>
            <EditForm/>
            </div>
        </div>
    </div>
  )
} 

export default EditProf

