import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    SingleUserCallStart,
    SingleUserCallSuccess,
    SingleUserCallFailure,
} from "../Reducers/SingleUserCallReducer";
import axios from "axios";

export const SingleUserCalls = createAsyncThunk(
  "singleusercall",
  async ({id}, thunkAPI) => {
    try {

      thunkAPI.dispatch(SingleUserCallStart());
      const investorstoken= thunkAPI.getState().investorauth.investortoken
      const header = {
        Authorization: `Bearer ${investorstoken}`,
      };

      
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/investor/CallForMe/${id}` ,{ headers: header,  } 
      );


      thunkAPI.dispatch(SingleUserCallSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(SingleUserCallFailure(error.response.data.message));
    }
  }
);
