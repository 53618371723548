import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  singleData: {},
  token: null,
};

const InvestorUserListingSlice = createSlice({
  name: "investoruserlisting",
  initialState,
  reducers: {
    investorUserListingStart(state) {
      state.loading = true;
      state.error = null;
    },

    investoreUserListingCallSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    investorUserLisitngFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    investorUserSingleStart(state) {
      state.loading = true;
      state.error = null;
    },

    investoreUserSingleCallSuccess(state, action) {
      state.loading = false;
      state.singleData = action.payload.data;
    },

    investorUserSingleFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  investorUserSingleStart,
  investoreUserSingleCallSuccess,
  investorUserSingleFailure,
  investorUserListingStart,
  investoreUserListingCallSuccess,
  investorUserLisitngFailure,
  clearError,
  clearMessage,
} = InvestorUserListingSlice.actions;

export default InvestorUserListingSlice.reducer;
