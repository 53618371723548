import React, { useEffect, useState } from "react";
import trash from "../../../assets/Trash.png";
import edit from "../../../assets/Pen Squared.png";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteInterestGroup,
  GetInterestGroup,
} from "../../../Redux/Actions/InterestGroup";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/InterestGroupReducer";
import { toast } from "react-toastify";
import DeleteModal from "../../Modals/DeleteModal";
import Pagination from "../../Pagination";

function InterestGroupAdmin() {
  const { data, message, error } = useSelector((state) => state.interestGroup);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const handleEdit = (value) => {
    console.log("Edit");
    navigate("/admin/edit-interest-group", {
      state: {
        data: value,
      },
    });
  };
  const handleDelete = async (id) => {
    await dispatch(DeleteInterestGroup({ id }));
    dispatch(GetInterestGroup({ page, size }));
  };
  const handleModal = (id) => {
    setModalOpen(true);
    setId(id);
  };

  useEffect(() => {
    dispatch(GetInterestGroup({ page, size }));
  }, [dispatch, page, size]);
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (message) {
      toast.success(error);
      dispatch(clearMessage());
    }
  }, [message, error]);
  return (
    <div className="container-fluid mainedit">
      <div className="col-sm-12 adminSubsPackage">
        <div className="adminSubsPackageButton">
          <Link to="/admin/add-interest-group">
            <Button>Add Interest Group</Button>
          </Link>
        </div>

        <div className="intAdminGrp">
          <table className="adminSubsPackageTable">
            <thead className="tableHeading">
              <tr className="tableBodyRow intetrestGrp">
                <th className="subAdmNAme">Name</th>
                <th className="actionIcons">Action</th>
              </tr>
            </thead>

            <tbody className="tableBody">
              {data?.items?.map((value, i) => (
                <tr className="tableBodyRow intetrestGrp" key={i}>
                  <td className="subAdmNAme">{value?.groupName}</td>
                  <td className="actionIcons">
                    <img
                      src={edit}
                      className="actionIconsAdm"
                      onClick={() => handleEdit(value)}
                    />
                    <img
                      src={trash}
                      className="actionIconsAdm"
                      // onClick={() => handleDelete(value._id)}
                      onClick={() => handleModal(value._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        TotalData={data?.totalCount}
        handlePageChange={handlePageChange}
        currentPage={page}
        onShowSizeChange={onShowSizeChange}
      />
      <DeleteModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleDelete={handleDelete}
        id={id}
      />
    </div>
  );
}

export default InterestGroupAdmin;
