import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const InvestorShareFeedbackReducerSlice = createSlice({
  name: "sharefeedback",
  initialState,
  reducers: {
    InvestorShareFeedbackStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    InvestorShareFeedbackSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    },

    InvestorShareFeedbackFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    clearErrors: (state) => {
      state.error = null;
    },
    clearMessages: (state) => {
      state.message = null;
    },
  },
});

export const {
  InvestorShareFeedbackStart,
  InvestorShareFeedbackSuccess,
  InvestorShareFeedbackFailure,
  clearErrors,
  clearMessages,
} = InvestorShareFeedbackReducerSlice.actions;

export default InvestorShareFeedbackReducerSlice.reducer;
