import React from "react";
import SideBar from "../Componenets/User/Sidebar/SideBar";
import ProfileView from "../Componenets/User/Profile/ProfileView";
import Header from "../Componenets/User/Header/Header";

import SuccessComp from "../Componenets/Success/SuccessComp";

function SuccessPage() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <SideBar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          {/* <Header /> */}

          <SuccessComp />
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
