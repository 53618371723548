import React from "react";
import { Pagination as AntPagination } from "antd";

const Pagination = ({
  TotalData,
  handlePageChange,
  currentPage,
  onShowSizeChange,
}) => {
  return (
    <nav className="paginationMain pagination">
      <AntPagination
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        total={TotalData}
        onChange={handlePageChange}
        defaultCurrent={currentPage}
        showTotal={(total) => `Total ${total} items`}
      />
    </nav>
  );
};

export default Pagination;
