import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  isLinkedin: null,
  userToken: null,
};

const authSlice = createSlice({
  name: "userauth",
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
      state.isLinkedin = false;
    },

    loginSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Login Successfully";
      state.userToken = action.payload.data.token;
      Cookies.set("token", action.payload.data.token);
      state.isLinkedin = false;
    },

    logOutSuccess(state) {
      state.data = null;
      state.userToken = null;
      state.message = null;
      state.loading = false;
      Cookies.remove("token");
      localStorage.removeItem("persist:root");
      state.isLinkedin = false;
    },
    linkedinLogin(state, action) {
      state.userToken = action?.payload;
      Cookies.set("token", action.payload);
      state.isLinkedin = true;
      console.log(state.isLinkedin, "<====== I am Linkedin");
    },

    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    signUpStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
      state.isLinkedin = false;
    },

    signUpSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      console.log(action.payload);
      state.message = action.payload.message;
      state.isLinkedin = false;
    },

    signUpFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
      state.isLinkedin = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  clearError,
  clearMessage,
  logOutSuccess,
  linkedinLogin,
  signUpStart,
  signUpFailure,
  signUpSuccess,
} = authSlice.actions;

export default authSlice.reducer;
