import React from "react";
import { Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Bell from "../../../assets/bell.png";
import Avatar from "../../../assets/default.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logOutSuccess } from "../../../Redux/Reducers/AdminAuthReducer";
import { logOutSuccess as userLogout } from "../../../Redux/Reducers/UserAuthReducer";
import { logOutSuccess as investorLogout } from "../../../Redux/Reducers/InvestorAuthReducer";

const { Search } = Input;
function AdminHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    // Handle search functionality here
    console.log("Search:", value);
  };
  const handleLogout = () => {
    dispatch(logOutSuccess());
    dispatch(userLogout());
    dispatch(investorLogout());
    navigate("/");
    toast.success("Log Out Successfully");
  };

  const getActiveLinkText = () => {
    const lastPath = location.pathname.split("/").pop();

    const formattedText = lastPath
      ? lastPath.replace(/-/g, " ")
      : "Subscription Package";
    return formattedText;
  };
  return (
    <div className="col-sm-12 header">
      <div className="row headsser">
        <div className="col-sm-5 ">
          <h2>{getActiveLinkText()}</h2>
        </div>

        <div className="col-sm-5 headsser">
          {/* <Search
            placeholder="Search..."
            onSearch={handleSearch}
            style={{ marginBottom: "16px" }}
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          /> */}
        </div>

        <div className="col-sm-1 noti">
          {/* <img src={Bell} alt="Dropdown Icon" /> */}
        </div>

        <div className="col-sm-1 headerImage">
          <DropdownButton
            id="dropdown-basic-button"
            title={<img src={Avatar} alt="Dropdown Icon" />}
          >
            <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
