import React, { Fragment, useEffect, useState } from "react";
import VideoPlayer from "../Profile/VideoPlayer";

function RightSecD({data}) {
 
  const videoId = data.userCallDetails?.userStoryData?.Video;

  const [videoState, setVideoState] = useState();

  useEffect(() => {
    setVideoState(data?.userStoryData?.Video);
  }, [data?.userStoryData?.Video]);
  console.log (data, "videoasdasdasd")


  if (!data) {
    return <div>Data is not available.</div>;
  }



  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 rightvideo">
        {data ? <VideoPlayer link={videoState} /> : <></>}
        </div>
      </div>
    </Fragment>
  );
}

export default RightSecD;


