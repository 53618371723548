import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
const ProtectedRoute = ({ role, token, children }) => {
  if (!token) {
    // Redirect to login if not authenticated
    return <Navigate to="/" />;
  }

  // Render the children if authenticated
  return children;
};

export default ProtectedRoute;
