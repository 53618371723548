import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  contactStart,
  contactSuccess,
  contactFailure,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactFailure,
} from "../Reducers/ContactReducer";
import axios from "axios";

export const GetContact = createAsyncThunk(
  "contact",
  async ({ page, size }, thunkAPI) => {
    try {
      thunkAPI.dispatch(contactStart());

      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      const params = {
        ...(page && { page }),
        ...(size && { size }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/contactUsRequests`,
        {
          params,
          headers: header,
        }
      );

      thunkAPI.dispatch(contactSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(contactFailure(error.response.data.message));
    }
  }
);

export const DeleteContact = createAsyncThunk(
  "user",
  async ({ id }, thunkAPI) => {
    try {
      const adminAuth = thunkAPI.getState().adminAuth.adminToken;

      const header = {
        Authorization: `Bearer ${adminAuth}`,
      };
      thunkAPI.dispatch(deleteContactStart());

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteContactRequest/${id}`,
        {
          headers: header,
        }
      );

      thunkAPI.dispatch(deleteContactSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(deleteContactFailure(error.response.data.message));
    }
  }
);
