import React from "react";
import LoginSlide from "../../Componenets/User/Login/LoginSlide";
import ConfirmPasswordForm from "../../Componenets/User/ConfirmPassword/ConfirmPasswordForm";

function ConfirmPassword() {
  return (
    <div className="container-fluid userlogin">
      <div className="row">
        <div className="col-sm-6 leftslideuser">
          <LoginSlide />
        </div>

        <div className="col-sm-6 rightloginform">
          <ConfirmPasswordForm />
        </div>
      </div>
    </div>
  );
}

export default ConfirmPassword;
