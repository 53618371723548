import React from 'react'
import LoginSlide from '../../Componenets/User/Login/LoginSlide'
import BusinessForm from '../../Componenets/User/BusinessDetail/BusinessForm'

function BusinessDetail() {
  return (
    <div className='container-fluid userlogin'>
        <div className='row'>
            <div className='col-sm-6 leftslideuser'>
                <LoginSlide/>
            </div>

            <div className='col-sm-6 rightloginform'>
            <BusinessForm/>
            </div>
        </div>
    </div>
  ) 
}

export default BusinessDetail