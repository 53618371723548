import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetInvestorGroupuccess, GetInvestorGrouptart, getInvestorFailure, } from "../Reducers/GetInvestorGroupReducers";
import axios from "axios";


export const GetInvestorGroup = createAsyncThunk(

  "InvestorGroup",

  async (_, thunkAPI) => {
    try {

      thunkAPI.dispatch(GetInvestorGrouptart()); // Dispatch the start action

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllInvestorGroupNames?size=100`
      );
      thunkAPI.dispatch(GetInvestorGroupuccess(response));
      console.log("respponse", response)

      return response;
    } catch (error) {
  
      thunkAPI.dispatch(getInvestorFailure(error.response.data.message)); // Dispatch the failure action
    }
  },
);