import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
  token: null,
};

const InvestorBusiness = createSlice({
  name: "investorbusiness",
  initialState,
  reducers: {
    InvestorBusinessStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    InvestorBusinessSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Business Info Add successfully!";
    },

    logOutSuccess(state) {
      state.data = null;
      state.token = null;
      state.message = null;
      console.log("chala");
      // Cookies.remove("token");
    },

    InvestorBusinessFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  InvestorBusinessStart,
  InvestorBusinessSuccess,
  InvestorBusinessFailure,
  clearError,
  clearMessage,
  logOutSuccess,
} = InvestorBusiness.actions;

export default InvestorBusiness.reducer;
