import React, { Fragment, useState } from "react";
import Users from "../../../assets/user.png";
import Round from "../../../assets/round.png";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import VideoPlayer from "./VideoPlayer";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CirclePlay from "../../../assets/circlePlay.png";
import Avatar from "../../../assets/default.png";

function LeftProf({ data }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log(data, ">mydata");

  const handleSubmit = (value) => {
    console.log(value);
  };
  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };

  if (!data) {
    return <div>Data is not available.</div>;
  }

  return (
    <Fragment>
      <div className="mainprof" key={data._id}>
        <div className="row leftsidedetail investerLeftsidedetail">
          <div className="col-sm-12 usImg">
            <img src={data?.image ? data?.image : Avatar} />
          </div>

          <div className="col-sm-12 profdetails">
            <button
              className="profiledeTailsButtons"
              style={{ background: data.isActive ? "#008935" : "red" }}
            >
              {data.isActive ? "Active" : "Not Active"}
            </button>
            <Link
              to="/investor/edit-profile"
              className="linkEdit profiledeTailsButtons"
            >
              {" "}
              Edit Profile{" "}
            </Link>
          </div>

          <div className="col-sm-12 profdetails">
            <Button variant="primary" onClick={handleShow}>
              <img src={CirclePlay} alt="" /> View Business Video
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Business Video</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <VideoPlayer
                  link={data.investorRelationToBusinessInfo?.Video}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {" "}
                  Close{" "}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LeftProf;
