import React, { Fragment, useEffect, useState } from "react";
import LeftSec from "./LeftSec";
import RightSec from "./RightSec";
import VideoPlayer from "../Profile/VideoPlayer";
import { InvestorSingleUser } from "../../../Redux/Actions/InvestorUserListing";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import CirclePlay from "../../../assets/circlePlay.png";
import Avatar from "../../../assets/default.png";

function UserCallRequestPreview() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const param = useParams();
  const id = param.id;

  const dispatch = useDispatch();
  const { singleData } = useSelector((state) => state.InvestorUserListing);

  useEffect(() => {
    dispatch(InvestorSingleUser(id));
  }, [dispatch]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row heights">
          <div className="col-sm-12 maincol userCallReqPrev">
            <div className="row">
              <div className="col-sm-3 userlistprof">
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      src={
                        singleData?.profile_pic
                          ? singleData?.profile_pic
                          : Avatar
                      }
                    />
                  </div>

                  <div className="col-sm-12 sweep">
                    <Swiper
                      pagination={{ type: "fraction" }}
                      navigation={true}
                      modules={[Autoplay, Pagination]}
                      autoplay={{ delay: 2000, disableOnInteraction: false }}
                      className="mySwiper"
                    >
                      {singleData?.userBusinessInfoData?.userInvestorGroups?.map(
                        (item, i) => (
                          <SwiperSlide>
                            <div className="col-sm-12">
                              <p className="bstgre">
                                {item?.userInvestorGroupName?.groupName}
                              </p>
                            </div>
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>

                  <div className="col-sm-12 modalss modalBtn">
                    <Button variant="primary" onClick={handleShow}>
                      {" "}
                      <img src={CirclePlay} alt="" /> Venture Story
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Venture Story</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {" "}
                        <VideoPlayer link={singleData?.userStoryData?.Video} />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>

                  <div className="col-sm-12">
                    <button
                      className={singleData?.isActive ? "greenData" : "redData"}
                    >
                      {singleData?.isActive ? "Active " : "Not Active"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-9 desktop">
                <LeftSec data={singleData} id={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserCallRequestPreview;
