import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  callToInvestorStart,
  callToInvestorSuccess,
  callToInvestorFailure,
} from "../Reducers/CalltoInvestorReducer";
import axios from "axios";

export const CalltoInvestor = createAsyncThunk(
  "calltoinvestor",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(callToInvestorStart());

      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/callToInvestor/${id}`,
        { headers: header }
      );

      thunkAPI.dispatch(callToInvestorSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(callToInvestorFailure(error.response.data.message));
    }
  }
);
