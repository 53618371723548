import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const InvestorSlice = createSlice({
  name: "InvestorGroup",
  initialState,
  reducers: {
    GetInvestorGrouptart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null
      console.log("working")
    },

    GetInvestorGroupuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data.items;
      state.message = action.payload;

      console.log(action.payload, "pop")
 
    },

    getInvestorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
    GetInvestorGrouptart,
    GetInvestorGroupuccess,

  getInvestorFailure,
  clearError,
  clearMessage,
} = InvestorSlice.actions;

export default InvestorSlice.reducer;