import React from "react";

import ScheduleCard from "../../Componenets/Investor/ScheduleCalls/ScheduleCard";
import InvestorSidebar from "../../Componenets/Investor/Sidebar/InvestorSidebar";
import InvestorHeader from "../../Componenets/Investor/Header/InvestorHeader";

function ScheduleCalls() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <InvestorSidebar />
        </div>
  
        <div className="col-sm-9 rightloginform bgdarks">
          <InvestorHeader />
          <ScheduleCard />
        </div>
      </div>
    </div>
  );
}

export default ScheduleCalls;
