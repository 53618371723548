import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  userAddInfoStart,
  userAddInfoSuccess,
  userAddInfoFailure,
} from "../Reducers/UserAddInfoReducer";
import axios from "axios";

export const UserAddInfo = createAsyncThunk(
  "useraddinfo",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(userAddInfoStart());
      const userToken = thunkAPI.getState().userAuth.userToken;

      const header = {
        Authorization: `Bearer ${userToken}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/addUserInfo`,
        data,
        { headers: header }
      );

      thunkAPI.dispatch(userAddInfoSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(userAddInfoFailure(error.response.data.message));
    }
  }
);
