import React from "react";
import Particles from "react-confetti-explosion";

const ParticleEffect = ({ position }) => {
  const particleParams = {
    particles: {
      number: {
        value: 100,
      },
      size: {
        value: 3,
      },
      color: {
        value: "#ff9900",
      },
      opacity: {
        value: 0.8,
        anim: {
          enable: true,
        },
      },
      move: {
        enable: true,
        speed: 6,
      },
    },
  };

  const containerStyle = {
    overflow: "hidden",
  };

  return (
    <div style={containerStyle}>
      <Particles params={particleParams} />
    </div>
  );
};

export default ParticleEffect;
