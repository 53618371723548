import React, { Fragment, useEffect } from "react";
import { Button, Checkbox, Form, Input, Image, Select } from "antd";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { linkedinLogin } from "../../../Redux/Reducers/UserAuthReducer";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Loader from "../../Loader";
import { UserAddInfo } from "../../../Redux/Actions/UserAddInfo";
import {
  clearError,
  clearMessage,
} from "../../../Redux/Reducers/UserAddInfoReducer";
import { GetCity } from "../../../Redux/Actions/GetCity";
import { GetCountry } from "../../../Redux/Actions/GetCountry";
import { verify } from "../../../Redux/Actions/verify";
import Cookies from "js-cookie";

<Spin
  indicator={
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  }
/>;

function AddInfoForm() {
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, message, loading } = useSelector((state) => state.userAddInfo);
  const { data } = useSelector((state) => state.country);
  const verifyData = useSelector((state) => state.verify.data);

  const cityData = useSelector((state) => state.city.data);
  const handleChange = (e) => {
    dispatch(GetCity(e));
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    dispatch(UserAddInfo(values));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }

    if (message) {
      toast.success(message);
      navigate("/user/story");
      dispatch(clearMessage());
    }
  }, [error, message]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const authToken = urlSearchParams.get("authToken");
    if (authToken) {
      dispatch(linkedinLogin(authToken));
      dispatch(verify(authToken));
    } else if (Cookies.get("token")) {
      dispatch(verify(Cookies.get("token")));
    } else {
      navigate("/user/login");
    }
  }, [dispatch]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const authToken = urlSearchParams.get("authToken");
    console.log(authToken, "authToken -----------");
    if (authToken) {
      dispatch(linkedinLogin(authToken));
    }
    dispatch(verify(authToken));
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      if (verifyData.user && verifyData.user.UsersInfoDetail) {
        navigate("/user/story");
        console.log("1");
      }
    }
  }, [verifyData]);
  return (
    <Fragment>
      <div className="row storyUSer addInfoForm">
        <div className="col-sm-12 loginform">
          <h2>Add Info</h2>
          <p>Just Few Information Required to Create Venture</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your Country!",
                },
              ]}
            >
              <Select
                placeholder="Country"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => handleChange(e)}
              >
                {data?.map((e) => (
                  <Option key={e._id} value={e._id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="cityId"
              rules={[
                {
                  required: true,
                  message: "Please input your City!",
                },
              ]}
            >
              <Select
                placeholder="City"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => handleChange(e)}
              >
                {cityData?.map((e) => (
                  <Option value={e._id}>{e.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="address"
              rules={[
                { required: true, message: "Please input your address!" },
              ]}
            >
              <Input placeholder="address" />
            </Form.Item>

            <Form.Item
              name="businessName"
              rules={[
                { required: true, message: "Please input your Business Name!" },
              ]}
            >
              <Input placeholder="Business Name" />
            </Form.Item>

            <Form.Item
              name="pnum"
              rules={[{ required: true, message: "Please input your pnum!" }]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default AddInfoForm;
