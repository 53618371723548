import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginStart,
  loginSuccess,
  loginFailure,
} from "../Reducers/AdminAuthReducer";
import axios from "axios";

export const AdminAuthLogin = createAsyncThunk(
  "adminauth",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(loginStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/login`,
        data
      );

      thunkAPI.dispatch(loginSuccess(response.data));
    } catch (error) {
      thunkAPI.dispatch(loginFailure(error.response.data.message));
    }
  }
);
