import React from "react";
import Sidebar from "../../Componenets/User/Sidebar/SideBar";
import Header from "../../Componenets/User/Header/Header";
import ReschedulePreview from "../../Componenets/User/Reschedule/ReschedulePreview";

function ReschedulePage() {
  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <Sidebar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <Header />
          <ReschedulePreview />
        </div>
      </div>
    </div>
  );
}

export default ReschedulePage;
