import React from 'react'

import SideBar from '../../Componenets/User/Sidebar/SideBar'
import Header from '../../Componenets/User/Header/Header'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SinglePackage from "../../Componenets/User/SubsPackage/SinglePackage";

function SinglePackageView() {
  const stripePromise = loadStripe(
    "pk_test_51NKa9vLWzpnpY5nWhZgPto8lMErxlCdOYIll9mwjGXjaQl5H7iYXKOYWJ0WVBLqYRKdY8duAh0ePUxdkJzW7vvij00NQQVAQw5"
  );

  return (
    <div className="container-fluid userdashboard">
      <div className="row">
        <div className="col-sm-3 sidebar">
          <SideBar />
        </div>

        <div className="col-sm-9 rightloginform bgdarks">
          <Header />
          <Elements stripe={stripePromise}>
            <SinglePackage />
          </Elements>
        </div>
      </div>
    </div>
  );
}

export default SinglePackageView

