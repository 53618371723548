import React, { Fragment } from 'react'
import Logo from "../assets/Logo.png"
import { Link } from 'react-router-dom'
import Loginslide from "../Componenets/User/Login/LoginSlide"
import "../Style/Userstyle.css"
import Fade from 'react-reveal/Fade';
import Left from "../assets/left.png"
import Right from "../assets/right.png"

function Home() {
    return (
      <Fragment>
        <div className="container-fluid homescreen homescreen1  userlogin">
          <div className="row ">
            <Fade left delay={1000}>
            <div className="col-sm-6 leftslideuser">
          <Loginslide />
        </div>
            </Fade>

            <Fade right delay={1000}>
              <div className="col-sm-6">
                <div className="col-sm-12 hometitle">
                  <h2>
                    Welcome To The <img src={Logo} alt="asd" /> Portal
                  </h2>
                  <h3>
                    We're dedicated to nurturing startups and fostering
                    connections that can fuel growth. Our platform is designed
                    to bring entrepreneurs and investors together in an engaging
                    and supportive environment
                  </h3>
                </div>
                <div className="row mycolss">
                  {/* <div className="col-sm-4">
                  <Link to="/admin/login">Admin Login</Link>
                  
                </div> */}
                  <div className="col-sm-6">
                    <Link to="/investor/login" className="left">
                      <img src={Right} alt="asd" />
                      Investor
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <Link to="/user/login" className="right">
                      Venture <img src={Left} alt="asd" />
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Fragment>
    );
}
  
export default Home