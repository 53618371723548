import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

  error: null,
  data: [],
};

const SingleUserCall = createSlice({
  name: "singleusercall",
  initialState,
  reducers: {
    SingleUserCallStart(state) {
      state.loading = true;
      state.error = null;
    },

    SingleUserCallSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      console.log(action.payload, "data")
    },

    SingleUserCallFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  SingleUserCallStart,
  SingleUserCallFailure,
  clearError,
  clearMessage,
  SingleUserCallSuccess,
} = SingleUserCall.actions;



export default SingleUserCall.reducer;
