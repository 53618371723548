import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    contactStart(state) {
      state.loading = true;
      state.error = null;
    },

    contactSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
    },

    contactFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteContactStart(state) {
      state.loading = true;
      state.error = null;
    },

    deleteContactSuccess(state, action) {
      state.loading = false;
      state.message = action.payload.message;
      console.log(action.payload, "<====payload");
    },

    deleteContactFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  contactStart,
  contactSuccess,
  contactFailure,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactFailure,
  clearError,
  clearMessage,
} = contactSlice.actions;

export default contactSlice.reducer;
