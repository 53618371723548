import React, { Fragment } from 'react'
import { Button, Form, Select, } from 'antd';



const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const { Option } = Select;

function GroupForm() {
    return (
        <Fragment>
            <div className='row storyUSer'>
                <div className='col-sm-12 recordform groupform' >
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h2>Investor Group</h2>
                            <p>Welcome back! please enter your detail</p>
                        </div>
                    </div>

                    <Form
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className='row'>


                            <div className='col-sm-12'>
                                 
                            </div>

                            <div className='col-sm-12'>
                                <Form.Item
                                    name="investorgroup"
                                    rules={[{ required: true, message: 'Please Chose Investor Group', },
                                    ]}    >
                                    <Select placeholder="Select Stage">
                                        <Option value="option1">Option 1</Option>
                                        <Option value="option2">Option 2</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='col-sm-12'>
                                <Form.Item
                                    name="stage"
                                    rules={[{ required: true, message: 'Please Chose Stage', },
                                    ]}    >
                                    <Select placeholder="Select Stage">
                                        <Option value="option1">Option 1</Option>
                                        <Option value="option2">Option 2</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                        

                            <div className='col-sm-12 pitchdeckbtn'>
                                <Form.Item  >
                                    <Button type="primary" htmlType="submit" className='recordbtn'>
                                        Next
                                    </Button>
                                </Form.Item>
                            </div>

                        </div>
                    </Form>

                </div>
            </div>
        </Fragment>
    )
}

export default GroupForm