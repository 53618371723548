import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const verifySlice = createSlice({
  name: "verify",
  initialState,
  reducers: {
    verifyStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    verifySuccess(state, action) {
      state.loading = false;
      state.data = action.payload?.data;
      state.message = action.payload.message;
    },

    verifyFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  verifyStart,
  verifyFailure,
  clearError,
  clearMessage,
  verifySuccess,
} = verifySlice.actions;

export default verifySlice.reducer;
