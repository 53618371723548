import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  token: null,
};

const InvestorCallingSlice = createSlice({
  name: "investorreschedule",
  initialState,
  reducers: {
    investorCallingStart(state) {
      state.loading = true;
      state.error = null;
    },

    investorCallingSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
    },

    investorCallingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  investorCallingStart,
  investorCallingSuccess,
  investorCallingFailure,
  clearError,
  clearMessage,
} = InvestorCallingSlice.actions;

export default InvestorCallingSlice.reducer;
