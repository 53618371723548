import ProtectedRoute from "./Componenets/ProtectedRoutes";
import Homepage from "./Pages/Homepage";
import Login from "./Pages/UserPages/Login";
import SignUp from "./Pages/UserPages/SignUp";
import StoryVideo from "./Pages/UserPages/StoryVideo";
import PitchDeck from "./Pages/UserPages/PitchDeck";
import SubscriptionPackage from "./Pages/UserPages/SubscriptionPackage";
import InvestorLogin from "./Pages/InvestorPages/InvestorLogin";
import InvestorSignUp from "./Pages/InvestorPages/InvestorSignUp";
import InvestorGroup from "./Pages/UserPages/InvestorGroup";
import BusinessDetail from "./Pages/UserPages/BusinessDetail";
import InverstorBussinessInfo from "./Pages/InvestorPages/InverstorBussinessInfo";
import CallRequest from "./Pages/InvestorPages/CallRequest";
import AdminLogin from "./Pages/AdminPages/AdminLogin";
import AdminPackages from "./Pages/AdminPages/AdminPackages";
import AdminAddSubscriptionPackages from "./Pages/AdminPages/AdminAddSubscriptionPackages";
import AdminUser from "./Pages/AdminPages/AdminUser";
import AdminInvestors from "./Pages/AdminPages/AdminInvestors";
import AdminEditUser from "./Pages/AdminPages/AdminEditUser";
import AdminInterestGroup from "./Pages/AdminPages/AdminInterestGroup";
import AdminInterestsGroups from "./Pages/AdminPages/AdminInterestsGroups";
import SinglePackageView from "./Pages/UserPages/SinglePackageView";
import Profile from "./Pages/UserPages/Profile";
import EditProf from "./Pages/UserPages/EditProf";
import ScheduleCalls from "./Pages/UserPages/ScheduleCalls";
import EditProfile from "./Pages/InvestorPages/EditProfile";
import InvestorCallRequest from "./Pages/InvestorPages/InvestorCallRequest";
import InvestorScheduleCalls from "./Pages/InvestorPages/ScheduleCalls";
import AdminInvestorAdd from "./Pages/AdminPages/AdminInvestorAdd";
import AdminCallRequest from "./Pages/AdminPages/AdminCallRequest";
import AdminUserCallRequestDetails from "./Pages/AdminPages/AdminUserCallRequestDetails";
import InvestorList from "./Pages/UserPages/InvestorList";
import AdminScheduledCalls from "./Pages/AdminPages/AdminScheduledCalls";
import EditScheduledCallsAdmin from "./Pages/AdminPages/EditScheduledCallsAdmin";
import DeclineCallRequest from "./Pages/InvestorPages/DeclineCallRequest";
import InvestmentProfile from "./Pages/InvestorPages/InvestmentProfile";
import AdminSubcriptionPackageEdit from "./Pages/AdminPages/AdminSubcriptionPackageEdit";
import AdminEditInterestGroup from "./Pages/AdminPages/AdminEditInterestGroup";
import AdminEditInvestor from "./Pages/AdminPages/AdminEditInvestor";
import UserAddInfo from "./Pages/UserPages/UserAddInfo";
import LearningPortalDetails from "./Pages/LearningPortalPages/LearningPortalDetails";
import LearningPortal from "./Pages/LearningPortalPages/LearnigPortal";
import InvestorAddInfo from "./Pages/InvestorPages/InvestorAddInfo";
import NotFoundPage from "./Pages/NotFoundPage";
import Cookies from "js-cookie";
import UserList from "./Pages/InvestorPages/UserList";
import UserToInvestorCallRequest from "./Pages/InvestorPages/UserToInvestorCallRequest";
import RescheduleRequest from "./Pages/InvestorPages/RescheduleRequest";
import FeedbackCallRequest from "./Pages/InvestorPages/FeedbackCallRequest";
import ReschedulePage from "./Pages/UserPages/ReschedulePage";
import ForgetPassword from "./Pages/UserPages/ForgetPassword";
import ConfirmPassword from "./Pages/UserPages/ConfirmPassword";
import InvestorForgetPassword from "./Pages/InvestorPages/ForgetPassword";
import InvestorConfirmPassword from "./Pages/InvestorPages/ConfirmPassword";
import RescheduleCalls from "./Pages/InvestorPages/RescheduleCalls";
import InvestorDetailPage from "./Pages/UserPages/InvestorDetailPage";
import SuccessPage from "./Pages/SuccessPage";
// ... (import other components)
import { verify } from "./Redux/Actions/verify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ContactsList from "./Pages/AdminPages/ContactsList";
import NewsLetterList from "./Pages/AdminPages/NewsLetterList";
import BussinessList from "./Pages/AdminPages/BussinessList";

const commonRoutes = [
  { path: "/", element: <Homepage /> },
  { path: "/user/login", element: <Login /> },
  { path: "/user/signup", element: <SignUp /> },
  { path: "/investor/login", element: <InvestorLogin /> },

  { path: "/investor/signup", element: <InvestorSignUp /> },
  { path: "/admin/login", element: <AdminLogin /> },
  { path: "/user/success", element: <SuccessPage /> },

  { path: "/investor/add-info", element: <InvestorAddInfo /> },
  { path: "/user/add-info", element: <UserAddInfo /> },
  { path: "/user/forget-password", element: <ForgetPassword /> },
  { path: "/user/confirm-password/:id", element: <ConfirmPassword /> },
  { path: "/investor/forget-password", element: <InvestorForgetPassword /> },
  {
    path: "/investor/confirm-password/:id",
    element: <InvestorConfirmPassword />,
  },
  // ... (add other common routes as needed)
];

const generateRoutes = (userRole, token) => {
  console.log(userRole, "<>>>>>>>>>>>>>>>>>>>>", token);

  switch (userRole) {
    case "user":
      return [
        {
          path: "/user/story",
          element: (
            <ProtectedRoute token={token}>
              <StoryVideo />
            </ProtectedRoute>
          ),
        },

        {
          path: "/user/profile",
          element: (
            <ProtectedRoute token={token}>
              <Profile />
            </ProtectedRoute>
          ),
        },

        {
          path: "/user/pitchdeck",
          element: (
            <ProtectedRoute token={token}>
              <PitchDeck />
            </ProtectedRoute>
          ),
        },

        {
          path: "/user/investor-group",
          element: (
            <ProtectedRoute token={token}>
              <InvestorGroup />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/business-details",
          element: (
            <ProtectedRoute token={token}>
              <BusinessDetail />
            </ProtectedRoute>
          ),
        },

        {
          path: "/user/subscription-package",
          element: (
            <ProtectedRoute token={token}>
              <SubscriptionPackage />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/selectpackage/:id",
          element: (
            <ProtectedRoute token={token}>
              <SinglePackageView />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/edit-profile",
          element: (
            <ProtectedRoute token={token}>
              <EditProf />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/investor",
          element: (
            <ProtectedRoute token={token}>
              <InvestorList />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/schedule-calls",
          element: (
            <ProtectedRoute token={token}>
              <ScheduleCalls />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/reschedule/:id",
          element: (
            <ProtectedRoute token={token}>
              <ReschedulePage />
            </ProtectedRoute>
          ),
        },
        {
          path: "/user/investor-detail/:id",
          element: (
            <ProtectedRoute token={token}>
              <InvestorDetailPage />
            </ProtectedRoute>
          ),
        },
        // ... (add other user-specific routes)
      ];

    case "investor":
      return [
        {
          path: "/investor/reschedule-request",
          element: (
            <ProtectedRoute token={token}>
              <RescheduleCalls />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/business-info",
          element: (
            <ProtectedRoute token={token}>
              <InverstorBussinessInfo />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/call-request",
          element: (
            <ProtectedRoute token={token}>
              <CallRequest />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/edit-profile",
          element: (
            <ProtectedRoute token={token}>
              <EditProfile />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/my-profile",
          element: (
            <ProtectedRoute token={token}>
              <InvestmentProfile />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/user-requests/:id",
          element: (
            <ProtectedRoute token={token}>
              <InvestorCallRequest />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/reschedule-requests/:id",
          element: (
            <ProtectedRoute token={token}>
              <RescheduleRequest />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/user-to-request/:id",
          element: (
            <ProtectedRoute token={token}>
              <UserToInvestorCallRequest />
            </ProtectedRoute>
          ),
        },

        {
          path: "/investor/user-detail/:id",
          element: (
            <ProtectedRoute token={token}>
              <UserToInvestorCallRequest />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/decline-user-requests/:id",
          element: (
            <ProtectedRoute token={token}>
              <DeclineCallRequest />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/share-feedbacks/:id",
          element: (
            <ProtectedRoute token={token}>
              <FeedbackCallRequest />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/schedule-calls",
          element: (
            <ProtectedRoute token={token}>
              <InvestorScheduleCalls />
            </ProtectedRoute>
          ),
        },
        {
          path: "/investor/learning-portal",
          element: <LearningPortal />,
        },
        {
          path: "/investor/learning-portal/:title",
          element: <LearningPortalDetails />,
        },
        {
          path: "/investor/venture",
          element: (
            <ProtectedRoute token={token}>
              <UserList />
            </ProtectedRoute>
          ),
        },
      ];

    case "admin":
      return [
        { path: "/admin/subscription-packages", element: <AdminPackages /> },
        {
          path: "/admin/add-subscription-package",
          element: (
            <ProtectedRoute token={token}>
              <AdminAddSubscriptionPackages />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/edit-subscription-package",
          element: (
            <ProtectedRoute token={token}>
              <AdminSubcriptionPackageEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/users",
          element: (
            <ProtectedRoute token={token}>
              <AdminUser />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/edit-user/:id",
          element: (
            <ProtectedRoute token={token}>
              <AdminEditUser />
            </ProtectedRoute>
          ),
        },

        {
          path: "/admin/investors",
          element: (
            <ProtectedRoute token={token}>
              <AdminInvestors />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/add-investor",
          element: (
            <ProtectedRoute token={token}>
              <AdminInvestorAdd />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/edit-investor/:id",
          element: (
            <ProtectedRoute token={token}>
              <AdminEditInvestor />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/interest-group",
          element: (
            <ProtectedRoute token={token}>
              <AdminInterestGroup />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/add-interest-group",
          element: (
            <ProtectedRoute token={token}>
              <AdminInterestsGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/edit-interest-group",
          element: (
            <ProtectedRoute token={token}>
              <AdminEditInterestGroup />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/call-request",
          element: (
            <ProtectedRoute token={token}>
              <AdminCallRequest />{" "}
            </ProtectedRoute>
          ),
        },

        {
          path: "/admin/user-call-request",
          element: (
            <ProtectedRoute token={token}>
              <AdminUserCallRequestDetails />{" "}
            </ProtectedRoute>
          ),
        },

        {
          path: "/admin/scheduled-calls",
          element: (
            <ProtectedRoute token={token}>
              <AdminScheduledCalls />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/edit-call-request/:name",
          element: (
            <ProtectedRoute token={token}>
              <EditScheduledCallsAdmin />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/contact-list",
          element: (
            <ProtectedRoute token={token}>
              <ContactsList />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/newsletter",
          element: (
            <ProtectedRoute token={token}>
              <NewsLetterList />
            </ProtectedRoute>
          ),
        },
        {
          path: "/admin/business-leads",
          element: (
            <ProtectedRoute token={token}>
              <BussinessList />
            </ProtectedRoute>
          ),
        },

        // ... (add other admin-specific routes)
      ];

    default:
      return [];
  }
};

export { commonRoutes, generateRoutes };
